import axios from 'axios';
const local = 'localhost';

const api = axios.create({
	baseURL: `http://${local}:${process.env.REACT_APP_API_PORT}`,
});

export const requestData = async (endpoint) => {
	const { data } = await api.get(endpoint);
	return data;
};

/* BOLETINS */
export const requestBoletins = async () => {
	try {
		const { data } = await axios.get('https://api.subhue.org/boletins/');
		return data;
	} catch (error) {
		return {
			error: 'Erro ao receber os boletins. Tente novamente mais tarde.',
		};
	}
};

/* EMAIL DE SUPORTE */
export const requestEmail = async (body) => {
	try {
		const { data } = await axios.post('https://api.subhue.org/contato/', {
			nome: body.nome,
			email: body.email,
			telefone: body.telefone,
			mensagem: body.mensagem,
		});
		return data;
	} catch (error) {
		return {
			error: 'Erro ao enviar email. Tente novamente mais tarde.',
		};
	}
};

/* PUBLICAÇÕES BLOG */
export const requestPublics = async () => {
	try {
		const { data } = await axios.get('https://api.subhue.org/noticias/');
		return data;
	} catch (error) {
		return {
			error: 'Erro ao buscar unidades. Tente novamente mais tarde.',
		};
	}
};

/* UNIDADES */
export const requestUnits = async () => {
	try {
		const { data } = await axios.get(`https://api.subhue.org/unidades/`);
		return data;
	} catch (error) {
		return {
			error: 'Erro ao buscar unidades. Tente novamente mais tarde.',
		};
	}
};

/* NOTIFICAÇÕES  */
export const requestNoteLogin = async (input, senha) => {
	try {
		const { data } = await axios.post(`http://${local}:3002/noteLogin`, {
			input,
			senha,
		});
		return data;
	} catch (error) {
		return { error: error.message };
	}
};

//USUARIOS
export const requestLogin = async (input, senha) => {
	try {
		const { data } = await axios.post(`http://${local}:3002/login`, {
			input,
			senha,
		});
		return data;
	} catch (error) {
		return { error: error.message };
	}
};

export const requestCreate = async (body) => {
	try {
		const response = await axios.post(
			`http://${local}:3002/register`,
			body
		);
		return { data: response.data, status: response.status };
	} catch (error) {
		return { error: error.response.data.message };
	}
};

export const requestCreateRegister = async (token, body) => {
	try {
		const response = await axios.put(
			`http://${local}:3002/register`,
			body,
			{
				headers: { Authorization: token },
			}
		);
		return response;
	} catch (error) {
		return error;
	}
};

export const requestGestData = async ({ token, nome }) => {
	try {
		const response = await axios.get(`http://${local}:3002/register`, {
			headers: { Authorization: `Bearer ${token}` },
			params: { nome },
		});
		return response.data;
	} catch (error) {
		console.error('Erro na requisição:', error.response || error.message);
		return { error: error.response?.data || error.message };
	}
};

export const requestUsers = async ({ token }) => {
	try {
		const response = await axios.get(
			`http://${local}:3002/register/search`,
			{
				headers: { Authorization: `Bearer ${token}` },
			}
		);
		return response.data;
	} catch (error) {
		console.error('Erro na requisição:', error.response || error.message);
		return { error: error.response?.data || error.message };
	}
};

export const updatePermissions = async ({ token, body }) => {
	try {
		const response = await axios.put(
			`http://${local}:3002/register/permissions`,
			body,
			{
				headers: { Authorization: token },
			}
		);
		return response;
	} catch (error) {
		return error;
	}
};

//ESCALAS
export const requestCreateScale = async (token, body) => {
	try {
		const response = await axios.post(`http://${local}:3002/scale`, body, {
			headers: { Authorization: token },
		});
		return response;
	} catch (error) {
		return error;
	}
};

export const requestScaleData = async (body) => {
	try {
		const response = await axios.get(
			`http://${local}:3002/scale/${body.id}`,

			{
				headers: {
					Authorization: body.token,
				},
			}
		);
		return response;
	} catch (error) {
		return error;
	}
};

export const requestAllScale = async (body) => {
	try {
		const response = await axios.get(
			`http://${local}:3002/scale/`,

			{
				headers: {
					Authorization: body.token,
				},
			}
		);
		return response;
	} catch (error) {
		return error;
	}
};

/* DOCUMENTOS */

export const requestDocuments = async () => {
	const { data } = await axios.get(`http://${local}:3002/document`);
	return data;
};
