import React from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
/* import { Link } from 'react-router-dom'; */

function Saude() {
	const contatos = [
		{
			nome: 'Hugo Fagundes (Superintendente)',
			telefone: 'riosaudemental@gmail.com',
		},
	];

	return (
		<div className='flex flex-col min-h-screen'>
			<NavBar />
			<div className='flex flex-col flex-grow w-[80%]  md:w-[96%] lg:w-[63%] mt-10 mb-14 mx-auto text-wrap '>
				<h1 className='mx-auto md:mx-10  text-[#004A80] font-bold text-3xl md:text-4xl mt-12 mb-12 w-first-letter:text-xl font-roboto'>
					Saúde Mental
				</h1>
				<span className='text-justify pb-5 font-roboto md:mx-10'>
					A Superintendência de Saúde Mental é a área técnica da
					Secretaria Municipal de Saúde do Rio de Janeiro responsável
					por estabelecer diretrizes, articular ações e gerir o
					Programa de Desinstitucionalização e Serviços Residenciais
					Terapêuticos no âmbito da Rede de Atenção Psicossocial
					(RAPS) que é organizada a partir de sete componentes:
					Atenção Primária, Atenção Psicossocial Estratégica, Atenção
					de Urgência e Emergência, Atenção Residencial de Caráter
					Transitório, Atenção Hospitalar, Estratégias de
					Desinstitucionalização e Estratégias de Reabilitação
					Psicossocial.
				</span>
				<span className='text-justify pb-5 font-roboto md:mx-10'>
					A RAPS tem como finalidade a atenção à saúde para pessoas,
					chamadas de cliente, com sofrimento ou transtorno mental e
					com necessidades decorrentes do uso de crack, álcool e
					outras drogas no Sistema Único de Saúde (SUS) e tem como
					diretrizes o respeito à liberdade, a promoção da autonomia e
					da cidadania, combate ao estigma/preconceito, reconhecimento
					dos condicionantes e determinantes sociais da saúde,
					garantia da equidade e a integralidade do cuidado. A
					prioridade da RAPS é a construção de uma rede de serviços
					substitutiva ao hospital psiquiátrico, garantindo atenção em
					saúde mental de base comunitária/territorial e a
					desinstitucionalização de pacientes longamente internados.
				</span>
				<span className='text-justify pb-5 font-roboto md:mx-10'>
					Os Centros de Atenção Psicossocial (CAPS) são unidades para
					acolhimento às crises em saúde mental, atendimento e
					reinserção social de pessoas com transtornos mentais graves
					e persistentes e/ou com transtornos mentais decorrentes do
					uso prejudicial de álcool e/ou outras drogas. Os CAPS
					oferecem atendimento interdisciplinar, composto por uma
					equipe multiprofissional e atua em articulação com as demais
					unidades de Saúde e com outros setores (Educação,
					Assistência Social, etc.), sempre incluindo a família e a
					comunidade nas estratégias de cuidado.
				</span>
				<span className='text-justify pb-5 font-roboto md:mx-10'>
					O acesso aos CAPS pode ser feito por demanda espontânea, por
					intermédio de uma unidade de atenção primária ou
					especializada, por encaminhamento de uma emergência ou após
					uma internação clínica/psiquiátrica. Os CAPS funcionam de
					segunda a sexta, com atendimento das 8h às 17h. Os CAPS III
					tem funcionamento 24h, durante os sete dias da semana,
					oferecendo a possibilidade de acolhimento noturno para quem
					já é atendido, conforme avaliação da equipe.
				</span>
				<span className='text-justify pb-5 font-roboto  md:mx-10'>
					A Prefeitura do Rio conta com 18 Centros de Atenção
					Psicossocial (CAPS), 6 Centros de Atenção Psicossocial
					Álcool Outras Drogas (CAPSad) - dois deles com unidades de
					acolhimento adultos (UAA) - e 7 Centros de Atenção
					Psicossocial Infantil (CAPSi), totalizando 31 unidades
					especializadas próprias. Outras 3 das redes estadual e
					federal completam a rede de 34 CAPS dentro do município do
					Rio de Janeiro.
				</span>
				<div>
					<h1 className='mx-auto md:mx-10 text-slate-800 font-bold text-xl md:text-xl mt-2 w-first-letter:text-xl font-roboto mb-5'>
						Contatos
					</h1>
					<div className='columns-1 '>
						{contatos.map((contato, index) => (
							<div
								key={index}
								className='mx-auto md:mx-10 text-slate-800 mb-5 font-roboto'>
								<p>
									<strong>Responsavel: {contato.nome}</strong>
								</p>
								<p>E-mail: {contato.telefone}</p>
							</div>
						))}
					</div>
				</div>
			</div>
			<div>
				<Footer />
			</div>
		</div>
	);
}

export default Saude;
