export const contatos = [
	{
		nome: 'Hospital Maternidade Maria Amélia Buarque de Hollanda',
		endereco: 'Rua Moncorvo Filho, 67 – Centro',
		telefone: 'Tel.: 2252-3416',
	},
	{
		nome: 'Hospital Maternidade Fernando Magalhães',
		endereco: 'Rua General José Cristino, 87 – São Cristóvão',
		telefone: 'Tel.: 3878-2327',
	},
	{
		nome: 'Maternidade do Hospital Municipal Miguel Couto',
		endereco: 'Rua Mário Ribeiro, 117 – Leblon',
		telefone: 'Tel.: 3111-3742',
	},
	{
		nome: 'Maternidade da Rocinha',
		endereco: 'Avenida Niemeyer, 776. São Conrado, térreo e 8° andar',
		telefone: 'Tel.: 2196-5235020',
	},
	{
		nome: 'Hospital Maternidade Paulino Werneck',
		endereco: 'Estrada do Cacuia, 745 – Cocotá.',
		telefone: 'Tel.: 3512-9805',
	},
	{
		nome: 'Hospital Maternidade Carmela Dutra',
		endereco: 'Rua Aquidabã, 1.037 – Lins de Vasconcelos',
		telefone: 'Tel.: 3111-6762',
	},
	{
		nome: 'Hospital Maternidade Herculano Pinheiro',
		endereco: 'Rua Andrade Figueira, s/nº – Madureira',
		telefone: 'Tel.: 2458-8601 / 2458-8602',
	},
	{
		nome: 'Hospital Maternidade Alexander Fleming',
		endereco: 'Rua Jorge Schimidt, 331 – Marechal Hermes',
		telefone: 'Tel.: 3111-6241 / 2489-7161',
	},
	{
		nome: 'Maternidade Leila Diniz (Hospital Municipal Lourenço Jorge)',
		endereco: 'Av. Ayrton Senna, 2000 – Barra da Tijuca',
		telefone: 'Tel.: 3111-4900 / 3111-4923',
	},
	{
		nome: 'Hospital da Mulher Mariska Ribeiro',
		endereco: 'Praça 1º de Maio, s/nº – Bangu',
		telefone: 'Tel.: 3747-0437',
	},
	{
		nome: 'Maternidade do Hospital Municipal Albert Schweitzer',
		endereco: 'Rua Nilópolis, 239 – Realengo',
		telefone: 'Tel.: 2018-0063',
	},
	{
		nome: 'Maternidade do Hospital Municipal Rocha Faria',
		endereco: 'Av. Cesário de Melo nº 3215 – Campo Grande',
		telefone: 'Tels.: 2233-6791 / 2233-6789',
	},
	{
		nome: 'Maternidade do Hospital Municipal Pedro II',
		endereco: 'Rua do Prado, 325 – Santa Cruz',
		telefone: 'Tel.: 2419-4782',
	},
	{
		nome: 'Casa de Parto David Capistrano Filho',
		endereco: 'Av. Pontalina, s/nº – Realengo',
		telefone: 'Tel.: 3462-5593',
	},
];
