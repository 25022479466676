const unidades = [
	'CERC (Coordenação de Emergência Regional Centro)',
	'CPDC (Casa de Parto David Capistrano)',
	'HMCD (Hospital Maternidade Carmela Dutra)',
	'HMAR (Hospital Municipal Alvaro Ramos)',
	'HMPW (Hospital Maternidade Paulino Werneck)',
	'HMEF (Hospital Municipal Evandro Freire)',
	'HMAS (Hospital Municipal Albert Schweitzer)',
	'HMJ (Hospital Municipal Jesus)',
	'HMAF (Hospital Maternidade Alexandre Fleming)',
	'HMFM (Hospital Maternidade Fernando Magalhães)',
	'HMHP (Hospital Maternidade Herculano de Pinheiro)',
	'HMLJ (Hospital Municipal Lourenço Jorge)',
	'HMMC (Hospital Municipal Miguel Couto)',
	'HMMABH (Hospital Maternidade Maria Amelia Buarque de Hollanda)',
	'HMMMR (Hospital Municipal da Mulher Mariska Ribeiro)',
	'HMNSL (Hospital Municipal Nossa Senhora do Loreto)',
	'HMPII (Hospital Municipal Pedro II)',
	'HMP (Hospital Municipal Piedade)',
	'HMPW (Hospital Municipal Pedro II - Unidade de Pediatria)',
	'HMRG (Hospital Municipal Ronaldo Gasolla)',
	'HMRM (Hospital Municipal Rocha Maia)',
	'HMRPS (Hospital Municipal Raphael de Paula Souza)',
	'HMSF (Hospital Municipal Salgado Filho)',
	'HMSA (Hospital Municipal Souza Aguiar)',
	'HMBR (Hospital Municipal Barata Ribeiro)',
	'HMFST (Hospital Municipal Fransisco da Silva Telles)',
	'MHMMC (Maternidade Hospital Municipal Miguel Couto)',
	'MHMAS (Maternidade Hospital Municipal Albert Schweitzer)',
	'MHMPII (Maternidade Hospital Municipal Pedro II)',
	'HMRF (Hospital Municipal Rocha Faria)',
	'MHMRF (Maternidade Hospital Municipal Rocha Faria)',
	'HMLD (Hospital Municipal Leila Diniz)',
	'MR (Maternidade da Rocinha)',
	'SMS PADI AS (SMS PADI Albert Shweitzer)',
	'SMS PADI FST (SMS PADI Francisco da Silva Telles)',
	'SMS PADI LJ (SMS PADI Lourenço Jorge)',
	'SMS PADI MC (SMS PADI Miguel Couto)',
	'SMS PADI PW (SMS PADI Paulino Werneck)',
	'SMS PADI PII (SMS PADI Pedro II)',
	'SMS PADI RF (SMS PADI Rocha Faria)',
	'SMS PADI SF (SMS PADI Salgado Filho)',
	'SUBHUE',
];

const setores = [
	'Acolhimento com Classificação de Risco',
	'Agência Transfusional',
	'Alojamento Conjunto',
	'Ambulatório',
	'Anestesia',
	'Anestesiologia',
	'Assistência ao Parto',
	'Bucomaxilo',
	'Centro Cirúrgico',
	'Centro de Obstetrícia Neo',
	'Centro Obstétrico',
	'Cirurgia Geral',
	'Cirurgia Plástica',
	'Clínica Cirúrgica',
	'Clínica Médica - Eixo Verde',
	'Clínica Médica - Vermelho e Amarelo',
	'CTI 1 - Terapia Intensiva',
	'CTI Adulto',
	'CTI Pediátrico',
	'CTQ',
	'Cuidados Prolongados',
	'EMAD I',
	'EMAD II',
	'EMAD III',
	'Emergência',
	'Emergência Pediátrica',
	'ENF Centro Obstétrico',
	'Enfermaria Pediátrica',
	'Enfermaria/UI',
	'Infecto',
	'Internação Clínica',
	'Neonatologia',
	'Neonatologia Plantão',
	'Nefrologia',
	'Neurocirurgia',
	'Oftalmologia',
	'Ortopedia',
	'Oficina',
	'Obstetras',
	'Obstetrícia',
	'Plástica',
	'Pediatria',
	'Pré-natal',
	'Proctologia',
	'Psiquiatria',
	'Rotina Clínica',
	'RX',
	'Saúde Mental',
	'Terapia Intensiva',
	'UIPE',
	'UTI Adulto',
	'UTI Materna',
	'UTIN',
	'UTINN',
	'UCINCA',
	'UCINCO',
	'Urologia',
	'Vascular',
	'Outros',
];
const medicos = [
	'Anestesiologia',
	'Bucomaxilo',
	'Cirurgia',
	'Cirurgia Vascular',
	'Clínica Médica',
	'Ginecologia',
	'Intensivista',
	'Médico',
	'Neonatologia',
	'Neurocirurgia',
	'Obstetricia',
	'Oftalmologia',
	'Ortopedia',
	'Otorrinolaringologia',
	'Pediatria',
	'Psiquiatria',
	'Urologia',
	'Médico Residente',
	'Médico Radiologista',
	'Anatomia Patológica',
	'Cirurgia Pediátrica',
	'Ultrassonografia',
	'Endoscopia',
	'Cirurgia Plástica',
	'Cardiologia',
	'Dentista',
];

export { setores, unidades, medicos };
