import React, { useState, useEffect } from 'react';
/* import { useNavigate } from 'react-router-dom'; */
import Footer from '../../../components/Footer';
import { requestCreateRegister } from '../../../services/requests';
import NavBar from '../../../components/NavBar';

import Cookies from 'js-cookie';
/* JSON.parse(Cookies.get('user')); */

function Cadastro() {
	const [inputValues, setInputValues] = useState({
		permissao: { escala: false, notificacoes: false },
	});
	const [showRoleAlert, setShowRoleAlert] = useState(false);
	const [enableButton, setEnableButton] = useState(false);
	const [changeButton, setChangeButton] = useState(false);
	/* 	const navigate = useNavigate(); */

	const inputsValidation = (obj) => {
		const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

		if (obj.nome && obj.cpf && obj.email) {
			if (
				obj.nome.length >= 8 &&
				obj.cpf.length === 11 &&
				regex.test(obj.email)
			) {
				setEnableButton(true);
			} else {
				setEnableButton(false);
			}
		} else {
			setEnableButton(false);
		}
	};

	useEffect(() => {
		if (Cookies.get('user')) {
			if (
				JSON.parse(Cookies.get('user')).cargo === 'gestor' ||
				JSON.parse(Cookies.get('user')).cargo === 'desenvolvedor'
			) {
				setShowRoleAlert(false);
			} else {
				setShowRoleAlert(true);
			}
		} else {
			setShowRoleAlert(true);
		}
	}, []);

	const handleChange = (id, value, checked) => {
		setChangeButton(false);
		let inputs = inputValues;
		if (id === 'permissao') {
			if (inputs[id]) {
				setInputValues({
					...inputs,
					[id]: { ...inputs[id], [value]: checked },
				});
				inputsValidation({
					...inputs,
					[id]: { ...inputs[id], [value]: checked },
				});
			} else {
				setInputValues({ ...inputs, [id]: { [value]: checked } });
				inputsValidation({ ...inputs, [id]: { [value]: checked } });
			}
		} else {
			setInputValues({ ...inputs, [id]: value });
			inputsValidation({ ...inputs, [id]: value });
		}
	};

	const handleRegister = async () => {
		if (changeButton) {
			console.log('Não Fez a Requisição');
		} else {
			const user = JSON.parse(Cookies.get('user'));
			await requestCreateRegister(user.token, {
				...inputValues,
				unidade: user.nome,
			});

			console.log(user.token);
			console.log({
				...inputValues,
				unidade: user.nome,
			});
			setChangeButton(true);
		}
	};

	return (
		<div className='flex flex-col min-h-screen'>
			<NavBar />
			{/* {console.log(inputValues)} */}
			<div className='text-center flex-grow mt-20 mb-2'>
				{!showRoleAlert ? (
					<section className=' '>
						<div className='flex flex-col items-center mt-[2%]  mx-auto md:h-full lg:py-0'>
							<div className='w-[88%] md:w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0  '>
								<div className='p-6 pb-2  space-y-4 md:space-y-6 sm:p-8'>
									<h1 className='text-xl font-bold leading-tight tracking-tight text-slate-700  md:text-3xl '>
										Inserção de Novos Usuários no Banco de
										Dados
									</h1>
									<form
										className='space-y-4 md:space-y-6'
										action='#'>
										<div>
											<input
												onChange={({
													target: { value },
													target: { id },
												}) =>
													handleChange(
														id,
														value.toLowerCase()
													)
												}
												type='nome'
												name='nome'
												id='nome'
												className='bg-gray-50 border border-gray-300 text-gray-700  sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  -gray-600  '
												placeholder='Nome'
												required=''></input>
											<p
												htmlFor='remember'
												className='text-[#808080] text-start text-[10px] dark:text-gray-300'>
												Nome e Sobrenome (Mínimo 8
												Caracteres)
											</p>
										</div>
										<div>
											<input
												onChange={({
													target: { value },
													target: { id },
												}) => handleChange(id, value)}
												type='number'
												name='cpf'
												id='cpf'
												className='bg-gray-50 border border-gray-300 text-gray-700  sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  -gray-600  '
												placeholder='CPF'
												required=''></input>
											<p
												htmlFor='remember'
												className='text-[#808080] text-start text-[10px] dark:text-gray-300'>
												Digite Seu CPF (Somente Números)
											</p>
										</div>
										<div>
											<input
												onChange={({
													target: { value },
													target: { id },
												}) => handleChange(id, value)}
												type='email'
												name='email'
												id='email'
												className='bg-gray-50 border border-gray-300 text-gray-700  sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  -gray-600  '
												placeholder='Email'
												required=''></input>
											<p
												htmlFor='remember'
												className='text-[#808080] text-start text-[10px] dark:text-gray-300'>
												Exemplo:
												seunome.smsrio@gmail.com
											</p>
										</div>
										<div>
											<input
												onChange={({
													target: { value },
													target: { id },
												}) => handleChange(id, value)}
												type='number'
												name='telefone'
												id='telefone'
												className='bg-gray-50 border border-gray-300 text-gray-700  sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  -gray-600  '
												placeholder='Telefone'
												required=''></input>
											<p
												htmlFor='remember'
												className='text-[#808080] text-start text-[10px] dark:text-gray-300'>
												Digite Seu Telefone (Somente
												Números)
											</p>
										</div>
										<div>
											<input
												onChange={({
													target: { value },
													target: { id },
												}) => handleChange(id, value)}
												type='number'
												name='matricula'
												id='matricula'
												className='bg-gray-50 border border-gray-300 text-gray-700  sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  -gray-600  '
												placeholder='Matrícula'
												required=''></input>
											<p
												htmlFor='remember'
												className='text-[#808080] text-start text-[10px] dark:text-gray-300'>
												Digite o Número da Matrícula
												(Somente Números)
											</p>
										</div>
										<div className='text-start text-slate-600 font-bold text-md'>
											<p className='mb-5'>
												DEFINA AS PERMISSÕES DO USUÁRIO
											</p>
											<div className='flex '>
												<div className='flex items-center mr-6'>
													<input
														onChange={({
															target: { checked },
															target: { value },
															target: { id },
														}) =>
															handleChange(
																id,
																value,
																checked
															)
														}
														id='permissao'
														type='checkbox'
														value='escala'
														className='w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500   focus:ring-2  '></input>
													<label
														htmlFor='escala'
														className='ms-2 text-xs font-medium  text-gray-400'>
														Preenchimento de Escalas
													</label>
												</div>
												<div className='flex items-center'>
													<input
														onChange={({
															target: { checked },
															target: { value },
															target: { id },
														}) =>
															handleChange(
																id,
																value,
																checked
															)
														}
														id='permissao'
														type='checkbox'
														value='notificacoes'
														className='w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'></input>
													<label
														htmlFor='notificacoes'
														className='ms-2 text-xs font-medium  text-gray-400'>
														Avaliação de
														Notificações
													</label>
												</div>
											</div>
										</div>
										<div className='relative'>
											<button
												type='button'
												disabled={!enableButton}
												onClick={handleRegister}
												className={`text-white enabled:bg-[#004A80] 
												${changeButton ? 'enabled:bg-green-700 ' : ''}
												enabled:hover:bg-blue-800 bg-blue-300 focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm w-full px-5 py-2.5 me-2 mb-2 mt-5  focus:outline-none`}>
												{changeButton
													? 'Usuário Cadastrado'
													: 'Cadastrar'}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</section>
				) : (
					<section className='bg-[#004A80] rounded-md w-[90%] sm:w-[70%] md:w-[60%] lg:w-[50%] mt-20 mx-auto my-auto'>
						<div className='py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
							<div className='mx-auto max-w-screen-sm text-center'>
								<h1 className='mb-4 text-white text-5xl sm:text-6xl lg:text-7xl tracking-tight font-extrabold text-primary-600'>
									401
								</h1>
								<p className='mb-10 text-2xl sm:text-3xl md:text-4xl tracking-tight font-bold text-slate-100'>
									Acesso Negado
								</p>
								<p className='mb-7 text-sm sm:text-base md:text-lg font-light text-gray-300'>
									Você não possui permissão para acessar este
									campo. Entre em contato com o
									administrador(a) da sua unidade para
									solicitação de acesso.
								</p>
								<a
									href='/login'
									className='inline-flex hover:scale-105 transition-transform text-gray-300 bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4'>
									Volte para a Tela de Login
								</a>
							</div>
						</div>
					</section>
				)}
			</div>
			<div>
				<Footer />
			</div>
		</div>
	);
}

export default Cadastro;
