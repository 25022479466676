const documents = [
	{
		id: 1,
		titulo: 'Avaliação das Práticas de Segurança do Paciente - Serviços de Diálise 2024',
		temas: 'Segurança do Paciente',
		categoria: 'Guias',
		setor: '',
		publicacao: '2024',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/2_orientacoes-avaliacao-das-praticas-seguranca-paciente_dialise-2024_18-04-2024.pdf',
	},
	{
		id: 2,
		titulo: 'Orientações para preenchimento da Avaliação das Práticas de Segurança do Paciente 2024 - Serviços de Saúde com UTI',
		temas: 'Segurança do Paciente',
		categoria: 'Guias',
		setor: '',
		publicacao: '2024',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/orientacoes-avaliacao-praticas-seg-paciente-2022-hospitais-com-uti_14-04-2022.pdf',
	},
	{
		id: 3,
		titulo: 'Caderno 1 da ANVISA: Assistência Segura: Uma Reflexão Teórica Aplicada à Prática',
		temas: 'Segurança do Paciente',
		categoria: 'Manuais',
		setor: '',
		publicacao: '2017',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/Caderno_1_-_Assistencia_segura_uma_reflexao_teorica_aplicada_a_pratica.pdf',
	},
	{
		id: 4,
		titulo: 'Caderno 2 da ANVISA: Critérios Diagnósticos de Infecção Relacionada à Assistência à Saúde',
		temas: 'Segurança do Paciente',
		categoria: 'Manuais',
		setor: '',
		publicacao: '2017',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/Caderno_2_-_Crit%C3%A9rios_Diagn%C3%B3sticos_de_Infec%C3%A7%C3%A3o_Relacionada_%C3%A0_Assist%C3%AAncia_%C3%A0_Sa%C3%BAde.pdf',
	},
	{
		id: 5,
		titulo: 'Caderno 3 da ANVISA: Critérios Diagnósticos de Infecção Associada à Assistência à Saúde Neonatologia',
		temas: 'Segurança do Paciente',
		categoria: 'Manuais',
		setor: 'Maternidades e Hospitais Pediátricos',
		publicacao: '2017',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/Caderno_3_-_Crit%C3%A9rios_Diagn%C3%B3sticos_de_Infec%C3%A7%C3%A3o_Associada_%C3%A0_Assist%C3%AAncia_%C3%A0_Sa%C3%BAde_Neonatologia.pdf',
	},
	{
		id: 6,
		titulo: 'Caderno 4 da ANVISA: Medidas de Prevenção de Infecção Relacionada à Assistência à Saúde',
		temas: 'Segurança do Paciente',
		categoria: 'Manuais',
		setor: '',
		publicacao: '2017',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/Caderno_4_-_Medidas_de_Preven%C3%A7%C3%A3o_de_Infec%C3%A7%C3%A3o_Relacionada_%C3%A0_Assist%C3%AAncia_%C3%A0_Sa%C3%BAde.pdf',
	},
	{
		id: 7,
		titulo: 'Caderno 5 da ANVISA: Documento de referência para o Programa Nacional de SEGURANÇA DO PACIENTE',
		temas: 'Segurança do Paciente',
		categoria: 'Manuais',
		setor: '',
		publicacao: '2013',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/caderno_5_-_Documento_referencia_programa_nacional_seguranca.pdf',
	},
	{
		id: 8,
		titulo: 'Caderno 6 da ANVISA: Implantação do Núcleo de Segurança do Paciente em Serviços de Saúde',
		temas: 'Segurança do Paciente',
		categoria: 'Manuais',
		setor: '',
		publicacao: '2016',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/Caderno_6_-_Implantacao_nucleo_de_seguranca.pdf',
	},
	{
		id: 9,
		titulo: 'Caderno 7 da ANVISA: Gestão de Riscos e Investigação de Eventos Adversos Relacionados à Assistência à Saúde',
		temas: 'Segurança do Paciente',
		categoria: 'Manuais',
		setor: '',
		publicacao: '2017',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/Caderno_7_-_Gest%C3%A3o_de_Riscos_e_Investiga%C3%A7%C3%A3o_de_Eventos_Adversos_Relacionados_%C3%A0_Assist%C3%AAncia_%C3%A0_Sa%C3%BAde.pdf',
	},
	{
		id: 10,
		titulo: 'Caderno 8 da ANVISA: Medidas de Prevenção e Critérios Diagnósticos de Infecções Puerperais em Parto Vaginal e Cirurgia Cesariana',
		temas: 'Segurança do Paciente',
		categoria: 'Manuais',
		setor: 'Maternidades e Hospitais Pediátricos',
		publicacao: '2017',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/Caderno_8_-_Medidas_de_Preven%C3%A7%C3%A3o_e_Crit%C3%A9rios_Diagn%C3%B3sticos_de_Infec%C3%A7%C3%B5es_Puerperais_em_Parto_Vaginal_e_Cirurgia_Cesariana.pdf',
	},
	{
		id: 11,
		titulo: 'Caderno 9 da ANVISA: Medidas de Prevenção de Endoftalmites e de Síndrome Tóxica do Segmento Anterior Relacionadas a Procedimentos Oftalmológicos Invasivo',
		temas: 'Segurança do Paciente',
		categoria: 'Manuais',
		setor: 'Hospitais Gerais e Especializados',
		publicacao: '2017',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/Caderno_9_-_Medidas_de_Preven%C3%A7%C3%A3o_de_Endoftalmites_e_de_S%C3%ADndrome_T%C3%B3xica_do_Segmento_Anterior_Relacionadas_a_Procedimentos_Oftalmol%C3%B3gicos_Invasivos.pdf',
	},
	{
		id: 12,
		titulo: 'Caderno 10 da ANVISA: Prevenção de infecções por microrganismos multirresistentes em serviços de saúde',
		temas: 'Segurança do Paciente',
		categoria: 'Manuais',
		setor: '',
		publicacao: '2021',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/manual-prevencao-de-multirresistentes7.pdf',
	},
	{
		id: 13,
		titulo: 'Diretriz SUBHUE - Sistemas de Gerenciamento de Incidentes',
		temas: 'Segurança do Paciente',
		categoria: 'Guias',
		setor: '',
		publicacao: '2024',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/DIRETRIZ_SUBHUE_-_GERENCIAMENTO_INCIDENTES.pdf',
	},
	{
		id: 14,
		titulo: 'Checklist básico* para as CCIHs e os serviços de saúde em caso de suspeita ou confirmação de surto por C. auris segundo a Nota Técnica GVIMS/GGTES/Anvisa 02/20222023',
		temas: 'CCIH',
		categoria: 'Materiais Técnicos',
		setor: '',
		publicacao: '2022',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/ORIENT~1.PDF',
	},
	{
		id: 15,
		titulo: 'Checklist de Inserção de Cateter Vesical de Demora',
		temas: 'CCIH',
		categoria: 'Materiais Técnicos',
		setor: '',
		publicacao: '2023',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/CHECKLIST_DE_INSER%C3%87%C3%83O_DE_CVD.pdf',
	},
	{
		id: 16,
		titulo: 'Checklist de Inserção de cateter Vascular Central Adulto e Pediátrico',
		temas: 'CCIH',
		categoria: 'Materiais Técnicos',
		setor: '',
		publicacao: '2023',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/CHECKLIST_DE_INSER%C3%87%C3%83O_CVC_ADULTO_E_PEDI.pdf',
	},
	{
		id: 17,
		titulo: 'Checklist de Inserção de Cateter Vascular Central — NEONATA',
		temas: 'CCIH',
		categoria: 'Materiais Técnicos',
		setor: 'Maternidades e Hospitais Pediátricos',
		publicacao: '2023',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/CHECKLIST_DE_INSER%C3%87%C3%83O_CVC_NEONATAL.pdf',
	},
	{
		id: 18,
		titulo: 'Diretriz de SEPSE',
		temas: 'CCIH',
		categoria: 'Guias',
		setor: '',
		publicacao: '2023',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/diretriz_de_sepse.pdf',
	},
	{
		id: 19,
		titulo: 'COVID 19 - Nota Técnica - ANVISA',
		temas: '',
		categoria: 'Materiais Técnicos',
		setor: '',
		publicacao: '2024',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/NT042020covid1908.09.2022paraoportal3.pdf',
	},
	{
		id: 20,
		titulo: 'Cegonha - Guia de Serviços',
		temas: '',
		categoria: 'Identificações',
		setor: 'Maternidades e Hospitais Pediátricos',
		publicacao: '2024',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/AquiTemCegonha_A2_Grafica.pdf',
	},
	{
		id: 21,
		titulo: 'Portal Subpav',
		temas: '',
		categoria: 'Identificações',
		setor: 'Maternidades e Hospitais Pediátricos',
		publicacao: '2024',
		link: 'https://subpav.org/aps/uploads/publico/repositorio/AquiTemCegonha_A2_Grafica.pdf',
	},
];

export default documents;
