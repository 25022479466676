import React from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';

function Plataforma() {
	return (
		<div className=''>
			<NavBar />
			<div className='text-center  h-screen mt-20  w-full'>
				<h1
					className='w-full text-blue-900 font-semibold  flex justify-center  text-3xl md:text-5xl
					mt-6 w-first-letter:text-xl font:roboto
				'>
					Plataforma de Ensino
				</h1>

				<hr className='w-28 h-2 mx-auto my-3 bg-blue-900 dark:bg-gray'></hr>
				<h2
					className='w-full text-red-500 font-semibold  flex items-center justify-center  text-3xl md:text-5xl
					mt-20 w-first-letter:text-xl font:roboto
				'>
					Em Breve!
				</h2>
			</div>
			<div>
				<Footer />
			</div>
		</div>
	);
}

export default Plataforma;
