import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { IoIosArrowBack } from 'react-icons/io';
import Footer from '../../src/components/Footer';
import { requestCreate } from '../services/requests';
import { registerSchema } from '../Schemas/registerSchema';
import NavBar from '../components/NavBar';

function Register() {
	const [cpf, setCPF] = useState('');
	const [password, setPassword] = useState('');
	const [password2, setPassword2] = useState('');
	const [name, setName] = useState('');
	const [showAlert, setShowAlert] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showPassword2, setShowPassword2] = useState(false);
	const [password2Alert, setpassword2Alert] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		const enabledButton = () => {
			const { error } = registerSchema.validate({
				nome: name,
				cpf,
				password,
			});
			if (password.length !== 0 || password2.length !== 0) {
				setpassword2Alert(password === password2);
			}
			if (!error) {
				if (password === password2) {
					setIsDisabled(false);
				} else {
					setIsDisabled(true);
				}
			} else {
				setIsDisabled(true);
			}
		};

		enabledButton();
	}, [name, cpf, password, password2]);

	const populateStorage = (value, id) => {
		if (id === 'cpf') {
			localStorage.setItem([id], value);
			setCPF(value);
		} else if (id === 'password') {
			setPassword(value);
		} else if (id === 'password2') {
			setPassword2(value);
		} else if (id === 'nome') {
			localStorage.setItem([id], value);
			setName(value);
		}
	};

	const handleRegister = async () => {
		const data = await requestCreate({
			nome: name.toLowerCase(),
			cpf,
			senha: password,
		});
		if (data.error) {
			setShowAlert(true);
			setErrorMessage(data.error);
		} else {
			const { nome, cargo, token } = data;
			localStorage.setItem(
				'user',
				JSON.stringify({ nome, cargo, token })
			);
			navigate('/login');
			return data;
		}
	};

	return (
		<div className='flex flex-col min-h-screen'>
			<NavBar />
			<div className='text-center flex-grow mt-32 my-10 md:mt-20 md:my-10'>
				<div className='absolute left-2'>
					<button
						type='button'
						onClick={() => navigate('/login')}
						className='text-white w-[2rem] enabled:hover:bg-[#004A80] bg-blue-300 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-sm mt-5 p-2 '>
						<IoIosArrowBack className='scale-150  ' />
					</button>
				</div>
				<section>
					<div className='flex flex-col items-center mt-[2%] mx-auto md:h-full lg:py-0'>
						<div className='w-[88%] md:w-full bg-white rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0'>
							<div className='p-6 pb-2 space-y-4 md:space-y-6 sm:p-8'>
								<h1 className='text-xl font-bold leading-tight tracking-tight text-slate-700 md:text-3xl'>
									Cadastre seu Usuario
								</h1>
								<form
									className='space-y-4 md:space-y-6'
									action='#'>
									<div>
										<input
											value={name}
											onChange={({
												target: { value },
												target: { id },
											}) => populateStorage(value, id)}
											type='nome'
											name='nome'
											id='nome'
											className='bg-gray-50 border border-gray-300 text-gray-700 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5'
											placeholder='Nome'
											required=''
										/>
										<p
											htmlFor='remember'
											className='text-[#808080] text-start text-[10px]'>
											Nome e Sobrenome (Mínimo 8
											Caracteres)
										</p>
									</div>
									<div>
										<input
											value={cpf}
											onChange={({
												target: { value },
												target: { id },
											}) => populateStorage(value, id)}
											type='number'
											step='1'
											name='cpf'
											id='cpf'
											className='bg-gray-50 border border-gray-300 text-gray-700 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5'
											placeholder='CPF'
											required=''
										/>
										{showAlert ? (
											<p
												htmlFor='remember'
												className='text-red-600 text-start text-[10px]'>
												{errorMessage}
											</p>
										) : (
											<p
												htmlFor='remember'
												className='text-[#808080] text-start text-[10px]'>
												Digite Seu CPF (Somente Números)
											</p>
										)}
									</div>
									<div className='relative'>
										<input
											value={password}
											onChange={({
												target: { value },
												target: { id },
											}) => setPassword(value, id)}
											type={
												showPassword
													? 'text'
													: 'password'
											}
											name='password'
											id='password'
											placeholder='Senha'
											autoComplete='true'
											className='bg-gray-50 border border-gray-300 text-gray-700 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5'
											required=''
										/>
										<p
											htmlFor='remember'
											className='text-[#808080] text-start text-[10px]'>
											Digite Sua Senha (Mínimo 6
											Caracteres)
										</p>
										<button
											type='button'
											onClick={() =>
												setShowPassword(!showPassword)
											}
											className='absolute bottom-[28px] right-[12px] text-gray-500'>
											{!showPassword ? (
												<FaEyeSlash />
											) : (
												<FaEye />
											)}
										</button>
									</div>
									<div className='relative'>
										<input
											value={password2}
											onChange={({
												target: { value },
												target: { id },
											}) => setPassword2(value, id)}
											type={
												showPassword2
													? 'text'
													: 'password'
											}
											name='password2'
											id='password2'
											placeholder='Digite a Senha Novamente'
											autoComplete='true'
											className='bg-gray-50 border border-gray-300 text-gray-700 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5'
											required=''
										/>
										{password2Alert ? (
											<p
												htmlFor='remember'
												className='text-[#808080] text-start text-[10px]'>
												Digite Sua Senha (Mínimo 6
												Caracteres)
											</p>
										) : (
											<p
												htmlFor='remember'
												className='text-red-600 text-start text-[10px]'>
												Senhas não coincidem
											</p>
										)}

										<button
											type='button'
											onClick={() =>
												setShowPassword2(!showPassword2)
											}
											className=' absolute bottom-[27px] right-[12px] text-gray-500'>
											{!showPassword2 ? (
												<FaEyeSlash />
											) : (
												<FaEye />
											)}
										</button>
									</div>
									<div className='relative'>
										<div className='flex items-center mt-5 justify-between'>
											<div className='flex items-start'>
												<div className='flex items-center h-5'>
													<input
														id='remember'
														aria-describedby='remember'
														type='checkbox'
														className='w-4 h-4 border border-gray-300 bg-gray-50 focus:ring-3 focus:ring-primary-300'
														required=''
													/>
												</div>
												<div className='ml-3 text-sm flex'>
													<label
														htmlFor='remember'
														className='text-[#616161]'>
														Lembrar-me
													</label>
												</div>
											</div>
										</div>
										<button
											type='button'
											onClick={handleRegister}
											disabled={isDisabled}
											className='text-gray-400 enabled:bg-[#004A80] enabled:hover:bg-blue-800 bg-gray-100 focus:ring-4 focus:ring-blue-300 enabled:text-white font-medium rounded-sm text-sm w-full px-5 py-2.5 me-2 mb-2 mt-5'>
											Cadastrar
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			</div>
			<div>
				<Footer />
			</div>
		</div>
	);
}

export default Register;
