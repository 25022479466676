import React, { useState, useEffect } from 'react';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import Carousel from '../../components/Carousel';
import ICards from '../../components/ICards';
import Cookies from 'js-cookie';

function Hub() {
	const [showAlert, setShowAlert] = useState('loading');

	useEffect(() => {
		let userCookie = Cookies.get('user');
		const currentPage = 'hub';
		const validate = String(window.location.pathname).includes(currentPage);
		setShowAlert(validate && userCookie === undefined);
	}, []);

	return (
		<div className='flex flex-col min-h-screen'>
			<NavBar />
			<div className='text-center flex-grow mt-14'>
				{showAlert ? (
					<section className='bg-[#004A80] rounded-md w-[90%] sm:w-[70%] md:w-[60%] lg:w-[50%]  mb-20 mt-20 mx-auto my-auto'>
						<div className='py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
							<div className='mx-auto max-w-screen-sm text-center'>
								<h1 className='mb-4 text-white text-5xl sm:text-6xl lg:text-7xl tracking-tight font-extrabold text-primary-600'>
									401
								</h1>
								<p className='mb-10 text-2xl sm:text-3xl md:text-4xl tracking-tight font-bold text-slate-100'>
									Acesso Negado
								</p>
								<p className='mb-7 text-sm sm:text-base md:text-lg font-light text-gray-300'>
									Você não possui permissão para acessar este
									campo. Entre em contato com o
									administrador(a) da sua unidade para
									solicitação de acesso.
								</p>
								<a
									href='/login'
									className='inline-flex hover:scale-105 transition-transform text-gray-300 bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4'>
									Volte para a Tela de Login
								</a>
							</div>
						</div>
					</section>
				) : (
					<div className='text-center flex-grow  mx-auto w-[90%] max-w-[1136px] mt-[30px] '>
						<Carousel />
						<div className='h-[113.6] w-full'>
							<p className='mt-10 text-[#1E1E1E] font-bold text-xl  md:ml-0 text-center  md:text-center lg:text-start'>
								SERVIÇOS
							</p>
							<ICards />
						</div>
					</div>
				)}
			</div>

			<div>
				<Footer />
			</div>
		</div>
	);
}

export default Hub;
