import React from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
/* import { Link } from 'react-router-dom'; */

function CCIH() {
	return (
		<div className='flex flex-col min-h-screen'>
			<NavBar />
			<div className='flex flex-col flex-grow w-[80%]  md:w-[96%] lg:w-[63%] mt-10 mb-14 mx-auto text-wrap '>
				<h1 className='mx-auto md:mx-10 text-[#004A80] font-bold text-3xl md:text-4xl mt-12 mb-12 w-first-letter:text-xl font-roboto'>
					CCIH
				</h1>
				<span className='text-justify pb-5 font-roboto mx-auto md:mx-10'>
					A rede municipal de saúde do Rio de Janeiro conta com 11
					maternidades e uma casa de parto próprias. Essas unidades
					representam ao todo mais de 700 leitos de alojamento
					conjunto (mães e bebês) e 295 leitos de UI/UTI neonatal. As
					unidades estão distribuídas por toda a cidade.
				</span>
				<span className='text-justify pb-5 font-roboto mx-auto md:mx-10'>
					Todas elas estão inseridas no programa Cegonha Carioca, que
					busca garantir o melhor cuidado para a mãe e o bebê e que
					oferece incentivos ao pré-natal e transporte em ambulância
					para a maternidade, quando chegar a hora do parto. Também
					atendem à demanda espontânea de pacientes na porta de
					entrada.
				</span>
			</div>
			<div>
				<Footer />
			</div>
		</div>
	);
}

export default CCIH;
