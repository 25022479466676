import React, { useState, useEffect } from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
/* import { requestDocuments } from '../../services/requests'; */
import docArray from '../interno/planilhas/format';

function Guias() {
	const [documents, setDocuments] = useState([]);
	const [filtered, setFiltered] = useState([]);
	const [search, setSearch] = useState({
		titulo: '',
		temas: '',
		categoria: '',
		setor: '',
		publicacao: '',
	});

	const documentsRequest = async () => {
		/* const response = await requestDocuments();
		console.log(response); */
		return docArray;
	};

	const handleSearch = (value, id) => {
		setSearch({
			...search,
			[id]: value
				.toLowerCase()
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, ''),
		});
	};

	function filterDocuments(search, documents) {
		const activeFilters = Object.keys(search).filter((key) => search[key]);
		return documents.filter((document) => {
			return activeFilters.every((key) => {
				return (
					document[key] &&
					document[key]
						.toLowerCase()
						.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '')
						.includes(search[key])
				);
			});
		});
	}

	useEffect(() => {
		const getDocuments = async () => {
			const data = await documentsRequest(search);
			function organizarPorId(array) {
				array.sort((a, b) => {
					const idA = parseInt(a.id);
					const idB = parseInt(b.id);
					if (idA < idB) {
						return -1;
					}
					if (idA > idB) {
						return 1;
					}
					return 0;
				});

				return array;
			}
			setDocuments(organizarPorId(data));
		};

		getDocuments();
	}, [search]);

	useEffect(() => {
		setFiltered(filterDocuments(search, documents));
	}, [search, documents]);

	return (
		<div className='flex flex-col mx-auto min-h-screen'>
			<NavBar />
			<div className='text-center flex-grow mt-2'>
				<div className='flex flex-col w-[100%] lg:w-[70%] mx-auto mt-10 text-wrap text-center flex-grow'>
					<h1 className='mx-[7%] text-center md:text-center flex justify-center text-[#004A80] font-bold text-3xl md:text-5xl lg:text-5xl md:mt-14 md:mb-5 font-roboto'>
						Guias, Manuais e Materiais Técnicos
					</h1>
					<div className='w-[90%] mx-auto flex justify-center mt-12'>
						<div className='grid grid-cols-1 md:grid-cols-2 gap-x-32'>
							{/* Filtros */}
							{[
								'titulo',
								'categoria',
								'temas',
								'setor',
								'publicacao',
							].map((filter) => (
								<div
									className='flex mb-7 justify-end'
									key={filter}>
									<p className='font-semibold text-sm md:text-xl flex items-center text-[#323232] mr-5'>
										{filter.toUpperCase()}
									</p>
									{filter === 'categoria' ||
									filter === 'setor' ? (
										<select
											id={filter}
											onChange={({
												target: { value, id },
											}) => handleSearch(value, id)}
											className='bg-gray-300 border w-[15rem]  sm:w-[15rem] xl:w-[20rem] font-medium border-gray-300 sm:text-sm rounded-sm text-gray-700 focus:ring-primary-600 focus:border-primary-600 block p-2.5 '
											required=''>
											<option value=''>
												Selecione o filtro de{' '}
												{filter
													.charAt(0)
													.toUpperCase() +
													filter.slice(1)}
											</option>
											{/* Options para categoria e setor */}
											{filter === 'categoria'
												? [
														'Apresentações',
														'Boletins Informativos',
														'Guias',
														'Manuais',
														'Materiais Técnicos',
														'Identificações',
														'Protocolos',
														'Nota Técnica',
												  ].map((cat) => (
														<option
															key={cat}
															value={cat
																.toLowerCase()
																.normalize(
																	'NFD'
																)
																.replace(
																	/[\u0300-\u036f]/g,
																	''
																)}>
															{cat}
														</option>
												  ))
												: [
														'Urgência e Emergência',
														'Saúde Mental',
														'Maternidades e Hospitais Pediátricos',
														'Hospitais Gerais e Especializados',
														'Assessoria de Programas de Desospitalização',
														'Segurança do Paciente',
												  ].map((sec) => (
														<option
															key={sec}
															value={sec
																.toLowerCase()
																.normalize(
																	'NFD'
																)
																.replace(
																	/[\u0300-\u036f]/g,
																	''
																)}>
															{sec}
														</option>
												  ))}
										</select>
									) : (
										<input
											id={filter}
											onChange={({
												target: { value, id },
											}) =>
												handleSearch(
													value
														.toLowerCase()
														.normalize('NFD')
														.replace(
															/[\u0300-\u036f]/g,
															''
														),
													id
												)
											}
											type={
												filter === 'titulo'
													? 'text'
													: 'text'
											}
											className='bg-gray-300 border w-[15rem] md:w-[20rem] font-medium border-gray-300 text-black sm:text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block p-2.5 '
											placeholder={`Filtre por ${filter}`}
											required=''
										/>
									)}
								</div>
							))}
						</div>
					</div>
				</div>
				{filtered.length > 0 ? (
					<div className='w-[90%]  md:w-[90%] lg:w-[60%]   mx-auto'>
						{filtered.map((doc) => (
							<div
								key={doc.id}
								className='flex flex-col mt-10 mb-12'>
								<a
									href={doc.link}
									target='_blank'
									rel='noreferrer'
									className='flex text-start  text-[#004A80] text-2xl font-semibold p-4 rounded-md hover:bg-[#e2e2e2] transition-colors'>
									{doc.titulo}
								</a>
								<div
									className={`flex items-center text-start text-[#747474] px-4 text-sm md:text-lg `}>
									<span>
										{doc.setor.length > 0
											? doc.setor
											: 'indefinido'}
									</span>
									<div className='w-0.5 h-5 bg-gray-300 mx-2'></div>
									<span>
										{doc.temas.length > 0
											? doc.temas
											: 'indefinido'}
									</span>
									<div className='w-0.5 h-5 bg-gray-300 mx-2'></div>
									<span>
										{doc.publicacao.length > 0
											? doc.publicacao
											: 'indefinido'}
									</span>
								</div>
							</div>
						))}
					</div>
				) : (
					<p className='text-center text-[#323232] mt-5'>
						Nenhum documento encontrado.
					</p>
				)}
			</div>
			<Footer />
		</div>
	);
}

export default Guias;
