import React from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
/* import { Link } from 'react-router-dom'; */

function Seguranca() {
	return (
		<div className='flex flex-col min-h-screen'>
			<NavBar />
			<div className='flex flex-col flex-grow w-[80%]  md:w-[96%] lg:w-[63%] mt-10 mb-14 mx-auto text-wrap '>
				<h1 className='mx-auto md:mx-10  text-[#004A80] font-bold text-3xl md:text-4xl mt-12 mb-12 w-first-letter:text-xl font-roboto'>
					Segurança do Paciente
				</h1>
				<span className='text-justify pb-5 font-roboto mx-auto md:mx-10'>
					Com especialistas na área, a SUBHUE, juntamente com os
					Núcleos de Segurança do Paciente das unidades, trabalha para
					estruturar atividades organizadas, criar culturas,
					processos, procedimentos,comportamentos, tecnologias e
					ambientes em seus serviços visando reduzir riscos de forma
					consistente e sustentável, diminuindo a ocorrência de dano
					evitável, tornando os erros menos prováveis e reduzindo o
					impacto do dano quando este ocorrer.
				</span>
			</div>
			<div>
				<Footer />
			</div>
		</div>
	);
}

export default Seguranca;
