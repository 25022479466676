import React, { useState, useEffect } from 'react';
/* import { requestPublics } from '../services/requests';
import DOMPurify from 'dompurify'; */
import { RiCloseLine } from 'react-icons/ri';
/* import linkedin from '../images/Blog images/linkedin.png';
import facebook from '../images/Blog images/facebook.png';
import whatsapp from '../images/Blog images/whatsapp.png';
import instagram from '../images/Blog images/instagram_icon.png';

import linkedin_invert from '../images/Blog images/linkedin_invert.png';
import facebook_invert from '../images/Blog images/facebook_invert.png';
import whatsapp_invert from '../images/Blog images/whatsapp_invert.png';
import instagram_invert from '../images/Blog images/instagram_icon_invert.png'; */

const BlogCardExpand = ({ sendDataToParent, currentEvent }) => {
	function traduzirData(dataISO) {
		const meses = [
			'Janeiro',
			'Fevereiro',
			'Março',
			'Abril',
			'Maio',
			'Junho',
			'Julho',
			'Agosto',
			'Setembro',
			'Outubro',
			'Novembro',
			'Dezembro',
		];

		const [ano, mes, dia] = dataISO.split('-');
		return `${parseInt(dia)} de ${meses[parseInt(mes) - 1]} de ${ano}`;
	}

	const sendData = (event) => {
		const backgroundColor = window.getComputedStyle(
			event.target
		).backgroundColor;

		if (
			backgroundColor === 'rgba(0, 0, 0, 0.7)' ||
			event.target.id === 'close'
		) {
			const data = false;
			sendDataToParent(data);
		}
	};

	const gerarCorAleatoria = () => {
		const cores = [
			'rgb(152, 185, 91)',
			'rgb(163, 92, 135)',
			'rgb(169, 109, 176)',
			'rgb(71, 133, 79)',
			'rgb(147, 96, 175)',
			'rgb(147, 109, 92)',
			'rgb(166, 104, 107)',
			'rgb(142, 135, 72)',
			'rgb(165, 147, 111)',
			'rgb(148, 152, 87)',
		];

		return cores;
	};

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.key === 'Escape') {
				sendDataToParent(false); // Desativa a div ao pressionar Esc
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		// Cleanup para evitar múltiplos listeners
		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, []);

	return (
		<div
			onClick={sendData}
			className='font-inter fixed inset-0 bg-black z-20 bg-opacity-70 flex items-center justify-center'>
			<div className='absolute top-[-130px] sm:top-1 z-30   font-bold rounded-2xl bg-[#FFFFFF]  h-[900px] shadow-lg w-[700px] md:w-[1072px] scale-50 sm:scale-[70%] md:scale-75 lg:scale-75 xl:scale-80 sm:h-[95%] transition-all easy-in-out duration-200'>
				<div className='flex justify-end w-full shadow-lg h-[30px]'>
					<div onClick={() => sendDataToParent(false)}>
						<button
							id='close'
							className='flex justify-center right-0 rounded-tr-xl hover:text-white text-[#BDBDBD] w-[70px] h-[30px] bg-white transition-all hover:bg-[#B74644] '>
							<RiCloseLine
								id='close'
								size={30}
							/>
						</button>
					</div>
				</div>
				<div
					className='overflow-y-scroll font-bold h-[100%] rounded-b-2xl bg-[#FFFFFF] w-[100%] 
             [&::-webkit-scrollbar]:w-5
             [&::-webkit-scrollbar-track]:rounded-br-lg
             [&::-webkit-scrollbar-thumb]:rounded-br-lg
             [&::-webkit-scrollbar-track]:bg-gray-50
             [&::-webkit-scrollbar-thumb]:bg-gray-200
             dark:[&::-webkit-scrollbar-track]:bg-neutral-200
             dark:[&::-webkit-scrollbar-thumb]:bg-neutral-400'>
					<div className='w-full h-[293px]  bg-[#E3E3E3]'>
						<img
							className='w-full h-full object-cover'
							alt='imagem da noticia'
							src={currentEvent.imagem}></img>
					</div>
					<div className='relative font-light grid grid-rows-[auto,auto,1fr] gap-[18px] py-[15px] px-[10%] xs:px-[126px]'>
						<div className='text-[#555555] text-start font-medium text-[35px]'>
							{currentEvent.titulo}
						</div>
						<div className='flex flex-wrap gap-2  overflow-hidden'>
							{currentEvent.tags.split(',').map((topic, i) => (
								<div
									key={i}
									className='px-[8px] py-[3px] rounded-[4px] text-[14px] font-normal text-[#FFFFFF] flex-shrink-0'
									style={{
										backgroundColor: gerarCorAleatoria()[i],
									}}>
									{topic}
								</div>
							))}
						</div>
						<div className='self-end'>
							<p className='text-[#757575] font-normal text-[18px] text-start'>
								{traduzirData(currentEvent.data_publicacao)}
							</p>
						</div>
						<div className='h-[2px] bg-[#E3E3E3]'></div>
						<div className=' text-red-700'>
							<div
								dangerouslySetInnerHTML={{
									__html: currentEvent.conteudo,
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlogCardExpand;
