import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'; // Importa o Framer Motion
import MinhaSVG from '../images/minhasaudepng.png';
import HospitaisPNG from '../images/Hospitais Gerais.png';
import CcihPNG from '../images/ccih.png';
import logo1746 from '../images/1746 Vertical-04 2 (1).png';
import Censo from '../images/censo.png';
import ApdpadiPNG from '../images/APDPADI.png';
import BoletinPNG from '../images/boletins.png';
import MaternidadeSVG from '../images/SVGS/MaternidadeSVG';
import UrgenciaSVG from '../images/SVGS/UrgenciaSVG';
import NotasSVG from '../images/guias.png';
import OndePNG from '../images/onde.png';
import CegonhaPNG from '../images/cegonha.png';

function Grid() {
	const [showCards, setShowCards] = useState(false);

	useEffect(() => {
		setShowCards(false);
		const timer = setTimeout(() => {
			setShowCards(true);
		}, 50);

		return () => clearTimeout(timer);
	}, []);

	return (
		<div className='flex items-center mt-[8%] mb-[5%] md:mt-[2%] mx-auto xl:mb-[3%] 2xl:mb-[6%]'>
			<div className='flex flex-row flex-wrap md:flex-row md:flex-wrap gap-y-8 sm:gap-10 md:gap-[32px] sm:mx-auto justify-center items-center'>
				{Array.from({ length: 11 }, (_, i) => (
					<a
						key={i}
						href={getLink(i)}
						target={isExternalLink(getLink(i)) ? '_blank' : '_self'}
						rel='noopener noreferrer'
						className={`flex flex-row rounded-xl justify-center w-[180px]  sm:w-[300px] md:w-[162px] hover:no-underline hover:scale-110 cursor-pointer transform transition-all duration-1000 ease-out ${
							showCards
								? 'opacity-100 translate-y-0'
								: 'opacity-0 translate-y-10'
						}`}>
						<div className='hover:no-underline flex flex-col py-2 sm:pt-4 items-center p-4 hover:scale-11 cursor-pointer g transform transition duration-300 hover:scale-105 h-[130px] md:w-[105%] w-[80%] bg-[#F5F5F5] px-[16px] rounded-2xl shadow-md shadow-[#c0c0c0]'>
							<div className='z-10 bg-[#00C0F3] w-[49.74px] h-[49.74px] rounded-full mb-2'>
								<div className='flex justify-center z-20 bg-[#004A80] w-[47.74px]  h-[47.74px] rounded-full'>
									<div className='flex justify-center scale-[70%]'>
										{getSVG(i)}
									</div>
								</div>
							</div>
							<p className='font-semibold text-[13px]  text-[#757575]'>
								{getCardName(i)}
							</p>
						</div>
					</a>
				))}
			</div>
		</div>
	);
}

function getSVG(index) {
	switch (index) {
		case 0:
			return (
				<img
					alt='icone do minha saude'
					src={MinhaSVG}
					className=''
				/>
			);
		case 1:
			return <UrgenciaSVG />;
		case 2:
			return (
				<img
					alt='icone dos hospitais gerais e especializados'
					src={HospitaisPNG}
					className='pb-26 scale-[80%]'
				/>
			);
		case 3:
			return (
				<img
					alt='icone da APD - PADI'
					src={ApdpadiPNG}
					className=''
				/>
			);
		case 4:
			return <MaternidadeSVG />;
		case 5:
			return (
				<img
					alt='icone da Cegonha Carioca'
					src={CegonhaPNG}
					className=''
				/>
			);
		case 6:
			return (
				<img
					alt='icone dos boletins informativos'
					src={BoletinPNG}
					className=''
				/>
			);
		case 7:
			return (
				<img
					alt='icone de Guias, Manuais e Materiais Técnicos'
					src={NotasSVG}
					className='pb-26 scale-[80%]'
				/>
			);
		case 8:
			return (
				<img
					alt='icone de notificações de incidentes'
					src={Censo}
					className=''
				/>
			);
		case 9:
			return (
				<img
					alt='icone de Onde Ser Atendido'
					src={OndePNG}
					className=''
				/>
			);
		case 10:
			return (
				<img
					alt='icone da Ouvidoria'
					src={logo1746}
					className='h-[20px] mt-3'
				/>
			);
		default:
			return null;
	}
}

function getCardName(index) {
	switch (index) {
		case 0:
			return 'Minha Saúde';
		case 1:
			return 'Urgência e Emergência';
		case 2:
			return 'Hospitais Gerais e Especializados';
		case 3:
			return 'APD - PADI';
		case 4:
			return 'Maternidades e Hospitais Pediátricos';
		case 5:
			return 'Cegonha Carioca';
		case 6:
			return 'Boletins Informativos';
		case 7:
			return 'Guias, Manuais e Materiais Técnicos';
		case 8:
			return 'Notificações de Incidentes';
		case 9:
			return 'Onde Ser Atendido';
		case 10:
			return 'Ouvidoria';
		default:
			return null;
	}
}

function getLink(index) {
	switch (index) {
		case 0:
			return 'http://minhasaude.rio/';
		case 1:
			return `/${'Urgencia e Emergencia'}`;
		case 2:
			return `/${'Hospitais Gerais Especializados'}`;
		case 3:
			return `/${'Assessoria de Programas de Desospitalização'}`;
		case 4:
			return `/${'Maternidade e Pediatria'}`;
		case 5:
			return `/${'Cegonha'}`;
		case 6:
			return `/${'Boletins Informativos'}`;
		case 7:
			return `https://bibliotecasus.subpav.org/buscar?setor=4`;
		case 8:
			return `/${'hub-de-notificacoes'}`;
		case 9:
			return `https://pcrj.maps.arcgis.com/apps/webappviewer/index.html?id=014d8ab512a34f259bb27c8a13d4d65f`;
		case 10:
			return `/${'Ouvidoria'}`;
		default:
			return null;
	}
}

function isExternalLink(url) {
	return /^(http|https):\/\//.test(url);
}

export default Grid;
