import React from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';

function Ouvidoria() {
	const ouvidorias = [
		{
			nome: 'Ouvidoria da SMS – Nível Central',
			endereco:
				'Centro Administrativo São Sebastião, Rua Afonso Cavalcanti, nº 455 / 8º andar / sala 825 – Cidade Nova',
		},
		{
			nome: 'Ouvidoria da CAP 1.0',
			endereco: 'Rua Evaristo da Veiga, nº 16 / 3º andar – Centro',
		},
		{
			nome: 'Ouvidoria da CAP 2.1',
			endereco: 'Av. Venceslau Brás, nº 65 / fundos – Botafogo',
		},
		{
			nome: 'Ouvidoria da CAP 2.2',
			endereco: 'Rua Conde de Bonfim, nº 764 – Tijuca',
		},
		{
			nome: 'Ouvidoria da CAP 3.1',
			endereco: 'Rua São Godofredo, s/nº – Penha',
		},
		{
			nome: 'Ouvidoria da CAP 3.2',
			endereco: 'Rua Aquidabã, nº 1.037 – Lins de Vasconcelos',
		},
		{
			nome: 'Ouvidoria da CAP 3.3',
			endereco: 'Rua Avenida Manoel Martins, nº 53 – Madureira',
		},
		{
			nome: 'Ouvidoria da CAP 4.0',
			endereco: 'Av. Ayrton Senna, nº 2.001 / Bloco C – Barra da Tijuca',
		},
		{
			nome: 'Ouvidoria da CAP 5.1',
			endereco: 'Av. Carlos Pontes, s/nº – Jardim Sulacap',
		},
		{
			nome: 'Ouvidoria da CAP 5.2',
			endereco: 'Estrada do Campinho, nº 2.899 / 2º andar – Campo Grande',
		},
		{
			nome: 'Ouvidoria da CAP 5.3',
			endereco: 'Rua Álvaro Alberto, nº 601 – Santa Cruz',
		},
		{
			nome: 'Ouvidoria do Hospital Municipal Herculano Pinheiro',
			endereco: 'Av. Ministro Edgar Romero, nº 276 – Madureira',
		},
		{
			nome: 'Ouvidoria do Hospital Municipal Jesus',
			endereco: 'Rua Oito de Dezembro, nº 717 – Vila Isabel',
		},
		{
			nome: 'Ouvidoria do Hospital Municipal Lourenço Jorge / Maternidade Leila Diniz',
			endereco: 'Av. Ayrton Senna, nº 2.000 – Barra da Tijuca',
		},
		{
			nome: 'Ouvidoria do Hospital Municipal Miguel Couto',
			endereco: 'Rua Mário Ribeiro, nº 117 – Gávea',
		},
		{
			nome: 'Ouvidoria do Hospital Municipal Salgado Filho',
			endereco: 'Rua Arquias Cordeiro, nº 370 – Méier',
		},
		{
			nome: 'Ouvidoria do Hospital Municipal Souza Aguiar',
			endereco:
				'Praça da República, nº 111 / térreo – próximo à emergência',
		},
		{
			nome: 'Ouvidoria do Hospital Municipal Raphael de Paula Souza',
			endereco: 'Estrada de Curicica, nº 2.000 – Curicica',
		},
	];

	return (
		<div className=''>
			<NavBar />
			<div className='flex flex-col w-[80%]  md:w-[96%] lg:w-[63%] mb-14 mt-10 mx-auto text-wrap '>
				<h1 className='mx-auto md:mx-10 text-[#004A80] font-bold text-3xl md:text-4xl mt-12 mb-12 w-first-letter:text-xl font-roboto'>
					Ouvidoria
				</h1>
				<span className='text-justify pb-5 font-roboto mx-auto md:mx-10'>
					A Ouvidoria da SMS é o canal de comunicação entre o cidadão
					e a Secretaria Municipal de Saúde no tocante a questões
					pertinentes a elogios, sugestões e reclamações de serviços
					prestados nas unidades de saúde. Trata-se de uma segunda
					instância de relacionamento. Desta forma, antes de contatar
					a Ouvidoria, o cidadão deve recorrer à Central de
					Atendimento 1746, por telefone ou pelo portal{' '}
					<a
						rel='noreferrer'
						target='_blank'
						href='https://www.1746.rio/hc/pt-br'
						className='text-blue-500'>
						www.1746.rio.gov.br
					</a>{' '}
					, com funcionamento todos os dias da semana, inclusive
					domingos e feriados, 24 horas.
				</span>
				<span className='text-justify pb-5 font-roboto mx-auto md:mx-10'>
					Caso sua demanda solicitada através da Central 1746 não
					tenha sido atendida de forma satisfatória, a Ouvidoria da
					SMS pode ser contatada através dos seguintes canais:
				</span>
				<span className='text-justify pb-5 font-roboto mx-auto md:mx-10'>
					<span className='font-bold text-gray-800'>
						Pelo telefone:{' '}
					</span>{' '}
					a Central 1746 possui um setor que faz o registro de
					demandas para a Ouvidoria.
				</span>
				<span className='text-justify pb-5 font-roboto mx-auto md:mx-10'>
					<span className='font-bold text-gray-800'>
						Pela internet:{' '}
					</span>{' '}
					através do site{' '}
					<a
						className='text-blue-500'
						href='https://prefeitura.rio/'
						rel='noreferrer'
						target='_blank'>
						www.rio.rj.gov.br
					</a>{' '}
					link Ouvidoria.
				</span>
				<span className='text-justify pb-5 font-roboto mx-auto md:mx-10'>
					<span className='font-bold text-gray-800'>
						Pessoalmente:{' '}
					</span>{' '}
					nos endereços das Ouvidorias de cada unidade.
				</span>
				<div className='flex flex-col  mb-14  text-wrap '>
					<h1 className='mx-auto md:mx-10 text font-bold text-1xl md:text-2xl mt-12 w-first-letter:text-xl font-roboto'>
						Endereços das Ouvidorias da SMS
					</h1>
					<div className='mx-auto md:mx-10 py-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
						{ouvidorias.map((hospital, index) => (
							<div
								key={index}
								className='border border-gray-200 p-4 rounded-md'>
								<p className='text-[#004A80] font-bold text-md'>
									{hospital.nome}
								</p>
								<p className='text-gray-600 text-sm'>
									{hospital.endereco}
								</p>
								<p className='text-gray-600 text-sm'>
									{hospital.telefone}
								</p>
							</div>
						))}
					</div>
				</div>
			</div>
			<div>
				<Footer />
			</div>
		</div>
	);
}

export default Ouvidoria;
