import React, { useState, useEffect } from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import { contatos } from './descricoes e contatos/maternidades.js';
import { requestUnits } from '../../services/requests';
import { unidades } from './descricoes e contatos/escala.js';

function Maternidade() {
	const [visibleDescriptions, setVisibleDescriptions] = useState({});
	const [units, setUnits] = useState([]);

	/* const toggleDescription = (index) => {
		setVisibleDescriptions((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	}; */

	const fetchUnits = async () => {
		const response = await requestUnits();
		if (response.error) {
			console.log(response.error);
			/* alert(response.error); */
		} else {
			let groupedUnits = () => {
				return response.reduce((acc, unidade) => {
					const tipo = unidade.tipo_unidade;
					if (!acc[tipo]) {
						acc[tipo] = [];
					}
					acc[tipo].push(unidade);
					return acc;
				}, {});
			};

			let result = [];
			response
				.filter((unidade) => {
					if (unidade.unidades_associadas === true) {
						return unidade;
					}
				})
				.forEach((unidade) => {
					result.push(unidade.subunidades);
				});
			setUnits([...groupedUnits().Maternidade, ...result]);
		}
	};

	useEffect(() => {
		fetchUnits();
	}, []);

	return (
		<div className=''>
			<NavBar />
			<div className='flex flex-col w-[80%] md:w-[96%] lg:w-[63%] mt-14 mb-14 mx-auto text-wrap '>
				<h1 className='mx-auto md:mx-10 text-[#004A80] font-bold text-3xl md:text-4xl mt-14 mb-12 w-first-letter:text-xl font-roboto'>
					Maternidades e Hospitais Pediátricos
				</h1>
				<span className='text-justify pb-5 font-roboto mx-auto md:mx-10'>
					As maternidades municipais do Rio de Janeiro oferecem
					atendimento a gestantes em todas as fases do ciclo de
					gravidez e puerpério (45 dias após o parto), com
					atendimentos ambulatoriais, de urgência e internações.
					<br />
					<br />
					A rede municipal de saúde do Rio de Janeiro conta com 13
					Maternidades e 1 Casa de Parto próprias. Essas unidades
					representam mais de 700 leitos obstétricos e mais de 290
					leitos de UI/UTI neonatal. As unidades estão distribuídas
					por toda a cidade.
					<br />
					<br />
					Todas elas estão inseridas no Programa{' '}
					<a
						rel='noreferrer'
						target='_blank'
						href='/Cegonha'
						className='text-blue-500'>
						Cegonha Carioca
					</a>{' '}
					, que busca garantir o melhor cuidado para a mãe e o bebê e
					que oferece incentivos ao pré-natal e transporte em
					ambulância para a maternidade, quando chegar a hora do
					parto. Também atendem à demanda espontânea de pacientes na
					porta de entrada.
					<br />
					<br />
					As unidades têm um sistema de acolhimento e classificação de
					risco obstétrica, que é realizado na chegada da mulher à
					maternidade. Essa avaliação definirá quanto à internação ou
					outro encaminhamento.
				</span>
				{/* {console.log(units)} */}
				<div className='flex flex-col mb-14 text-wrap '>
					<h1 className='mx-auto md:mx-10 text font-bold text-1xl md:text-2xl mt-12 w-first-letter:text-xl font-roboto'>
						Contatos
					</h1>
					{/* {console.log(units)} */}
					{units.length > 0 ? (
						<div className='mx-auto md:mx-10 py-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
							{units.map((hospital, index) => (
								<div
									key={index}
									className={` border border-gray-200 p-4 rounded-md transition-all duration-300 ${
										visibleDescriptions[index]
											? 'h-auto'
											: 'h-46'
									}`}>
									<p className='text-[#004A80] font-bold text-md'>
										{hospital.nome}
									</p>
									<p className='text-gray-600 text-sm'>
										Endereço:. {hospital.bairro},{' '}
										{hospital.endereco}
									</p>
									<p className='text-gray-600 text-sm'>
										Tel:.{' '}
										{hospital.telefone === ''
											? 'não informado'
											: hospital.telefone}
									</p>
									{/* <button
										onClick={() => toggleDescription(index)}
										className='text-blue-500 my-2 '>
										{visibleDescriptions[index]
											? 'Menos Informações'
											: 'Mais Informações'}
									</button> */}
									{visibleDescriptions[index] && (
										<div>
											<span className='text-justify text-gray-600 text-sm pb-5 font-roboto mx-auto md:mx-10'>
												{hospital.nome.includes(
													'Maternidade da Rocinha'
												) ? (
													<p className='text-gray-600 text-md font-semibold'>
														Horários de visita
													</p>
												) : (
													<p className='text-gray-600 text-md font-semibold'>
														Horários de visita para
														família ampliada
													</p>
												)}
												{hospital.descricao1}
											</span>
											<span className='text-justify text-gray-600 text-sm pb-5 font-roboto mx-auto md:mx-10'>
												<p className='text-gray-600 text-md mt-10 font-semibold'>
													Acompanhante
												</p>
												{hospital.descricao2}
											</span>
											<span className='text-justify pb-5 font-roboto mx-auto md:mx-10 text-gray-600 text-sm'>
												<p className='text-gray-600 text-md mt-10 font-semibold'>
													Sala de Apoio à amamentação
												</p>
												{hospital.descricao3}
											</span>
										</div>
									)}
								</div>
							))}
						</div>
					) : (
						<div className=' border-gray-200 p-4 rounded-md'>
							<p className='text-[#004A80] h-[100px] mx-6 font-bold text-md'>
								{' '}
								UNIDADES NÃO ENCONTRADAS . . .
							</p>
						</div>
					)}
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Maternidade;
