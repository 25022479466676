const Joi = require('joi');

const minName = 12;
const minPassword = 6;

const registerSchema = Joi.object({
	nome: Joi.string().min(minName).required(),
	password: Joi.string().min(minPassword).required(),
	cpf: Joi.string().min(11).max(11).required(),
}).messages({
	'any.required': 'Todos os campos devem ser preenchidos',
});

module.exports = { registerSchema };
