import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import './index.css';
import App from './App';
import 'tailwindcss/tailwind.css';

// Usando createRoot()
createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		<BrowserRouter>
			<div className='min-h-screen bg-[#ffffff]'>
				<App className='m-0 p-0' />
			</div>
		</BrowserRouter>
	</React.StrictMode>
);

reportWebVitals();
