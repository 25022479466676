import React, { useEffect, useState, useRef } from 'react';
import { requestScaleData, requestGestData } from '../services/requests';
import Cookies from 'js-cookie';
/* JSON.parse(Cookies.get('user')); */

const CheckTable = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [checkTable, setCheckTable] = useState(null);
	const contentRef = useRef(null);
	const moment = require('moment');
	require('moment/locale/pt-br');
	moment.locale('pt-br');
	const objectLength = () => {
		if (checkTable) {
			const check = checkTable;
			const plant = check.plantonista.length;
			const rot = check.rotina.length;
			const pad = check.padi.length;
			const max = Math.max(plant, rot, pad);
			return [...Array(max).keys()];
		}
		return 0;
	};

	const visualizeScale = async (event) => {
		const setor = event.target.id;
		const user = JSON.parse(Cookies.get('user'));
		let id;
		if (user.unidade) {
			const gestData = await requestGestData({
				token: user.token,
				nome: user.unidade,
			});

			id = gestData[0].id.toString();
		}

		const { data } = await requestScaleData({
			token: user.token,
			id,
		});

		const filteredData = data.filter(
			(item) => item.setores.toLowerCase() === setor.toLowerCase()
		);
		let cabecalho = [
			{
				unidades: filteredData[0].unidades,
				setores: filteredData[0].setores,
				regimes: filteredData[0].regimes,
				turnos:
					filteredData[0].turnos === 'manhã' || 'noite'
						? 'manhã / noite'
						: 'diurno / noturno',
			},
		];
		let profissionais = {};
		let profissionais2 = {};

		filteredData.forEach((pro) => {
			if (pro.turnos === 'manhã' || pro.turnos === 'diurno') {
				if (!profissionais[`semana${pro.semana - 1}`]) {
					profissionais[`semana${pro.semana - 1}`] = {
						segunda: {},
						terça: {},
						quarta: {},
						quinta: {},
						sexta: {},
						sábado: {},
						domingo: {},
					};
					profissionais[`semana${pro.semana - 1}`][pro.dia] = {
						nome: pro.nome,
						vinculo: pro.vinculo,
						celular: pro.celular,
						especialidade: pro.especialidade,
						ch_total: pro.ch_total,
					};
				} else {
					profissionais[`semana${pro.semana - 1}`][pro.dia] = {
						nome: pro.nome,
						vinculo: pro.vinculo,
						celular: pro.celular,
						especialidade: pro.especialidade,
						ch_total: pro.ch_total,
					};
				}
			} else {
				if (!profissionais2[`semana${pro.semana - 1}`]) {
					profissionais2[`semana${pro.semana - 1}`] = {
						segunda: {},
						terça: {},
						quarta: {},
						quinta: {},
						sexta: {},
						sábado: {},
						domingo: {},
					};
					profissionais2[`semana${pro.semana - 1}`][pro.dia] = {
						nome: pro.nome,
						vinculo: pro.vinculo,
						celular: pro.celular,
						especialidade: pro.especialidade,
						ch_total: pro.ch_total,
					};
				} else {
					profissionais2[`semana${pro.semana - 1}`][pro.dia] = {
						nome: pro.nome,
						vinculo: pro.vinculo,
						celular: pro.celular,
						especialidade: pro.especialidade,
						ch_total: pro.ch_total,
					};
				}
			}
		});
		localStorage.setItem('cabeçalho', JSON.stringify(cabecalho));
		localStorage.setItem('profissionais', JSON.stringify(profissionais));
		localStorage.setItem('profissionais2', JSON.stringify(profissionais2));
		localStorage.setItem('lastEdit', filteredData[0].modificacao);
		window.location.reload();
	};

	const toggleAccordion = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		const ScaleDataById = async () => {
			const user = JSON.parse(Cookies.get('user'));

			let id = user.id;

			if (user.unidade) {
				const gestData = await requestGestData({
					token: user.token,
					nome: user.unidade,
				});

				id = gestData[0].id.toString();
			}

			const { data } = await requestScaleData({
				token: user.token,
				id,
			});
			const object = { plantonista: [], rotina: [], padi: [] };
			data.forEach((item) => {
				const key = item.regimes.toLowerCase();
				if (data) {
					if (item.mes === moment().format('MMMM').toLowerCase()) {
						if (!object[key].includes(item.setores)) {
							return object[key].push(item.setores);
						}
					}
				}
			});

			return setCheckTable(object);
		};
		ScaleDataById();
	}, [moment]);

	return (
		<div className='z-40 fixed top-10 my-4  left-[47%] right-1 md:top-14  md:my-4 '>
			<div className='flex justify-end'>
				<button
					onClick={toggleAccordion}
					title='CLIQUE PARA EXIBIR A LISTA DE TABELAS JÁ PREENCHIDAS '
					className='flex justify-end scale-50 hover:scale-60 hover:opacity-100  md:scale-100  opacity-80 w-[30px] h-[30px] text-center p-2 bg-blue-300 text-white text-sm font-bold rounded-lg hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-200 '>
					<svg
						className='absolute left-[5px] w-5 h-5  text-white   dark:text-white'
						aria-hidden='true'
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 14 8'>
						<path
							stroke='currentColor'
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeWidth='2'
							d='m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1'
						/>
					</svg>
					<svg
						className='w-6 h-6  text-white animate-ping  dark:text-white'
						aria-hidden='true'
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 14 8'>
						<path
							stroke='currentColor'
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeWidth='2'
							d='m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1'
						/>
					</svg>
				</button>
			</div>
			<div
				ref={contentRef}
				style={{
					maxHeight: isOpen
						? `${contentRef.current.scrollHeight}px`
						: '0px',
				}}
				className='overflow-hidden  fixed right-1 transition-all duration-500 ease-in-out '>
				<div
					className='mr-[50px] border-[3px]  border-white   md:mr-0 pl-4 pr-3 py-2  bg-gray-100
				 md:mt-1 rounded-md'>
					<div className='flex mb-2 gap-x-5 text-center w-full  mx-auto'>
						<span
							hidden={JSON.parse(Cookies.get('user'))
								.unidade.toString()
								.includes('padi')}
							className='flex-1 font-bold w-full text-[#004A80] text-start'>
							Plantonista
						</span>
						<span
							hidden={JSON.parse(Cookies.get('user'))
								.unidade.toString()
								.includes('padi')}
							className='flex-1 font-bold w-full text-[#004A80] text-start'>
							Rotina
						</span>
						<span
							hidden={
								!JSON.parse(Cookies.get('user'))
									.unidade.toString()
									.includes('padi')
							}
							className='flex-1 font-bold  text-start w-full text-[#004A80] '>
							PADI
						</span>
					</div>
					{checkTable ? (
						<ul className='list-none  min-h-[20px] '>
							{objectLength().map((item, index) => (
								<li
									key={index}
									className='flex items-start gap-x-5   '
									onClick={(event) => visualizeScale(event)}>
									{/* plantonista */}{' '}
									<button
										hidden={JSON.parse(Cookies.get('user'))
											.unidade.toString()
											.includes('padi')}
										id={
											checkTable.plantonista[index]
												? checkTable.plantonista[
														index
												  ].toUpperCase()
												: null
										}
										className='flex-1 w-[100px] py-1  font-bold text-[10px] text-wrap hover:scale-105  transition-all  text-start text-gray-600 '>
										{checkTable.plantonista[index]
											? checkTable.plantonista[
													index
											  ].toUpperCase()
											: null}
									</button>
									{/* rotina */}
									<button
										hidden={JSON.parse(Cookies.get('user'))
											.unidade.toString()
											.includes('padi')}
										onClick={(event) =>
											visualizeScale(event)
										}
										id={
											checkTable.rotina[index]
												? checkTable.rotina[
														index
												  ].toUpperCase()
												: null
										}
										className='flex-1 text-start hover:scale-105 w-[100px] transition-all  py-1 font-bold text-[10px] text-wrap  text-gray-600  h-max   '>
										{checkTable.rotina[index]
											? checkTable.rotina[
													index
											  ].toUpperCase()
											: null}
									</button>
									{/* PADI */}
									<button
										hidden={
											!JSON.parse(Cookies.get('user'))
												.unidade.toString()
												.includes('padi')
										}
										onClick={(event) =>
											visualizeScale(event)
										}
										id={
											checkTable.padi[index]
												? checkTable.padi[
														index
												  ].toUpperCase()
												: null
										}
										className='flex-1 text-start font-bold w-[100px] py-1 text-[10px] text-wrap hover:scale-105 transition-all  text-gray-600  h-max   '>
										{checkTable.padi[index]
											? checkTable.padi[
													index
											  ].toUpperCase()
											: null}
									</button>
								</li>
							))}
						</ul>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default CheckTable;
