import React from 'react';

function UrgenciaSVG() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='48'
			height='48'
			viewBox='0 0 48 48'
			fill='none'>
			<path
				d='M19.3333 48V32.1333L5.6 40.0667L0.933334 32L14.6667 24L0.933334 16.0667L5.6 8L19.3333 15.9333V0H28.6667V15.9333L42.4 8L47.0667 16.0667L33.3333 24L47.0667 32L42.4 40.0667L28.6667 32.1333V48H19.3333Z'
				fill='white'
			/>
		</svg>
	);
}

export default UrgenciaSVG;
