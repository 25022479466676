import React, { useState, useEffect } from 'react';
import NavBar from '../../../components/NavBar';
import Footer from '../../../components/Footer';
import { requestUsers, updatePermissions } from '../../../services/requests';

import Cookies from 'js-cookie';
/* JSON.parse(Cookies.get('user')); */

const Permissionador = () => {
	const [profissionais, setProfissionais] = useState([]);
	/* [
		{ id: 1, nome: 'João Silva', permissoes: 'escala' },
		{ id: 2, nome: 'Maria Souza', permissoes: 'notificacao' },
		{ id: 3, nome: 'Pedro Oliveira', permissoes: 'escala/notificacao' },
	] */
	const [windowAlert, setWindowAlert] = useState('loading');

	const handleRequest = async () => {
		const userVerification = Cookies.get('user');

		if (userVerification) {
			const user = JSON.parse(Cookies.get('user'));
			const token = user?.token;
			const response = await requestUsers({ token });
			if (response.error) {
				console.log('Erro na requisição:', response.error);
				return;
			}
			const result = response.filter((item) => {
				const user = JSON.parse(Cookies.get('user'));
				return item.unidade === user.unidade;
			});
			setProfissionais(result);
		}
	};

	useEffect(() => {
		handleRequest();
		if (Cookies.get('user')) {
			const user = JSON.parse(Cookies.get('user'));
			if (
				user?.cargo?.includes('gestor') ||
				user?.cargo?.includes('desenvolvedor')
			) {
				setWindowAlert(false);
			}
		}
	}, []);

	const handleCheckboxChange = (profissionalId, permissao) => {
		setProfissionais((prevProfissionais) =>
			prevProfissionais.map((profissional) => {
				if (profissional.id === profissionalId) {
					const possuiPermissao =
						profissional.permissao.includes(permissao);
					const novasPermissoes = possuiPermissao
						? profissional.permissao
								.split('/')
								.filter((p) => p !== permissao)
								.join('/')
						: `${profissional.permissao}/${permissao}`.replace(
								/^\/|\/$/g,
								''
						  );
					/* console.log({
						...profissional,
						permissao: novasPermissoes,
					}); */
					return { ...profissional, permissao: novasPermissoes };
				}

				return profissional;
			})
		);
	};

	const handleSubmit = () => {
		const permissoesAtualizadas = profissionais.map((p) => ({
			id: p.id,
			permissao: p.permissao,
		}));
		const token = JSON.parse(Cookies.get('user')).token;
		const userConfirmed = window.confirm(
			'Tem certeza que deseja continuar com as alterações?'
		);

		if (userConfirmed) {
			console.log({ token, body: permissoesAtualizadas });
			updatePermissions({ token, body: permissoesAtualizadas });
			/* window.location.reload(); */
		} else {
			let aux = [];
			let result = permissoesAtualizadas.forEach((item) => {
				if (item.permissao === 'notificacoes/escala') {
					let curr = item;
					curr.permissao = 'escala/notificacoes';
					aux.push(curr);
				} else {
					aux.push(result);
				}
			});
			/* console.log(aux);
			console.log(permissoesAtualizadas);
			console.log('Usuário cancelou a ação.'); */
		}
	};

	return (
		<div className='flex flex-col min-h-screen'>
			<NavBar />
			{windowAlert ? (
				<section className='bg-[#004A80] rounded-md w-[90%] sm:w-[70%] md:w-[60%] lg:w-[50%] mt-20 mx-auto my-auto'>
					<div className='py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
						<div className='mx-auto max-w-screen-sm text-center'>
							<h1 className='mb-4 text-white text-5xl sm:text-6xl lg:text-7xl tracking-tight font-extrabold text-primary-600'>
								401
							</h1>
							<p className='mb-10 text-2xl sm:text-3xl md:text-4xl tracking-tight font-bold text-slate-100'>
								Acesso Negado
							</p>
							<p className='mb-7 text-sm sm:text-base md:text-lg font-light text-gray-300'>
								Você não possui permissão para acessar este
								campo. Entre em contato com o administrador(a)
								da sua unidade para solicitação de acesso.
							</p>
							<a
								href='/login'
								className='inline-flex hover:scale-105 transition-transform text-gray-300 bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4'>
								Volte para a Tela de Login
							</a>
						</div>
					</div>
				</section>
			) : (
				<div className='flex-grow mt-32 mb-2'>
					{profissionais.length <= 0 ? (
						<div>ainda nao carregou</div>
					) : (
						<div className='max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg'>
							<h2 className='text-2xl font-bold mb-12 text-gray-700 text-center'>
								Administrador de Permissões
							</h2>
							<div className='overflow-x-auto'>
								<table className='min-w-full divide-y divide-gray-200'>
									<thead className='bg-gray-50'>
										<tr>
											<th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
												Profissional
											</th>
											<th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
												Permissão Escala
											</th>
											<th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
												Permissão Notificação
											</th>
										</tr>
									</thead>

									<tbody className='bg-white divide-y divide-gray-200'>
										{profissionais.map((profissional) => (
											<tr key={profissional.id}>
												<td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
													{profissional.nome}
												</td>
												<td className='px-6 py-4 whitespace-nowrap text-sm'>
													<input
														type='checkbox'
														checked={profissional.permissao.includes(
															'escala'
														)}
														onChange={() =>
															handleCheckboxChange(
																profissional.id,
																'escala'
															)
														}
														className='rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
													/>
												</td>
												<td className='px-6 py-4 whitespace-nowrap text-sm'>
													<input
														type='checkbox'
														checked={profissional.permissao.includes(
															'notificacoes'
														)}
														onChange={() =>
															handleCheckboxChange(
																profissional.id,
																'notificacoes'
															)
														}
														className='rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
													/>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							<div className='text-center mt-6'>
								<button
									onClick={handleSubmit}
									className='px-6 py-2 mt-10 bg-blue-600 text-white rounded-md hover:bg-blue-700'>
									Enviar Alterações
								</button>
							</div>
						</div>
					)}
				</div>
			)}
			<Footer />
		</div>
	);
};

export default Permissionador;
