import React, { useState, useEffect, useRef } from 'react';
import logo from '../images/logo.png';
import { requestCreateScale, requestGestData } from '../services/requests';
import { medicos } from '../pages/servicos/descricoes e contatos/escala';
import ExcelExport from './ExcelExport';
import { exportTableAsImageOrPDF } from './exportTableAsImageOrPDF';

import Cookies from 'js-cookie';
/* JSON.parse(Cookies.get('user')); */

/* import jsPDF from 'jspdf';
import 'jspdf-autotable'; */

const moment = require('moment');
require('moment/locale/pt-br');
moment.locale('pt-br');

function VanillaTableScale({ onResize, inputsCabeçalho }) {
	const [turnWhite, setTurnWhite] = useState(false);
	const [excelFormat, setExcelFormat] = useState(false);
	const [saveButtonClicked, setSaveButtonClicked] = useState(true);
	const [reload, setReload] = useState(false);
	const [prosList, setProsList] = useState([]);
	const [prosList2, setProsList2] = useState([]);
	const [weekNumber, setWeekNumber] = useState(1);
	const [weekNumber2, setWeekNumber2] = useState(1);
	const [headList, setHeadList] = useState([]);
	const [editDate, setEditDate] = useState('');
	const objectFormat = {
		segunda: {},
		terça: {},
		quarta: {},
		quinta: {},
		sexta: {},
		sábado: {},
		domingo: {},
	};
	const [inputValues, setInputValues] = useState({
		semana0: {
			segunda: {},
			terça: {},
			quarta: {},
			quinta: {},
			sexta: {},
			sábado: {},
			domingo: {},
		},
	});
	const [inputValuesnight, setinputValuesnight] = useState({
		semana0: {
			segunda: {},
			terça: {},
			quarta: {},
			quinta: {},
			sexta: {},
			sábado: {},
			domingo: {},
		},
	});
	const vincs = [
		'rio-saúde',
		'spdm',
		'vivario',
		'fas',
		'gnosis',
		'ideias',
		'cejam',
		'direta temporário',
		'direta efetiva',
	];

	const sem = [
		'segunda',
		'terça',
		'quarta',
		'quinta',
		'sexta',
		'sábado',
		'domingo',
	];
	const cleanTable = () => {
		localStorage.removeItem('profissionais');
		localStorage.removeItem('profissionais2');
		let model = {
			semana0: {
				segunda: {},
				terça: {},
				quarta: {},
				quinta: {},
				sexta: {},
				sábado: {},
				domingo: {},
			},
		};
		setInputValues(model);
		setinputValuesnight(model);
		setProsList([]);
		setProsList2([]);
		setReload(true);
		setTimeout(() => {
			setReload(false);
		}, '1000');
	};

	const formRef = useRef(null);

	const tableRef = useRef(null);
	const verificarEscalaCompleta = (escala) => {
		const diasDaSemana = [
			'segunda',
			'terça',
			'quarta',
			'quinta',
			'sexta',
			'sábado',
			'domingo',
		];
		const verificarTurno = (turno) => {
			return Object.keys(turno).every((semana) => {
				return diasDaSemana.every((dia) => {
					const diaData = turno[semana][dia];
					return (
						diaData &&
						diaData.nome &&
						diaData.especialidade &&
						diaData.celular &&
						diaData.vinculo &&
						diaData.ch_total
					);
				});
			});
		};
		if (
			localStorage.getItem('profissionais') === null ||
			localStorage.getItem('cabeçalho') === null
		) {
			return false;
		} else {
			if (escala.cabecalho.turnos) {
				const diaKey = escala.cabecalho.turnos
					.split(' /')[0]
					.toLowerCase();
				const noiteKey = escala.cabecalho.turnos
					.split('/ ')[1]
					.toLowerCase();
				const diurnoCompleto = verificarTurno(escala[diaKey]);
				const noturnoCompleto = verificarTurno(escala[noiteKey]);
				return diurnoCompleto && noturnoCompleto;
			}
		}
	};

	const formatDataForExcel = (data) => {
		/* console.log(data); */
		const formattedData = [];
		const flattenWeekData = (shiftType, weekData, semana) => {
			Object.keys(weekData).forEach((day) => {
				formattedData.push({
					user_id: data.cabecalho.user_id,
					Nome: weekData[day]?.nome || '',
					Especialidade: weekData[day]?.especialidade || '',
					Celular: weekData[day]?.celular || '',
					'Carga Horária Total': weekData[day]?.ch_total || '',
					Dia: day,
					Modificação: moment(new Date()).format('DD/MM/YYYY'),
					Vínculo: weekData[day]?.vinculo || '',
					Regime: data.cabecalho.regimes,
					Setor: data.cabecalho.setores,
					Turno: shiftType,
					Unidade: data.cabecalho.unidades,
					Mês: moment().format('MMMM').toUpperCase(),
					Semana: Number(semana[semana.length - 1]) + 1,
				});
			});
		};
		Object.keys(data).forEach((key) => {
			if (key !== 'cabecalho') {
				const turno = data[key];
				Object.keys(turno).forEach((semana) => {
					if (turno[semana]) {
						flattenWeekData(
							key.charAt(0).toUpperCase() + key.slice(1),
							turno[semana],
							semana
						);
					}
				});
			}
		});
		let result = [];
		formattedData.forEach((item) => {
			if (
				!(
					item.Nome.length === 0 &&
					item.Especialidade.length === 0 &&
					item.Celular.length === 0
				)
			) {
				result.push(item);
			}
		});

		return result;
	};
	/* const generatePDF = () => {
		const doc = new jsPDF();

		// Este é o objeto de exemplo que você forneceu
		const data = {
			cabecalho: {
				unidades: 'hospital maternidade carmela dutra',
				setores: 'agência transfusional',
				regimes: 'rotina',
				turnos: 'manhã / noite',
				user_id: 39,
			},
			manhã: {
				semana0: {
					domingo: {
						nome: 'guilherme pimenta leonel',
						vinculo: 'rio-saúde',
						celular: '(21) 99937-1100',
						especialidade: 'médico',
						ch_total: '12',
					},
					segunda: {
						nome: 'guilherme pimenta leonel',
						vinculo: 'rio-saúde',
						celular: '(21) 99937-1100',
						especialidade: 'médico',
						ch_total: '12',
					},
					terça: {
						nome: 'guilherme pimenta leonel',
						vinculo: 'rio-saúde',
						celular: '(21) 99937-1100',
						especialidade: 'médico',
						ch_total: '12',
					},
					quarta: {
						nome: 'guilherme pimenta leonel',
						vinculo: 'rio-saúde',
						celular: '(21) 99937-1100',
						especialidade: 'médico',
						ch_total: '12',
					},
					quinta: {
						nome: 'guilherme pimenta leonel',
						vinculo: 'rio-saúde',
						celular: '(21) 99937-1100',
						especialidade: 'médico',
						ch_total: '12',
					},
					sexta: {
						nome: 'guilherme pimenta leonel',
						vinculo: 'rio-saúde',
						celular: '(21) 99937-1100',
						especialidade: 'médico',
						ch_total: '12',
					},
					sábado: {
						nome: 'VACÂNCIA',
						vinculo: 'rio-saúde',
						celular: '0000000000',
						especialidade: 'médico',
						ch_total: '12',
					},
				},
			},
			noite: {
				semana0: {
					domingo: {},
					segunda: {},
					terça: {},
					quarta: {},
					quinta: {
						nome: 'antonio leonel',
						vinculo: 'rio-saúde',
						celular: '(21) 99937-1100',
						especialidade: 'médico',
						ch_total: '12',
					},
					sexta: {
						nome: 'VACÂNCIA',
						vinculo: 'rio-saúde',
						celular: '0000000000',
						especialidade: 'médico',
						ch_total: '12',
					},
					sábado: {},
				},
			},
		};

		// Adicionar o cabeçalho no PDF
		doc.text('Hospital: ' + data.cabecalho.unidades, 10, 10);
		doc.text('Setor: ' + data.cabecalho.setores, 10, 20);
		doc.text('Regime: ' + data.cabecalho.regimes, 10, 30);
		doc.text('Turnos: ' + data.cabecalho.turnos, 10, 40);

		// Função auxiliar para gerar as linhas da tabela
		const getTableRows = (turno) => {
			const diasSemana = [
				'domingo',
				'segunda',
				'terça',
				'quarta',
				'quinta',
				'sexta',
				'sábado',
			];
			return diasSemana.map((dia) => {
				const dataTurno = data[turno].semana0[dia] || {};
				return [
					dia.charAt(0).toUpperCase() + dia.slice(1),
					dataTurno.nome || 'VACÂNCIA',
					dataTurno.vinculo || '-',
					dataTurno.celular || '-',
					dataTurno.especialidade || '-',
					dataTurno.ch_total || '-',
				];
			});
		};

		// Definir colunas da tabela
		const tableColumns = [
			'Dia da Semana',
			'Nome',
			'Vínculo',
			'Celular',
			'Especialidade',
			'CH Total',
		];

		// Adicionar tabela para o turno "Manhã"
		doc.text('Turno: Manhã', 10, 50);
		doc.autoTable({
			startY: 60,
			head: [tableColumns],
			body: getTableRows('manhã'),
		});

		// Adicionar tabela para o turno "Noite"
		doc.text('Turno: Noite', 10, doc.lastAutoTable.finalY + 10);
		doc.autoTable({
			startY: doc.lastAutoTable.finalY + 20,
			head: [tableColumns],
			body: getTableRows('noite'),
		});

		// Salvar o PDF
		doc.save('escalas.pdf');
	};
 */
	const handleInputChange = async (e) => {
		const { name, value, id } = e.target;
		const numeroParaDia = (n) => sem[n - 1];
		const semanaIndex = Number(id[0]);
		const diaIndex = Number(id[1]) + 1;
		const isinputValuesnight = Boolean(id[2]);
		let date = moment(new Date()).format('DD/MM/YYYY');
		setEditDate(date);
		if (isinputValuesnight) {
			const updatedInputValuesnight = JSON.parse(
				JSON.stringify(inputValuesnight)
			);
			updatedInputValuesnight[`semana${semanaIndex}`][
				numeroParaDia(diaIndex)
			] = {
				...updatedInputValuesnight[`semana${semanaIndex}`][
					numeroParaDia(diaIndex)
				],
				[name]: value.toLowerCase(),
			};
			let response = Object.values(updatedInputValuesnight);

			setinputValuesnight(updatedInputValuesnight);
			setProsList2(response);
		} else {
			const updatedInputValues = JSON.parse(JSON.stringify(inputValues));
			updatedInputValues[`semana${semanaIndex}`][
				numeroParaDia(diaIndex)
			] = {
				...updatedInputValues[`semana${semanaIndex}`][
					numeroParaDia(diaIndex)
				],
				[name]: value.toLowerCase(),
			};
			let response = Object.values(updatedInputValues);

			setInputValues(updatedInputValues);
			setProsList(response);
		}

		const localId = JSON.parse(Cookies.get('user'));
		const head = inputsCabeçalho;
		const pros = inputValues;
		const pros2 = inputValuesnight;
		let response = localId.id;
		verificarEscalaCompleta({
			cabecalho: { ...head, user_id: response },
			[head.turnos.split(' /')[0]]: pros,
			[head.turnos.split('/ ')[1]]: pros2,
		});
		setSaveButtonClicked(false);
		if (localId.unidade) {
			const gestData = await requestGestData({
				token: localId.token,
				nome: localId.unidade,
			});
			response = gestData[0].id.toString();
		}
		setExcelFormat(
			formatDataForExcel({
				cabecalho: { ...head, user_id: response },
				[head.turnos.split(' /')[0]]: pros,
				[head.turnos.split('/ ')[1]]: pros2,
			})
		);
	};

	/* const excelClick = async () => {
		const localId = JSON.parse(Cookies.get('user'));
		const head = inputsCabeçalho;
		const pros = inputValues;
		const pros2 = inputValuesnight;
		let response = localId.id;
		setExcelFormat(
			formatDataForExcel({
				cabecalho: { ...head, user_id: response },
				[head.turnos.split(' /')[0]]: pros,
				[head.turnos.split('/ ')[1]]: pros2,
			})
		);
	}; */

	const addVacancy = (e) => {
		const { id } = e.target;
		const numeroParaDia = (n) => sem[n - 1];
		const semanaIndex = Number(id[0]);
		const diaIndex = Number(id[1]) + 1;
		const isinputValuesnight = Boolean(id[2]);
		let date = moment(new Date()).format('DD/MM/YYYY');
		setEditDate(date);
		const updateValues = (values) => {
			const aux = { ...values };
			aux[`semana${semanaIndex}`][numeroParaDia(diaIndex)] = {
				...aux[`semana${semanaIndex}`][numeroParaDia(diaIndex)],
				nome: 'VACÂNCIA',
				celular: '0000000000',
			};
			return aux;
		};
		if (isinputValuesnight) {
			setinputValuesnight(updateValues(inputValuesnight));
			setProsList2(Object.values(inputValuesnight));
		} else {
			setInputValues(updateValues(inputValues));
			setProsList(Object.values(inputValues));
		}
		saveTable();
	};

	const removeDataOnClick = (e) => {
		const { id } = e.target;
		if (id[2]) {
			const dataName = e.target.getAttribute('dataname');
			const numeroParaDia = (n) => sem[n - 1];
			const semanaIndex = Number(id[0]);
			const diaIndex = Number(id[1]) + 1;
			let aux = { ...inputValuesnight };
			let date = moment(new Date()).format('DD/MM/YYYY');
			setEditDate(date);
			aux[`semana${semanaIndex}`][numeroParaDia(diaIndex)] = {
				...aux[`semana${semanaIndex}`][numeroParaDia(diaIndex)],
				[dataName]: '',
			};
			setinputValuesnight(aux);
		} else {
			const dataName = e.target.getAttribute('dataname');
			const numeroParaDia = (n) => sem[n - 1];
			const semanaIndex = Number(id[0]);
			const diaIndex = Number(id[1]) + 1;
			let aux = { ...inputValues };
			aux[`semana${semanaIndex}`][numeroParaDia(diaIndex)] = {
				...aux[`semana${semanaIndex}`][numeroParaDia(diaIndex)],
				[dataName]: '',
			};
			setInputValues(aux);
		}
	};

	const formatPhoneNumber = (value) => {
		value = value.replace(/\D/g, '');
		if (value.length <= 10) {
			return value.replace(/^(\d{2})(\d{4})(\d{0,4})$/, '($1) $2-$3');
		} else {
			return value.replace(/^(\d{2})(\d{5})(\d{0,4})$/, '($1) $2-$3');
		}
	};

	const removeLineButton = async (e) => {
		const { id } = e.target;
		let date = moment(new Date()).format('DD/MM/YYYY');
		setEditDate(date);
		if (id !== 'noite') {
			setWeekNumber(weekNumber - 1);
			const obj = inputValues;
			let pros = [];
			const keys = Object.keys(obj);
			const lastKey = keys[keys.length - 1];
			delete obj[lastKey];
			prosList.forEach((item, index) => {
				if (index !== prosList.length - 1) {
					pros.push(item);
				}
			});

			setProsList(pros);
			setInputValues(obj);
		} else {
			setWeekNumber2(weekNumber2 - 1);
			const obj = inputValuesnight;
			let pros2 = [];
			const keys = Object.keys(obj);
			const lastKey = keys[keys.length - 1];
			delete obj[lastKey];
			prosList2.forEach((item, index) => {
				if (index !== prosList2.length - 1) {
					pros2.push(item);
				}
			});
			setProsList2(pros2);
			setinputValuesnight(obj);
		}
		const localId = JSON.parse(Cookies.get('user'));
		const cabecalho = inputsCabeçalho;
		let response = localId.id;
		verificarEscalaCompleta({
			cabecalho: { ...cabecalho, user_id: response },
			[cabecalho.turnos.split(' /')[0]]: inputValues,
			[cabecalho.turnos.split('/ ')[1]]: inputValuesnight,
		});

		if (localId.unidade) {
			const gestData = await requestGestData({
				token: localId.token,
				nome: localId.unidade,
			});
			response = gestData[0].id.toString();
		}

		setExcelFormat(
			formatDataForExcel({
				cabecalho: { ...cabecalho, user_id: response },
				[cabecalho.turnos.split(' /')[0]]: inputValues,
				[cabecalho.turnos.split('/ ')[1]]: inputValuesnight,
			})
		);
		setSaveButtonClicked(true);
	};

	const createLineButton = (e) => {
		const localId = JSON.parse(Cookies.get('user'));
		const cabecalho = inputsCabeçalho;
		const response = localId.id;
		const { id } = e.target;
		let date = moment(new Date()).format('DD/MM/YYYY');
		setEditDate(date);
		if (id !== 'noite') {
			const obj = inputValues;

			setProsList([...prosList, objectFormat]);
			setInputValues({
				...obj,
				[`semana${weekNumber}`]: objectFormat,
			});
			setWeekNumber(weekNumber + 1);
			verificarEscalaCompleta({
				cabecalho: { ...cabecalho, user_id: response },
				[cabecalho.turnos.split(' /')[0]]: {
					...inputValues,
					[`semana${weekNumber}`]: objectFormat,
				},
				[cabecalho.turnos.split('/ ')[1]]: inputValuesnight,
			});
		} else {
			const obj = inputValuesnight;

			setProsList2([...prosList2, objectFormat]);
			setinputValuesnight({
				...obj,
				[`semana${weekNumber2}`]: objectFormat,
			});
			setWeekNumber2(weekNumber2 + 1);
			verificarEscalaCompleta({
				cabecalho: { ...cabecalho, user_id: response },
				[cabecalho.turnos.split(' /')[0]]: inputValues,
				[cabecalho.turnos.split('/ ')[1]]: {
					...inputValuesnight,
					[`semana${weekNumber2}`]: objectFormat,
				},
			});
		}
	};

	const saveTable = () => {
		const hasInputValues = Object.keys(inputValues).length > 0;
		const hasinputValuesnight = Object.keys(inputValuesnight).length > 0;
		let date = moment(new Date()).format('DD/MM/YYYY');
		localStorage.setItem('lastEdit', date);
		setEditDate(date);
		if (hasInputValues || hasinputValuesnight) {
			if (hasInputValues) {
				localStorage.setItem(
					'profissionais',
					JSON.stringify(inputValues)
				);
			}
			if (hasinputValuesnight) {
				localStorage.setItem(
					'profissionais2',
					JSON.stringify(inputValuesnight)
				);
			}
		} else {
			localStorage.removeItem('profissionais');
			localStorage.removeItem('profissionais2');
		}
		setSaveButtonClicked(true);

		const localId = JSON.parse(Cookies.get('user'));
		const head = inputsCabeçalho;
		const pros = inputValues;
		const pros2 = inputValuesnight;
		let response = localId.id;
		setExcelFormat(
			formatDataForExcel({
				cabecalho: { ...head, user_id: response },
				[head.turnos.split(' /')[0]]: pros,
				[head.turnos.split('/ ')[1]]: pros2,
			})
		);
	};

	const logDoProduto = () => {
		const localId = JSON.parse(Cookies.get('user'));
		const head = inputsCabeçalho;
		const pros = inputValues;
		const pros2 = inputValuesnight;
		const response = localId.id;

		console.log([
			{
				cabecalho: { ...head, user_id: response },
				[head.turnos.split(' /')[0]]: pros,
				[head.turnos.split('/ ')[1]]: pros2,
			},
		]);
	};

	const submitTable = async () => {
		const LocalUser = JSON.parse(Cookies.get('user'));
		const head = inputsCabeçalho;
		const pros = inputValues;
		const pros2 = inputValuesnight;
		let id;

		if (LocalUser.unidade) {
			const gestData = await requestGestData({
				token: LocalUser.token,
				nome: LocalUser.unidade,
			});
			id = gestData[0].id.toString();
		}

		let date = moment(new Date()).format('DD/MM/YYYY');
		setEditDate(date);
		let keys = Object.keys(head);
		let values = Object.values(head);
		keys.forEach((key, index) => {
			head[key] = values[index].toLowerCase();
		});
		await requestCreateScale(LocalUser.token, [
			{
				cabecalho: { ...head, user_id: id },
				[head.turnos.split(' /')[0]]: pros,
				[head.turnos.split('/ ')[1]]: pros2,
			},
		]);
		setReload(true);
		setTimeout(() => {
			setReload(false);
		}, 1000);
	};
	useEffect(() => {
		if (localStorage.getItem('lastEdit')) {
			setEditDate(localStorage.getItem('lastEdit'));
		}
		const head = JSON.parse(localStorage.getItem('cabeçalho')) || [];
		const pros = JSON.parse(localStorage.getItem('profissionais')) || [];
		const pros2 = JSON.parse(localStorage.getItem('profissionais2')) || [];

		const localId = JSON.parse(Cookies.get('user'));
		const cabecalho = inputsCabeçalho;
		let response = localId.id;

		verificarEscalaCompleta({
			cabecalho: { ...cabecalho, user_id: response },
			[cabecalho.turnos.split(' /')[0]]: pros,
			[cabecalho.turnos.split('/ ')[1]]: pros2,
		});

		const excelId = async () => {
			if (localId.unidade) {
				const gestData = await requestGestData({
					token: localId.token,
					nome: localId.unidade,
				});
				response = gestData[0].id.toString();
			}

			if (head[0]) {
				setExcelFormat(
					formatDataForExcel({
						cabecalho: { ...head[0], user_id: response },
						[head[0].turnos.split(' /')[0]]: pros,
						[head[0].turnos.split('/ ')[1]]: pros2,
					})
				);
			} else {
				setExcelFormat(
					formatDataForExcel({
						cabecalho: { ...cabecalho, user_id: response },
						[cabecalho.turnos.split(' /')[0]]: pros,
						[cabecalho.turnos.split('/ ')[1]]: pros2,
					})
				);
			}
		};

		excelId();

		setSaveButtonClicked(true);

		setHeadList(head);
		setProsList(Object.values(pros));
		setProsList2(Object.values(pros2));
		if (pros) {
			if (Object.values(pros).length > 0) {
				setInputValues(pros);
				setWeekNumber(Object.values(pros).length);
			}
		}
		if (pros2) {
			if (Object.values(pros2).length > 0) {
				setinputValuesnight(pros2);
				setWeekNumber2(Object.values(pros2).length);
			}
		}

		const handleResize = () => {
			if (onResize) {
				onResize(tableRef.current);
			}
		};
		const resizeObserver = new ResizeObserver(handleResize);
		const currentTableRef = tableRef.current;
		if (currentTableRef) {
			resizeObserver.observe(currentTableRef);
		}
		return () => {
			if (currentTableRef) {
				resizeObserver.unobserve(currentTableRef);
			}
		};
	}, [onResize, inputsCabeçalho]);

	return (
		<div>
			{reload ? (
				<div
					className='flex justify-center h-[1200px] content-center align-bottom items-baseline  w-full my-20'
					role='status'>
					<svg
						aria-hidden='true'
						className='w-8 h-8 text-gray-200 animate-spin mt-[800px]  fill-blue-600'
						viewBox='0 0 100 101'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
							fill='currentColor'
						/>
						<path
							d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
							fill='currentFill'
						/>
					</svg>
					<span className='sr-only'>Loading...</span>
				</div>
			) : (
				<div
					ref={formRef}
					id='my-table-id'
					className='mx-auto mb-20 mt-24 w-[81%]'>
					<p className=' flex mx-auto w-[100%] md:[[1690px]  mb-2 justify-start text-sm text-[#757575] '>
						Última Atualização: {editDate}
					</p>
					<div className='  overflow-x-auto'>
						{/* {' '}
						{!localStorage.getItem('cabeçalho') ? (
							<div className='absolute z-20 w-full h-full py-[300px] bg-white opacity-60 hover:opacity-90 transition text-gray-500 font-bold text-[90px] align-bottom hover-text m-auto left-0 right-0 top-0 bottom-0'>
								PREENCHA OS CAMPOS DO CABEÇALHO
							</div>
						) : null} */}
						<div className=' gap-1 flex md:border-2 w-[1315px] lg:w-full border-white text-white   '>
							<div className=' px-[30px]  text-[#004A80] py-11'>
								<p className='font-bold text-sm '>
									{moment().format('MMMM').toUpperCase()}
								</p>
								<p className='font-bold text-sm '>
									{moment().format('YYYY')}
								</p>
							</div>
							<div className='flex flex-col border-white justify-center text-black  w-full h-[11rem]'>
								{' '}
								<div className='flex font-semibold  flex-col mb-1  items-baseline justify-start  text-lg  mx-auto w-[100%] md:w-[50%]'>
									<div className='flex flex-row text-end  justify-around '>
										<span className='mr-2 py-3 w-[73px]'>
											Unidade:
										</span>
										<span className='text-3xl py-2 w-[565px]  font-bold text-start '>
											{headList.length > 0
												? headList[0].unidades.toUpperCase()
												: null}
										</span>
									</div>
									<div className='flex flex-row text-end  justify-around '>
										<span className='mr-2 py-3 w-[73px]'>
											Setor:
										</span>
										<span className='text-3xl py-2 w-[565px]  font-bold text-start '>
											{headList.length > 0
												? headList[0].setores.toUpperCase()
												: null}
										</span>
									</div>
								</div>
							</div>
							<div className='hidden md:block my-auto ml-0 mr-4'>
								<img
									className='w-[400px]'
									src={logo}
									alt='Logo da prefeitura'></img>
							</div>
						</div>
						{/* TABELA DE DIA */}
						<div className='relative w-[1524px]  lg:w-full border-x-2  border-white py-3 bg-[#004A80] font-semibold text-white overflow-x-auto'>
							<div className='w-[100%] pb-1 text-center'>
								<span>
									{headList.length > 0
										? headList[0].regimes.toUpperCase()
										: null}{' '}
								</span>
								{' - '}
								<span>
									{headList.length > 0
										? headList[0].turnos
												.split('/')[0]
												.toUpperCase()
										: null}{' '}
								</span>
							</div>
						</div>
						<table
							ref={tableRef}
							className='md:w-full text-sm text-left rtl:text-right text-white dark:text-gray-400'>
							<thead>
								<tr className='bg-[#004A80] border-2 border-white text-white'>
									<th
										scope='col'
										className='px-4 w-[10px] border-2 border-white py-4'>
										infos
									</th>
									{sem.map((item, index) => (
										<th
											key={index + 'n'}
											scope='col'
											className='px-2 w-[400px] border-2 font-extrabold text-md text-center border-white py-4'>
											{item.toUpperCase()}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{Array.from(
									{ length: weekNumber },
									(_, index) => index + 1
								).map((firstItem, semana) => (
									<tr
										key={semana + 's'}
										className='bg-[#F5F5F5] border-2 text-[12px] border-white'>
										<td className='px-2 py-2 content-start text-black border-2 border-white text-md'>
											<div className='flex flex-col justify-between h-full text-start font-medium'>
												<span className='py-1 flex-grow'>
													Nome:
												</span>
												<span className='py-1 flex-grow'>
													Celular:
												</span>
												<span className='py-1 flex-grow'>
													Vínculo:
												</span>
												<span className='py-1 flex-grow'>
													Especialidade:
												</span>
												<span className='py-1 flex-grow'>
													CH total:
												</span>
											</div>
										</td>
										{(Object.values(prosList)[semana]
											? Object.values(
													Object.values(prosList)[
														semana
													]
											  )
											: Array.from(
													{ length: 7 },
													(_, index) => index + 1
											  )
										).map((item, dia) => (
											<td
												key={dia + 't'}
												className='px-2 py-2 content-start text-black border-2 border-white text-md'>
												<div className='flex flex-col justify-between h-full text-start font-bold '>
													{/* NOME */}
													<span className='py-1 flex-grow'>
														{item.nome &&
														saveButtonClicked ? (
															<p
																dataname='nome'
																id={`${semana}${dia}`}
																className={`w-[186px]  ${
																	item.nome
																		.normalize(
																			'NFD'
																		)
																		.replace(
																			/[\u0300-\u036f]/g,
																			''
																		)
																		.toLowerCase() ===
																	'vacancia'
																		? 'text-red-500'
																		: ''
																}`}
																onClick={
																	removeDataOnClick
																}>
																{item.nome.toUpperCase()}
															</p>
														) : (
															<input
																type='text'
																name='nome'
																defaultValue={
																	item.nome
																}
																id={`${semana}${dia}`}
																onChange={
																	handleInputChange
																}
																placeholder={
																	turnWhite
																		? ''
																		: 'ex: Marcos Aurélio'
																}
																className={`w-[186px]  ${
																	turnWhite
																		? 'bg-gray-200'
																		: ''
																} uppercase ${
																	item.nome ===
																	'VACÂNCIA'
																		? 'text-red-500'
																		: ''
																}`}></input>
														)}
													</span>
													{/* Celular */}
													<span className='py-1'>
														{item.celular &&
														saveButtonClicked ? (
															<p
																dataname='celular'
																id={`${semana}${dia}`}
																className={`w-[186px]  ${
																	item.nome ===
																	'VACÂNCIA'
																		? 'text-red-500'
																		: ''
																}`}
																onClick={
																	removeDataOnClick
																}>
																{item.celular.toUpperCase()}
															</p>
														) : (
															<input
																id={`${semana}${dia}`}
																type='text'
																maxLength='15'
																defaultValue={
																	item.celular
																}
																onChange={(
																	e
																) => {
																	const formattedValue =
																		formatPhoneNumber(
																			e
																				.target
																				.value
																		);
																	e.target.value =
																		formattedValue;
																	handleInputChange(
																		e
																	);
																}}
																name='celular'
																placeholder={
																	turnWhite
																		? ' '
																		: 'ex: (21) 4002-8922'
																}
																className={`w-[186px] ${
																	turnWhite
																		? 'bg-gray-200'
																		: ''
																}  ${
																	item.nome ===
																	'VACÂNCIA'
																		? 'text-red-500'
																		: ''
																}`}></input>
														)}
													</span>

													{/* VINCULO */}
													<span className='py-1'>
														{item.vinculo &&
														saveButtonClicked ? (
															<p
																dataname='vinculo'
																id={`${semana}${dia}`}
																onClick={
																	removeDataOnClick
																}>
																{item.vinculo.toUpperCase()}
															</p>
														) : (
															<select
																type='text'
																name='vinculo'
																placeholder='ex: médico'
																id={`${semana}${dia}`}
																defaultValue={
																	item.vinculo
																}
																onChange={
																	handleInputChange
																}
																className={`w-[186px]  ${
																	turnWhite
																		? 'bg-gray-200'
																		: ''
																} `}>
																<option>
																	---
																</option>
																{vincs.map(
																	(item) => (
																		<option
																			key={
																				item
																			}
																			value={item.toLowerCase()}>
																			{item.toUpperCase()}
																		</option>
																	)
																)}
															</select>
														)}
													</span>
													{/* ESPECIALIDADE*/}
													<span className='py-1'>
														{item.especialidade &&
														saveButtonClicked ? (
															<p
																dataname='especialidade'
																id={`${semana}${dia}`}
																onClick={
																	removeDataOnClick
																}>
																{item.especialidade.toUpperCase()}
															</p>
														) : (
															<select
																type='text'
																name='especialidade'
																placeholder={
																	turnWhite
																		? ''
																		: 'ex: médico'
																}
																id={`${semana}${dia}`}
																defaultValue={
																	item.especialidade
																}
																onChange={
																	handleInputChange
																}
																className={`w-[186px]  ${
																	turnWhite
																		? 'bg-gray-200'
																		: ''
																}`}>
																<option>
																	---
																</option>
																{medicos.map(
																	(item) => (
																		<option
																			key={
																				item
																			}
																			value={item.toLowerCase()}>
																			{item.toUpperCase()}
																		</option>
																	)
																)}
															</select>
														)}
													</span>
													{/* CARGA HORARIA */}
													<span className='py-1'>
														{item.ch_total &&
														saveButtonClicked ? (
															<p
																dataname='ch_total'
																id={`${semana}${dia}`}
																onClick={
																	removeDataOnClick
																}>
																{item.ch_total.toUpperCase()}
															</p>
														) : (
															<div className='flex gap-2'>
																<input
																	type='number'
																	defaultValue={
																		item.ch_total
																	}
																	id={`${semana}${dia}`}
																	onChange={
																		handleInputChange
																	}
																	name='ch_total'
																	placeholder={
																		turnWhite
																			? ''
																			: 'ex: 12'
																	}
																	className={`w-[186px] ${
																		turnWhite
																			? 'bg-gray-200'
																			: ''
																	} `}></input>
															</div>
														)}
													</span>
													<span className='py-1 '>
														<button
															id={`${semana}${dia}`}
															type='button'
															onClick={addVacancy}
															className={`bg-gray-500 p-1 w-[186px]   hover:bg-gray-600 rounded-sm hover:opacity-100 text-white text-xs font-bold ${
																turnWhite
																	? 'hidden'
																	: null
															}`}>
															ADICIONAR VACÂNCIA
														</button>
													</span>
												</div>
											</td>
										))}
									</tr>
								))}
							</tbody>
						</table>
						{/* {console.log(
							'dia: ',
							inputValues,
							'noite:',
							inputValuesnight
						)}
						{console.log(
							'dia pros: ',
							prosList,
							'noite pros:',
							prosList2
						)} */}
						<div className='flex gap-x-2 py-2'>
							<button
								id='dia'
								type='button'
								onClick={createLineButton}
								className={`bg-slate-400 rounded-sm font-bold  text-white text-md px-2 hover:bg-slate-500 transition-all duration-200 ${
									turnWhite ? 'bg-white' : null
								} `}>
								{' '}
								Adcionar
							</button>
							<button
								type='button'
								id='dia'
								onClick={removeLineButton}
								className={`bg-slate-400 rounded-sm font-bold  text-white text-md px-2 hover:bg-slate-500 transition-all duration-200 ${
									turnWhite ? 'bg-white' : null
								} `}>
								{' '}
								Remover
							</button>
						</div>
						{/* TABELA DE NOITE */}
						<div className='mb-10'>
							<div className='relative w-[1524px] lg:w-full border-x-2  border-white py-3 bg-[#383838] font-semibold text-white overflow-x-auto'>
								<div className='w-[100%] pb-1 text-center'>
									<span>
										{headList.length > 0
											? headList[0].regimes.toUpperCase()
											: null}{' '}
									</span>
									{' - '}
									<span>
										{headList.length > 0
											? headList[0].turnos
													.split('/')[1]
													.toUpperCase()
											: null}{' '}
									</span>
								</div>
							</div>
							<table
								ref={tableRef}
								className='md:w-full text-sm text-left rtl:text-right text-white dark:text-gray-400'>
								<thead>
									<tr className='bg-[#3d3d3d] border-2 border-white text-white'>
										<th
											scope='col'
											className='px-4 w-[10px] border-2 border-white py-4'>
											infos
										</th>
										{sem.map((item, index) => (
											<th
												key={index + 'd'}
												scope='col'
												className='px-2 w-[400px] border-2 font-extrabold text-md text-center border-white py-4'>
												{item.toUpperCase()}
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{Array.from(
										{ length: weekNumber2 },
										(_, index) => index + 1
									).map((firstItem, semana) => (
										<tr
											key={semana + 'n'}
											className='bg-[#F5F5F5] border-2 text-[12px] font-semibold border-white'>
											<td className='px-2 py-2 content-start text-black border-2 border-white text-md'>
												<div className='flex flex-col justify-between h-full text-start font-medium'>
													<span className='py-1 flex-grow'>
														Nome:
													</span>
													<span className='py-1 flex-grow'>
														Celular:
													</span>
													<span className='py-1 flex-grow'>
														Vínculo:
													</span>
													<span className='py-1 flex-grow'>
														Especialidade:
													</span>
													<span className='py-1 flex-grow'>
														CH total:
													</span>
												</div>
											</td>

											{(Object.values(prosList2)[semana]
												? Object.values(
														Object.values(
															prosList2
														)[semana]
												  )
												: Array.from(
														{ length: 7 },
														(_, index) => index + 1
												  )
											).map((item, dia) => (
												<td
													key={dia + 'd'}
													className='px-2 py-2 content-start text-black border-2 border-white text-[12px]'>
													<div className='flex flex-col justify-between h-full text-start font-bold '>
														{/* NOME */}
														<span className='py-1 flex-grow'>
															{item.nome &&
															saveButtonClicked ? (
																<p
																	dataname='nome'
																	id={`${semana}${dia}n`}
																	className={`w-[186px]   ${
																		item.nome
																			.normalize(
																				'NFD'
																			)
																			.replace(
																				/[\u0300-\u036f]/g,
																				''
																			)
																			.toLowerCase() ===
																		'vacancia'
																			? 'text-red-500'
																			: ''
																	}`}
																	onClick={
																		removeDataOnClick
																	}>
																	{item.nome.toUpperCase()}
																</p>
															) : (
																<input
																	type='text'
																	name='nome'
																	defaultValue={
																		item.nome
																	}
																	id={`${semana}${dia}n`}
																	onChange={
																		handleInputChange
																	}
																	placeholder={
																		turnWhite
																			? ''
																			: 'ex: Marcos Aurélio'
																	}
																	className={`w-[186px] ${
																		turnWhite
																			? 'bg-gray-200'
																			: ''
																	}  uppercase ${
																		item.nome ===
																		'VACÂNCIA'
																			? 'text-red-500'
																			: 'text-black'
																	}`}></input>
															)}
														</span>
														{/* Celular */}
														<span className='py-1 flex-grow'>
															{item.celular &&
															saveButtonClicked ? (
																<p
																	dataname='celular'
																	id={`${semana}${dia}n`}
																	className={`w-[186px]  ${
																		item.nome ===
																		'VACÂNCIA'
																			? 'text-red-500'
																			: ''
																	}`}
																	onClick={
																		removeDataOnClick
																	}>
																	{item.celular.toUpperCase()}
																</p>
															) : (
																<input
																	id={`${semana}${dia}n`}
																	type='text'
																	maxLength='15'
																	defaultValue={
																		item.celular
																	}
																	onChange={(
																		e
																	) => {
																		const formattedValue =
																			formatPhoneNumber(
																				e
																					.target
																					.value
																			);
																		e.target.value =
																			formattedValue;
																		handleInputChange(
																			e
																		);
																	}}
																	name='celular'
																	placeholder={
																		turnWhite
																			? ''
																			: 'ex: (21) 4002-8922'
																	}
																	className={`w-[186px]  ${
																		turnWhite
																			? 'bg-gray-200'
																			: ''
																	} ${
																		item.nome ===
																		'VACÂNCIA'
																			? 'text-red-500'
																			: ''
																	}`}></input>
															)}
														</span>
														{/* VINCULO */}
														<span className='py-1 flex-grow'>
															{item.vinculo &&
															saveButtonClicked ? (
																<p
																	dataname='vinculo'
																	id={`${semana}${dia}dn`}
																	onClick={
																		removeDataOnClick
																	}>
																	{item.vinculo.toUpperCase()}
																</p>
															) : (
																<select
																	type='text'
																	name='vinculo'
																	placeholder='ex: médico'
																	id={`${semana}${dia}n`}
																	defaultValue={
																		item.vinculo
																	}
																	onChange={
																		handleInputChange
																	}
																	className={`w-[186px]  ${
																		turnWhite
																			? 'bg-gray-200'
																			: ''
																	}  `}>
																	<option>
																		---
																	</option>
																	{vincs.map(
																		(
																			item
																		) => (
																			<option
																				key={
																					item
																				}
																				value={item.toLowerCase()}>
																				{item.toUpperCase()}
																			</option>
																		)
																	)}
																</select>
															)}
														</span>
														{/* ESPECIALIDADE*/}
														<span className='py-1 flex-grow'>
															{item.especialidade &&
															saveButtonClicked ? (
																<p
																	dataname='especialidade'
																	id={`${semana}${dia}n`}
																	defaultValue={
																		'especialidade'
																	}
																	onClick={
																		removeDataOnClick
																	}>
																	{item.especialidade.toUpperCase()}
																</p>
															) : (
																<select
																	name='especialidade'
																	id={`${semana}${dia}n`}
																	defaultValue={
																		item.especialidade ||
																		''
																	}
																	onChange={
																		handleInputChange
																	}
																	className={`w-[186px] ${
																		turnWhite
																			? 'bg-gray-200'
																			: ''
																	} `}>
																	<option
																		value=''
																		disabled
																		hidden>
																		---
																	</option>
																	{medicos.map(
																		(
																			medico
																		) => (
																			<option
																				key={
																					medico
																				}
																				value={medico.toLowerCase()}>
																				{medico.toUpperCase()}
																			</option>
																		)
																	)}
																</select>
															)}
														</span>
														{/* CARGA HORARIA */}
														<span className='py-1 flex-grow'>
															{item.ch_total &&
															saveButtonClicked ? (
																<p
																	dataname='ch_total'
																	id={`${semana}${dia}n`}
																	defaultValue={
																		'ch_total'
																	}
																	onClick={
																		removeDataOnClick
																	}>
																	{item.ch_total.toUpperCase()}
																</p>
															) : (
																<input
																	type='number'
																	defaultValue={
																		item.ch_total
																	}
																	id={`${semana}${dia}n`}
																	onChange={
																		handleInputChange
																	}
																	name='ch_total'
																	placeholder={
																		turnWhite
																			? ''
																			: 'ex: 12'
																	}
																	className={`w-[186px]  ${
																		turnWhite
																			? 'bg-gray-200'
																			: ''
																	} `}></input>
															)}
														</span>
														<span className='py-1 '>
															<button
																id={`${semana}${dia}n`}
																type='button'
																onClick={
																	addVacancy
																}
																className={`bg-gray-500 p-1 w-[186px]   hover:bg-gray-600 rounded-sm hover:opacity-100 text-white text-xs font-bold ${
																	turnWhite
																		? 'hidden'
																		: null
																}`}>
																ADICIONAR
																VACÂNCIA
															</button>
														</span>
													</div>
												</td>
											))}
										</tr>
									))}
								</tbody>
							</table>
							<div className='flex gap-x-2 mt-2'>
								<button
									type='button'
									id='noite'
									onClick={createLineButton}
									className={`bg-slate-400 rounded-sm font-bold  text-white text-md px-2 hover:bg-slate-500 transition-all duration-200 ${
										turnWhite ? 'bg-white' : null
									} `}>
									{' '}
									Adicionar
								</button>
								<button
									type='button'
									id='noite'
									onClick={removeLineButton}
									className={`bg-slate-400 rounded-sm font-bold  text-white text-md px-2 hover:bg-slate-500 transition-all duration-200 ${
										turnWhite ? 'bg-white' : null
									} `}>
									{' '}
									Remover
								</button>
							</div>
						</div>
						<div className='flex justify-between w-[99%]'>
							<div>
								<button
									type='button'
									id='noite'
									title='CLIQUE PARA SALVAR A ESCALA ENQUANTO ESTIVER LOGADO'
									onClick={saveTable}
									className={`text-white bg-[#125e94] hover:bg-[#004A80] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2focus:outline-none ${
										turnWhite ? 'bg-white' : null
									}`}>
									{' '}
									Salvar Escala
								</button>
								<button
									onClick={() => {
										if (
											window.confirm(
												'CLIQUE EM "OK" CASO QUEIRA LIMPAR COMPLETAMENTE TODOS OS PROFISSIONAIS ADCIONADOS NESTA ESCALA '
											)
										) {
											cleanTable();
										}
									}}
									title='CLIQUE PARA LIMPAR OS DADOS DOS PROFISSIONAIS QUE ESTÃO NA TABELA'
									className={`text-white bg-[#125e94] hover:bg-[#004A80] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2focus:outline-none ${
										turnWhite ? 'bg-white' : null
									}`}>
									Limpar Tabela
								</button>
							</div>
							{/* {console.log(
								JSON.parse(
									Cookies.get('user')
								)?.unidade?.includes('subhue')
							)} */}
							<div>
								{JSON.parse(
									Cookies.get('user')
								)?.unidade?.includes('subhue') ? null : (
									<button
										type='button'
										id='noite'
										title='SUBMETE A ESCALA'
										disabled={
											localStorage.getItem('cabeçalho')
												? false
												: true
										}
										onMouseEnter={() => logDoProduto()}
										onClick={() => {
											if (
												window.confirm(
													'A ESCALA SERÁ ENVIADA PARA O BANCO DE DADOS, DESEJA CONTINUAR?'
												)
											) {
												submitTable();
											}
										}}
										className={`text-gray-300 bg-gray-100 enabled:bg-[#125e94]  enabled:hover:bg-[#004A80] enabled:text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  mb-2 focus:outline-none ${
											turnWhite
												? 'enabled:bg-white'
												: null
										}`}>
										{' '}
										Enviar Escala
									</button>
								)}
							</div>
							<div className='space-x-2'>
								{localStorage.getItem('cabeçalho') ? (
									<button
										title='EXPORTAR A TABELA COMO EXCEL'
										className={`px-2  bg-gray-300 enabled:bg-gray-400 enabled:hover:bg-gray-500 text-[10px] font-bold h-8 mb-3  text-white rounded-md ${
											turnWhite
												? 'enabled:bg-white'
												: null
										}`}>
										<ExcelExport
											data={excelFormat}
											fileName={
												JSON.parse(
													localStorage.getItem(
														'cabeçalho'
													)
												)[0].unidades +
												'-' +
												JSON.parse(
													localStorage.getItem(
														'cabeçalho'
													)
												)[0].setores +
												'-' +
												JSON.parse(
													localStorage.getItem(
														'cabeçalho'
													)
												)[0].regimes +
												'-' +
												JSON.parse(
													localStorage.getItem(
														'cabeçalho'
													)
												)[0].turnos
											}
										/>
									</button>
								) : null}
								{localStorage.getItem('cabeçalho') ? (
									<button
										title='EXPORTAR A TABELA COMO PDF'
										className={`px-2  bg-gray-300 enabled:bg-gray-400 enabled:hover:bg-gray-500 text-[10px] font-bold h-8 mb-3  text-white rounded-md ${
											turnWhite
												? 'enabled:bg-white'
												: null
										}`}
										onClick={() => {
											saveTable();
											setTurnWhite(true);

											setTimeout(() => {
												exportTableAsImageOrPDF(
													'pdf',
													JSON.parse(
														localStorage.getItem(
															'cabeçalho'
														)
													)[0].unidades +
														'-' +
														JSON.parse(
															localStorage.getItem(
																'cabeçalho'
															)
														)[0].setores +
														'-' +
														JSON.parse(
															localStorage.getItem(
																'cabeçalho'
															)
														)[0].regimes +
														'-' +
														JSON.parse(
															localStorage.getItem(
																'cabeçalho'
															)
														)[0].turnos
												);
												setTurnWhite(false);
											}, 100);
										}}>
										Exportar como PDF
									</button>
								) : null}
							</div>
							{/* <button onClick={generatePDF}>Generate PDF</button> */}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default VanillaTableScale;
