import React, { useState, useEffect } from 'react';
import Card3 from '../images/card 3.png';
import { useNavigate } from 'react-router-dom';
import Card1 from '../images/card 1.png';
/* import Card2 from '../images/card 2.png'; */

const Carousel = () => {
	const images = [Card1, Card3];
	const [currentIndex, setCurrentIndex] = useState(0);

	const navigate = useNavigate();

	const handleClick = (card) => {
		if (card === Card1) {
			navigate('/agenda-eventos');
		} else if (card === Card3) {
			navigate(`/${'Boletins Informativos'}`);
		} else {
			console.log('Cartão desconhecido');
		}
	};

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentIndex((prevIndex) =>
				prevIndex === images.length - 1 ? 0 : prevIndex + 1
			);
		}, 6000);

		return () => clearInterval(interval);
	}, [currentIndex, images.length]);

	const goToSlide = (index) => {
		setCurrentIndex(index);
	};

	return (
		<div className='relative '>
			<div className='overflow-hidden mx-auto flex justify-center  xl:h-[250px] sm:rounded-md my-3 max-w-[1136px]  w-[95%] h-[10%] '>
				<button>
					<div
						className='flex transition-transform  duration-[1000ms] ease-in-out'
						style={{
							transform: `translateX(-${currentIndex * 100}%)`,
						}}>
						{images.map((image, index) => (
							<img
								className='rounded-sm sm:rounded-md'
								key={index}
								onClick={() => handleClick(image)}
								src={image}
								alt={`Slide ${index}`}
							/>
						))}
					</div>
				</button>
				<div className='absolute justify-center transform-translate-x-1/2 flex space-x-2 md:space-x-2 bottom-3'>
					{images.map((_, index) => (
						<button
							key={index}
							className={`w-1 h-1  md:w-2 md:h-2 rounded-full mx-1 mt-[80px] md:mt-[13rem] xl:mt-[230px] bg-white ${
								currentIndex === index
									? 'bg-gray-400'
									: 'bg-white'
							} hover:bg-gray-400`}
							onClick={() => goToSlide(index)}></button>
					))}
				</div>
			</div>
		</div>
	);
};

export default Carousel;
