import React from 'react';
import NavBar from '../../components/NavBar';

function Atendimento() {
	return (
		<div className=''>
			<NavBar />
			<div className='text-center content-center mt-10 w-screen'>
				<h1
					className='w-full text-blue-900 font-semibold  flex items-center justify-center  text-3xl md:text-5xl
					mt-6 w-first-letter:text-xl font:roboto
				'>
					Onde ser Atendido
				</h1>
				<hr className='w-28 h-2 mx-auto my-3 bg-blue-900 dark:bg-gray'></hr>
			</div>
		</div>
	);
}

export default Atendimento;
