import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const exportTableAsImageOrPDF = (format, nome_arquivo) => {
	const table = document.getElementById('my-table-id');

	html2canvas(table, { scale: 2 }).then((canvas) => {
		const imgData = canvas.toDataURL('image/jpeg', 0.8);

		const pdf = new jsPDF({
			orientation: 'portrait',
			unit: 'pt',
			format: 'a4',
		});

		const pageWidth = pdf.internal.pageSize.getWidth();
		const pageHeight = pdf.internal.pageSize.getHeight();

		const imgWidth = canvas.width;
		const imgHeight = canvas.height;

		const scale = pageWidth / imgWidth;
		const scaledHeight = imgHeight * scale;

		let position = 0;

		while (position < scaledHeight) {
			const remainingHeight = scaledHeight - position;
			const sectionHeight = Math.min(remainingHeight, pageHeight);

			const canvasSection = document.createElement('canvas');
			canvasSection.width = imgWidth;
			canvasSection.height = sectionHeight / scale;
			const ctx = canvasSection.getContext('2d');

			ctx.drawImage(
				canvas,
				0,
				position / scale,
				imgWidth,
				sectionHeight / scale,
				0,
				0,
				imgWidth,
				sectionHeight / scale
			);

			const imgDataSection = canvasSection.toDataURL('image/jpeg', 0.8);
			pdf.addImage(
				imgDataSection,
				'JPEG',
				0,
				0,
				pageWidth,
				sectionHeight
			);

			position += pageHeight;

			if (position < scaledHeight) {
				pdf.addPage();
			}
		}

		if (format === 'pdf') {
			pdf.save(`${nome_arquivo}.pdf`);
		} else if (format === 'jpg') {
			const link = document.createElement('a');
			link.href = imgData;
			link.download = `${nome_arquivo}.jpg`;
			link.click();
		}
	});
};

export { exportTableAsImageOrPDF };
