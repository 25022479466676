import React, { useState } from 'react';
import { requestCreateScale } from '../services/requests';
import * as XLSX from 'xlsx';

const Contato = () => {
	const [output, setOutput] = useState('');
	const [buttonAppear, setButtonAppear] = useState(false);
	const [btnConfirm, setBtnConfirm] = useState(false);

	const sendDataToDatabase = async (data) => {
		data.forEach(async (item) => {
			await requestCreateScale([item]);
		});
		setBtnConfirm(true);
	};

	const formatData = (data) => {
		let formattedData = {
			Unidade: '',
			Setor: '',
			turnos: [],
		};

		let currentTurno = null;

		data.forEach((row, index) => {
			if (row.includes('Unidade:')) {
				formattedData.Unidade =
					row[row.indexOf('Unidade:') + 1].toLowerCase() || '';
			}
			if (row.includes('Setor:')) {
				formattedData.Setor =
					row[row.indexOf('Setor:') + 1].toLowerCase() || '';
			}

			const letters = ['Diurno', 'Noturno', 'Manhã', 'Tarde'];
			/* console.log(row[1]); */
			if (row[1]) {
				if (
					letters.some((letter) => row[1].toString().includes(letter))
				) {
					if (currentTurno) {
						formattedData.turnos.push(currentTurno);
					}

					currentTurno = {
						descricao: row[1],
						semanas: [], // Array para as semanas
					};
				}
			}

			if (currentTurno) {
				if (
					currentTurno.semanas.length === 0 ||
					currentTurno.semanas[currentTurno.semanas.length - 1].dados
						.length >= 4
				) {
					currentTurno.semanas.push({ dados: [] });
				}

				let currentSemana =
					currentTurno.semanas[currentTurno.semanas.length - 1];

				if (
					['Nome:', 'Telefone:', 'Vinculo:', 'CH total:'].includes(
						row[0]
					)
				) {
					/* console.log(row.slice(1)); */
					currentSemana.dados.push({
						tipo: row[0].replace(':', ''),
						valores: row.slice(1),
					});
				}
			}
		});

		if (currentTurno) {
			formattedData.turnos.push(currentTurno);
		}

		return formattedData;
	};

	const handleFile = (event) => {
		const file = event.target.files[0];
		if (!file) {
			alert('Por favor, selecione um arquivo Excel primeiro.');
			return;
		}

		const reader = new FileReader();
		reader.onload = (e) => {
			const data = new Uint8Array(e.target.result);
			const workbook = XLSX.read(data, { type: 'array' });

			let finalOutput = [];

			workbook.SheetNames.forEach((sheetName) => {
				const worksheet = workbook.Sheets[sheetName];
				const jsonData = XLSX.utils.sheet_to_json(worksheet, {
					header: 1,
				});
				const formattedData = formatData(jsonData);
				finalOutput.push(formattedData);
			});
			/* console.log(finalOutput); */
			let result = [];
			finalOutput.forEach((item) => {
				const funko = (turno) => {
					let current_turn;
					if (turno === 'noite') {
						current_turn = 1;
					} else {
						current_turn = 0;
					}
					const weeks = {};
					const week_day = [
						'segunda',
						'terça',
						'quarta',
						'quinta',
						'sexta',
						'sábado',
						'domingo',
					];
					item.turnos[current_turn].semanas.forEach(
						(semana, semanaIndex) => {
							if (semana.dados[0]) {
								semana.dados[0].valores.forEach(
									(dado, index) => {
										let pro_data = {
											[week_day[index]]: {
												nome:
													semana.dados[0].valores[
														index
													] === undefined
														? '**'
														: semana.dados[0].valores[
																index
														  ].toLowerCase(),
												telefone:
													semana.dados[1].valores[
														index
													] === undefined
														? '**'
														: semana.dados[1]
																.valores[index],
												vinculo:
													semana.dados[2].valores[
														index
													] === undefined
														? '**'
														: semana.dados[2].valores[
																index
														  ].toLowerCase(),
												especialidade: 'médico',
												ch_total:
													semana.dados[3].valores[
														index
													] === undefined
														? '**'
														: semana.dados[3].valores[
																index
														  ]
																.toString()
																.substring(
																	0,
																	2
																),
											},
										};
										if (
											!weeks[`semana${semanaIndex + 1}`]
										) {
											weeks[`semana${semanaIndex + 1}`] =
												{};
										}
										weeks[`semana${semanaIndex + 1}`] = {
											...weeks[
												`semana${semanaIndex + 1}`
											],
											...pro_data,
										};
									}
								);
							}
						}
					);
					return weeks;
				};
				console.log(item.turnos);
				result.push({
					cabecalho: {
						unidades: item.Unidade.toLowerCase(),
						setores: item.Setor.toLowerCase(),
						regimes: item.turnos[0].descricao
							.split(' - ')[0]
							.toLowerCase(),
						turnos: `${item.turnos[0].descricao
							.split(' - ')[1]
							.toLowerCase()} / ${item.turnos[1].descricao
							.split(' - ')[1]
							.toLowerCase()}`,
						user_id: 3,
					},
					[item.turnos[0].descricao.split(' - ')[1].toLowerCase()]:
						funko('dia'),
					[item.turnos[1].descricao.split(' - ')[1].toLowerCase()]:
						funko('noite'),
				});
			});
			setButtonAppear(true);
			setOutput(JSON.stringify(result, null, 2));
		};

		reader.readAsArrayBuffer(file);
	};

	return (
		<div>
			<h2 className='my-5'>
				Carregar Arquivo Excel e Exibir Dados Formatados
			</h2>

			<div className='mx-5 flex'>
				<input
					type='file'
					id='excelFile'
					accept='.xlsx'
					onClick={() => setBtnConfirm(false)}
					onChange={handleFile}
				/>

				<button
					type='button'
					hidden={!buttonAppear}
					onClick={() => sendDataToDatabase(JSON.parse(output))}
					className={`mx-2 p-2 ${
						btnConfirm
							? 'scale-105 bg-green-500 text-white hover:bg-green-600 rounded '
							: 'bg-slate-200 font-semibold text-sm hover:bg-slate-500 rounded'
					} `}>
					{' '}
					{btnConfirm
						? 'DADOS ENVIADOS COM SUCESSO'
						: 'ENVIAR PARA O BANCO DE DADOS'}{' '}
				</button>
			</div>
			<h3 className='my-5'>Resultado:</h3>
			<pre id='output'>{output}</pre>
		</div>
	);
};

export default Contato;
