import React, { useEffect, useState } from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import { requestBoletins } from '../../services/requests';

function Boletins() {
	const [boletins, setBoletins] = useState([]);
	const [categorias, setCategorias] = useState([]);
	const meses = [
		'janeiro',
		'fevereiro',
		'março',
		'abril',
		'maio',
		'junho',
		'julho',
		'agosto',
		'setembro',
		'outubro',
		'novembro',
		'dezembro',
	];

	/* 	const categorias = [
		'categoria1',
		'categoria2',
		'categoria3',
		'categoria4',
		'categoria5',
	];
 */
	const [filters, setFilters] = useState({
		ano: '',
		mes: '',
		categoria: '',
	});

	const filteredBoletins = boletins.filter((boletim) => {
		const [year, month] = boletim.data.split('-');
		return (
			(!filters.ano || filters.ano === year) &&
			(!filters.mes || filters.mes === meses[parseInt(month, 10) - 1]) &&
			(!filters.categoria || filters.categoria === boletim.categoria)
		);
	});

	const handleFilterChange = (key, value) => {
		setFilters((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	const anos = () => {
		let ano = new Date().getFullYear();
		let anos = [];
		for (let i = 0; i < 50; i++) {
			anos.push(ano - i);
		}
		return anos;
	};

	const handleRequest = async () => {
		const result = await requestBoletins();
		if (!result.error) {
			result.sort((a, b) => {
				return new Date(b.data) - new Date(a.data);
			});
			let catFilter = [];
			result.forEach((categoria) => {
				if (!catFilter.includes(categoria.categoria)) {
					catFilter.push(categoria.categoria);
				}
			});
			setCategorias(catFilter);
			setBoletins(result);
			/* console.log(result); */
		} else {
			console.log(result.error);
		}
	};

	useEffect(() => {
		handleRequest();
	}, []);

	return (
		<div className='flex flex-col mx-auto min-h-screen'>
			<NavBar />
			<div className='font-inter flex flex-col flex-grow'>
				<div className='sm:top-[80px] mt-[80px] pb-4 bg-[#f0f4f8]  w-full'>
					<div className='w-[90%] sm:w-[95%]  mx-auto items-center max-w-[1136px]'>
						<div className='px-[2px] py-[18px]'>
							<p className='text-[32px]  font-medium w-full text-center text-[#004A80]'>
								Boletins Informativos
							</p>
							<div className='flex justify-between flex-wrap sm:flex text-[22px] font-semibold text-[#004A80] mt-[32px]'>
								<div className='flex gap-[12px] h-[34px] justify-between w-full sm:w-min  sm:justify-start my-2  lg:my-0'>
									<p className='flex items-center text-[15px] sm:text-[22px]  '>
										ANO
									</p>
									<select
										className='bg-[#E3E3E3] text-[#757575] rounded-sm text-[15px] px-2 w-[235px] font-normal'
										value={filters.ano}
										onChange={(e) =>
											handleFilterChange(
												'ano',
												e.target.value
											)
										}>
										<option value=''>
											Ano da Publicação
										</option>
										{anos().map((ano, index) => (
											<option
												key={index}
												value={ano}>
												{ano}
											</option>
										))}
									</select>
								</div>
								<div className='flex gap-[12px] h-[34px] justify-between w-full sm:w-min  sm:justify-start my-2 lg:my-0'>
									<p className='flex items-center text-[15px] sm:text-[22px]'>
										MÊS
									</p>
									<select
										className='bg-[#E3E3E3] text-[#757575] rounded-sm text-[15px] px-2 w-[235px] font-normal'
										value={filters.mes}
										onChange={(e) =>
											handleFilterChange(
												'mes',
												e.target.value
											)
										}>
										<option value=''>
											Mês de Publicação
										</option>
										{meses.map((mes, index) => (
											<option
												key={index}
												value={mes}>
												{mes}
											</option>
										))}
									</select>
								</div>
								<div className='flex gap-[12px] h-[34px] justify-between w-full sm:w-min  sm:justify-start my-2 lg:my-0'>
									<p className='flex items-center text-[15px] sm:text-[22px]'>
										CATEGORIA
									</p>
									<select
										className='bg-[#E3E3E3] text-[#757575] rounded-sm text-[15px] px-2 w-[235px] font-normal'
										value={filters.categoria}
										onChange={(e) =>
											handleFilterChange(
												'categoria',
												e.target.value
											)
										}>
										<option value=''>
											Categoria do Documento
										</option>
										{categorias.map((categoria, index) => (
											<option
												key={index}
												value={categoria}>
												{categoria}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='mx-auto w-[90%] sm:w-[95%] items-center max-w-[1136px]'>
					<div className='h-[1px] mt-[28px] mb-8 bg-[#E3E3E3]'></div>
					{filteredBoletins.length > 0 ? (
						<div>
							{filteredBoletins.map((boletim) => (
								<div
									key={boletim.id}
									className='my-[28px]'>
									<div className='text-[#00C0F3] text-[14px]'>
										{boletim.categoria === null
											? 'categoria indefinida'
											: boletim.categoria}
									</div>
									<a
										href={boletim.arquivo}
										target='_blank'
										rel='noopener noreferrer'
										className='text-[#004A80] font-medium hover:text-[#3c7faf] transition-all ease-in-out text-[20px]'>
										{boletim.titulo}
									</a>
									<div className='text-[#757575] font-light text-[14px]'>
										{new Date(
											boletim.data
										).toLocaleDateString('pt-BR', {
											month: 'long',
											year: 'numeric',
										})}
									</div>
								</div>
							))}
						</div>
					) : (
						<div>
							{' '}
							<p className='w-full text-center mt-12 text-[#757575] font-bold text-sm sm:text-lg'>
								NENHUM DOCUMENTO ENCONTRADO
							</p>
							<p className='w-full text-center  mt-2 mb-10 sm:mb-0 text-[#757575]  text-xs md:text-sm '>
								modifique ou remova os filtros!
							</p>
						</div>
					)}
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Boletins;
