import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import Footer from '../../src/components/Footer';
import { unidades } from './servicos/descricoes e contatos/escala';
import NavBar from '../components/NavBar';
import { requestNoteLogin } from '../services/requests';

function NotesLogin() {
	const [input, setInput] = useState('');
	const [, /* selectInput */ setSelectInput] = useState('');
	const [password, setPassword] = useState('');
	const [filteredUnidades, setFilteredUnidades] = useState([]);
	const [showAlert, setShowAlert] = useState(false);
	const [showCPFAlert, setCPFAlert] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showList, setShowList] = useState(false);
	const navigate = useNavigate();
	const inputRef = useRef(null);
	const listRef = useRef(null);

	const removeAccentsAndSpecialChars = (str) => {
		return str
			.normalize('NFD') // Normaliza para decompor caracteres acentuados
			.replace(/[\u0300-\u036f]/g, '') // Remove os acentos
			.replace(/[^a-zA-Z0-9\s]/g, ''); // Remove caracteres especiais
	};

	const handleInputChange = (e) => {
		const value = e.target.value;
		const normalizedInput = removeAccentsAndSpecialChars(
			value.toLowerCase()
		);
		setInput(value);
		setFilteredUnidades(
			unidades.filter((unidade) =>
				removeAccentsAndSpecialChars(unidade.toLowerCase()).includes(
					normalizedInput
				)
			)
		);
		setShowList(true);
	};

	const handleOptionClick = (unidade) => {
		setInput(unidade);
		setSelectInput(unidade);
		setShowList(false);
	};

	const handleLogin = async (event) => {
		event.preventDefault();
		const requestResult = await requestNoteLogin(input, password);
		if (!requestResult.error) {
			localStorage.setItem('unidade', input);
		}

		setShowAlert(false);
		setCPFAlert(false);

		if (!requestResult.error) {
			if (requestResult.cargo === 'desenvolvedor') {
				navigate('/cadastro');
			} else {
				navigate(
					`/notificacoes/CERC (Coordenação de Emergência Regional Centro)`
				);
			}
		} else if (requestResult.error.includes('404')) {
			setCPFAlert(true);
		} else if (requestResult.error.includes('400')) {
			setShowAlert(true);
		}
	};

	const handleClickOutside = (e) => {
		if (
			listRef.current &&
			!listRef.current.contains(e.target) &&
			!inputRef.current.contains(e.target)
		) {
			setShowList(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className='flex flex-col min-h-screen'>
			<NavBar />
			<div className='text-center flex-grow mt-10'>
				<section>
					<div className='flex flex-col items-center mt-20 mx-auto md:h-full lg:py-0'>
						<div className='md:w-full bg-white rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0'>
							<div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
								<h1 className='text-2xl font-bold leading-tight tracking-tight text-slate-700  md:text-3xl'>
									Entre Para Criar Uma Notificação
								</h1>
								<form
									className='space-y-4 md:space-y-6'
									onSubmit={handleLogin}>
									<div className='relative'>
										<input
											ref={inputRef}
											type='text'
											placeholder='Nome da Unidade'
											className='bg-gray-50 border border-gray-300 text-gray-700 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5'
											value={input}
											onChange={handleInputChange}
											required
										/>
										{showList &&
											filteredUnidades.length > 0 && (
												<ul
													ref={listRef}
													className='absolute bg-white border border-gray-300 w-full mt-1 z-10 max-h-[300px]  md:max-h-[500px]  overflow-y-auto'>
													{filteredUnidades.map(
														(unidade, index) => (
															<li
																key={index}
																value={unidade}
																className='p-2 cursor-pointer  hover:hover:bg-blue-500 hover:text-white'
																onClick={() =>
																	handleOptionClick(
																		unidade
																	)
																}>
																{unidade}
															</li>
														)
													)}
												</ul>
											)}
									</div>
									<div className='relative'>
										<input
											value={password}
											onChange={({ target: { value } }) =>
												setPassword(value)
											}
											type={
												showPassword
													? 'text'
													: 'password'
											}
											name='password'
											id='password'
											placeholder='Senha'
											autoComplete='true'
											className='bg-gray-50 border border-gray-300 text-gray-700 sm:text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5'
											required
										/>
										<button
											type='button'
											onClick={() =>
												setShowPassword(!showPassword)
											}
											className='absolute inset-y-3 right-0 pr-3 mb-[105px] flex items-center text-gray-500'>
											{showPassword ? (
												<FaEyeSlash />
											) : (
												<FaEye />
											)}
										</button>
										<div className='flex items-center mt-5 justify-between'>
											<div className='flex items-start'>
												<div className='flex items-center h-5'>
													<input
														id='remember'
														type='checkbox'
														className='w-4 h-4 border border-gray-300 bg-gray-50 focus:ring-3 focus:ring-primary-300'
													/>
												</div>
												<div className='ml-3 text-sm'>
													<label htmlFor='remember'>
														Lembrar-me
													</label>
												</div>
											</div>
										</div>
										<button
											type='submit'
											onClick={handleLogin}
											className='text-white bg-[#004A80] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm w-full px-5 py-2.5 me-2 mb-2 mt-5 focus:outline-none'>
											ENTRAR
										</button>
									</div>
									{showAlert && (
										<p className='text-red-500 font-roboto font-semibold'>
											Usuário ou Senha Estão Fora dos
											Padrões!
										</p>
									)}
									{showCPFAlert && (
										<p className='text-red-500 font-roboto font-semibold'>
											Usuário ou Senha Incorretos!
										</p>
									)}
								</form>
							</div>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</div>
	);
}

export default NotesLogin;
