import React, { useEffect, useState } from 'react';
import NavBar from '../../../components/NavBar';
import Footer from '../../../components/Footer';
import VanillaTableScale from '../../../components/VanillaTableScale';
import { requestAllScale } from '../../../services/requests';
import { unidades, setores } from '../../servicos/descricoes e contatos/escala';
import Cookies from 'js-cookie';

function AdminVisualize() {
	const [list, setList] = useState({});
	const [filtered, setFiltered] = useState({});
	const [scaleList, setScaleList] = useState([]);
	const [reloadTable, setReloadTable] = useState(false);
	const [unitList, setUnitList] = useState();
	const [windowAlert, setWindowAlert] = useState(true);
	const [alert, setAlert] = useState(false);
	const [inputs, setInputs] = useState({});
	const [cabeçalho] = useState({
		unidades: '',
		setores: '',
		regimes: '',
		turnos: '',
	});
	const normalizeName = (name) => name.toLowerCase();

	const meses = [
		'janeiro',
		'fevereiro',
		'março',
		'abril',
		'maio',
		'junho',
		'julho',
		'agosto',
		'setembro',
		'outubro',
		'novembro',
		'dezembro',
	];

	const findUnidadeCompleta = (unidadeSiglaOuNome) => {
		return unidades.find((unidade) => {
			const sigla = unidade.split(' ')[0];
			const nomeCompleto = unidade;
			return (
				normalizeName(unidadeSiglaOuNome) === normalizeName(sigla) ||
				normalizeName(unidadeSiglaOuNome) ===
					normalizeName(nomeCompleto)
			);
		});
	};

	const handleChange = (value, id) => {
		setInputs({ ...inputs, [id]: value.toLowerCase() });
	};

	function filtrarDados(dados, nomeParcialUnidade, mes, setor) {
		let aux = Object.keys(dados);
		if (nomeParcialUnidade) {
			aux = Object.keys(dados).filter((unidade) =>
				unidade.toLowerCase().includes(nomeParcialUnidade.toLowerCase())
			);
		}
		return aux.reduce((resultado, unidade) => {
			const profissionaisFiltrados = dados[unidade].filter(
				(profissional) => {
					const filtroMes = mes ? profissional.mes === mes : true;
					const filtroSetor = setor
						? profissional.setores === setor
						: true;
					return filtroMes && filtroSetor;
				}
			);
			if (profissionaisFiltrados.length > 0) {
				resultado[unidade] = profissionaisFiltrados;
			}
			return resultado;
		}, {});
	}

	const scaleData = async () => {
		const { data } = await requestAllScale({
			token: JSON.parse(Cookies.get('user')).token,
		});
		let agrupadoPorUnidade = data.reduce((result, item) => {
			let { unidades } = item;
			let unidadeCompleta = findUnidadeCompleta(unidades) || unidades;
			function extrairConteudoEntreParenteses(str) {
				const resultado = str.match(/\(([^)]+)\)/);
				return resultado ? resultado[1] : null;
			}
			if (extrairConteudoEntreParenteses(unidadeCompleta)) {
				unidadeCompleta =
					extrairConteudoEntreParenteses(
						unidadeCompleta
					).toLowerCase();
			}
			if (!result[unidadeCompleta]) {
				result[unidadeCompleta] = [];
			}
			result[unidadeCompleta].push(item);
			return result;
		}, {});
		setList(agrupadoPorUnidade);
	};

	const filterList = () => {
		setScaleList([]);
		let dados = filtrarDados(
			list,
			inputs.unidade,
			inputs.mes,
			inputs.setores
		);

		const agrupados = {};
		Object.keys(dados).forEach((unidade) => {
			agrupados[unidade] = {};

			dados[unidade].forEach((profissional) => {
				const { setores, mes, regimes } = profissional;
				const chaveAgrupamento = `${regimes}-${setores.replace(
					/\s+/g,
					'/'
				)}-${mes}`;
				if (!agrupados[unidade][chaveAgrupamento]) {
					agrupados[unidade][chaveAgrupamento] = [];
				}
				agrupados[unidade][chaveAgrupamento].push(profissional);
			});
		});
		if (Object.keys(dados).length === 0) {
			setAlert(true);
		} else {
			setAlert(false);
		}

		setFiltered(agrupados);
	};

	const visualizeScale = async (data) => {
		const filteredData = data;
		let cabecalho = [
			{
				unidades: filteredData[0].unidades,
				setores: filteredData[0].setores,
				regimes: filteredData[0].regimes,
				turnos:
					filteredData[0].turnos === 'manhã' || 'noite'
						? 'manhã / noite'
						: 'diurno / noturno',
			},
		];
		let profissionais = {};
		let profissionais2 = {};

		filteredData.forEach((pro) => {
			if (pro.turnos === 'manhã' || pro.turnos === 'diurno') {
				if (!profissionais[`semana${pro.semana - 1}`]) {
					profissionais[`semana${pro.semana - 1}`] = {
						segunda: {},
						terça: {},
						quarta: {},
						quinta: {},
						sexta: {},
						sábado: {},
						domingo: {},
					};
					profissionais[`semana${pro.semana - 1}`][pro.dia] = {
						nome: pro.nome,
						vinculo: pro.vinculo,
						celular: pro.celular,
						especialidade: pro.especialidade,
						ch_total: pro.ch_total,
					};
				} else {
					profissionais[`semana${pro.semana - 1}`][pro.dia] = {
						nome: pro.nome,
						vinculo: pro.vinculo,
						celular: pro.celular,
						especialidade: pro.especialidade,
						ch_total: pro.ch_total,
					};
				}
			} else {
				if (!profissionais2[`semana${pro.semana - 1}`]) {
					profissionais2[`semana${pro.semana - 1}`] = {
						segunda: {},
						terça: {},
						quarta: {},
						quinta: {},
						sexta: {},
						sábado: {},
						domingo: {},
					};
					profissionais2[`semana${pro.semana - 1}`][pro.dia] = {
						nome: pro.nome,
						vinculo: pro.vinculo,
						celular: pro.celular,
						especialidade: pro.especialidade,
						ch_total: pro.ch_total,
					};
				} else {
					profissionais2[`semana${pro.semana - 1}`][pro.dia] = {
						nome: pro.nome,
						vinculo: pro.vinculo,
						celular: pro.celular,
						especialidade: pro.especialidade,
						ch_total: pro.ch_total,
					};
				}
			}
		});
		localStorage.setItem('cabeçalho', JSON.stringify(cabecalho));
		localStorage.setItem('profissionais', JSON.stringify(profissionais));
		localStorage.setItem('profissionais2', JSON.stringify(profissionais2));
		localStorage.setItem('lastEdit', filteredData[0].modificacao);
		setReloadTable(true);

		setTimeout(() => {
			setReloadTable(false);
		}, [2000]);
	};

	const showScaleList = (unidade) => {
		/* console.log(Object.keys(filtered[unidade])); */
		setUnitList(filtered[unidade]);
		setScaleList(Object.keys(filtered[unidade]));
	};

	useEffect(() => {
		/* localStorage.removeItem('profissionais');
		localStorage.removeItem('profissionais2');
		localStorage.removeItem('cabeçalho'); */

		if (Cookies.get('user')) {
			const user = JSON.parse(Cookies.get('user'));
			if (
				user?.unidade?.includes('subhue') &&
				(user?.cargo?.includes('gestor') ||
					user?.cargo?.includes('desenvolvedor'))
			) {
				scaleData();
				setWindowAlert(false);
			}
		}
	}, []);

	return (
		<div className='flex flex-col min-h-screen'>
			<NavBar />
			{windowAlert ? (
				<section className='bg-[#004A80] rounded-md w-[90%] sm:w-[70%] md:w-[60%] lg:w-[50%] mt-20 mx-auto my-auto'>
					<div className='py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
						<div className='mx-auto max-w-screen-sm text-center'>
							<h1 className='mb-4 text-white text-5xl sm:text-6xl lg:text-7xl tracking-tight font-extrabold text-primary-600'>
								401
							</h1>
							<p className='mb-10 text-2xl sm:text-3xl md:text-4xl tracking-tight font-bold text-slate-100'>
								Acesso Negado
							</p>
							<p className='mb-7 text-sm sm:text-base md:text-lg font-light text-gray-300'>
								Você não possui permissão para acessar este
								campo. Entre em contato com o administrador(a)
								da sua unidade para solicitação de acesso.
							</p>
							<a
								href='/login'
								className='inline-flex hover:scale-105 transition-transform text-gray-300 bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4'>
								Volte para a Tela de Login
							</a>
						</div>
					</div>
				</section>
			) : (
				<div>
					<div className='text-center  flex-grow  mx-auto w-[90%] max-w-[1136px] mt-[92px] md:mt-[92px]  my-10'>
						{/* FILTROS */}
						<div className='flex justify-center my-8'>
							<div className=' flex gap-[10px]'>
								<div className='font-medium text-gray-600 align-bottom'>
									UNIDADES:
									<input
										onChange={({ target: { value, id } }) =>
											handleChange(value, id)
										}
										id='unidade'
										type='text'
										placeholder='ex: Maternidade Alexandre...'
										className='border-2 uppercase pr-2 mx-2 text-[12px] w-[200px] h-[25px] border-gray-100 rounded-sm'
									/>
								</div>
								<div className='font-medium text-gray-600'>
									MÊS:
									<select
										className='border-2 uppercase pr-2 mx-2 text-[12px] w-[200px] h-[25px] border-gray-100 rounded-sm'
										name='mes'
										id='mes'
										onChange={({ target: { value, id } }) =>
											handleChange(value, id)
										}>
										<option value=''>
											Selecione o Mês
										</option>
										{meses.map((mes, index) => (
											<option
												key={index}
												value={mes}>
												{mes.toUpperCase()}
											</option>
										))}
									</select>
								</div>
								<div className='font-medium text-gray-600'>
									SETOR:
									<select
										className='border-2 uppercase pr-2 mx-2 text-[12px] w-[200px] h-[25px] border-gray-100 rounded-sm'
										name='setores'
										id='setores'
										onChange={({ target: { value, id } }) =>
											handleChange(value, id)
										}>
										<option value=''>
											Selecione o Setor
										</option>
										{setores.map((setor, index) => (
											<option
												key={index}
												value={setor}>
												{setor.toUpperCase()}
											</option>
										))}
									</select>
								</div>
							</div>
							<div>
								<button
									className='bg-[#3c65bd] mx-4 p-1 px-2 rounded-md text-white font-medium text-[13px] hover:bg-[#004A80]'
									onClick={filterList}>
									PESQUISAR
								</button>
							</div>
						</div>
						{alert ? (
							<div className='text-gray-300 py-4 '>
								Nenhuma escala foi encontrada com este filtro
							</div>
						) : (
							<div className='mt-10 p-8 bg-gray-50 rounded-lg shadow-lg'>
								{/* CARDS DE UNIDADES */}
								{Object.keys(
									Object.keys(filtered).length > 0
										? filtered
										: list
								).length > 0 ? (
									<div className='flex flex-row justify-around gap-10'>
										{/* UNIDADES */}
										<div
											onMouseDown={filterList}
											className='flex flex-col w-[40%] bg-white p-6 rounded-lg shadow-md max-h-[700px] overflow-y-auto'>
											<p className='text-xl font-bold text-slate-800 mb-6'>
												Unidades:
											</p>
											<ul>
												{Object.keys(
													Object.keys(filtered)
														.length > 0
														? filtered
														: list
												).map((item, index) => (
													<button
														key={index}
														onClick={() =>
															showScaleList(item)
														}
														className='w-full text-left py-3 px-4 mb-3 rounded-md bg-slate-100 text-slate-700 hover:bg-slate-200 hover:text-slate-900 transform transition-transform hover:scale-105 shadow-sm'>
														<li className='font-semibold'>
															{item.toUpperCase()}
														</li>
													</button>
												))}
											</ul>
										</div>

										{/* ESCALAS */}
										<div
											hidden={scaleList.length === 0}
											className='flex flex-col w-[50%] bg-white p-6 rounded-lg shadow-md max-h-[700px] overflow-y-auto'>
											<p className='text-xl font-bold text-slate-800 mb-6'>
												Escalas:
											</p>
											<ul>
												{scaleList.map(
													(item, index) => (
														<button
															key={index}
															onClick={() =>
																visualizeScale(
																	unitList[
																		item
																	]
																)
															}
															className='w-full  text-left py-3 px-4 mb-3 rounded-md bg-slate-100  text-slate-700 hover:bg-slate-200 hover:text-slate-900 transform transition-transform hover:scale-105 shadow-sm'>
															<a
																href='#bloco-destino'
																className='font-semibold'>
																{item
																	.split('-')
																	.join(' ')
																	.split(
																		' '
																	)[1]
																	.split('/')
																	.join(' ')
																	.toUpperCase()}
															</a>
														</button>
													)
												)}
											</ul>
										</div>
									</div>
								) : (
									<div className='text-center text-gray-400 py-6 text-lg font-semibold'>
										Utilize um dos filtros para pesquisar as
										escalas.
									</div>
								)}
							</div>
						)}
					</div>
					{reloadTable ? (
						<div
							id='bloco-destino'
							className='flex  justify-center h-screen content-center w-full my-20'
							role='status'>
							<svg
								aria-hidden='true'
								className='w-8 h-8 text-gray-200 animate-spin  fill-blue-600'
								viewBox='0 0 100 101'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
									fill='currentColor'
								/>
								<path
									d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
									fill='currentFill'
								/>
							</svg>
							<span className='sr-only'>Loading...</span>
						</div>
					) : (
						<div>
							<VanillaTableScale inputsCabeçalho={cabeçalho} />
						</div>
					)}
				</div>
			)}
			<div>
				<Footer />
			</div>
		</div>
	);
}

export default AdminVisualize;
