import { React } from 'react';
import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import UserPNG from '../../images/NotifyHubImages/Usuario.png';
import UnidadePNG from '../../images/NotifyHubImages/Unidade.png';
import QuebradoPNG from '../../images/NotifyHubImages/Quebrado.png';
import CalendarioPNG from '../../images/NotifyHubImages/Calendario.png';
import PranchaPNG from '../../images/NotifyHubImages/Prancha.png';

function NotesHub() {
	const [isOpen, setIsOpen] = useState(false);
	const [protocolResult, setProtocolResult] = useState(null);

	const contentRef = useRef(null);
	const toggleAccordion = () => {
		switch (protocolResult) {
			case false: {
				setIsOpen(false);
				break;
			}
			case true: {
				setIsOpen(true);
				break;
			}
			case null: {
				setProtocolResult(false);
				setIsOpen(false);
				break;
			}
			default: {
				setIsOpen(false);
				break;
			}
		}
	};
	return (
		<div className='flex flex-col min-h-screen'>
			<NavBar />
			<div className='text-center flex-grow h-[1000px]  mx-auto font-inter w-full mt-[50px]'>
				<div className='w-full p-[42px] bg-[#F0F4F8] mt-8'>
					<div className=' rounded-md w-full h-full'>
						<p className='text-[#004A80]  text-[32px] font-semibold w-[87%] sm:w-full  text-center mx-auto mb-[28px] font-inter '>
							Notificação de Incidentes
						</p>
						<div className='flex justify-center gap-x-[12px] content-center'>
							<p className='text-[#004A80]  font-semibold   text-[22px] text-center  font-inter  '>
								Consultar protocolo
							</p>
							<input
								placeholder='Número do protocolo'
								className='px-[10px] text-[15px] w-[220px] border-[#E3E3E3] bg-transparent  border-[1px] rounded-[4px] text-[#757575] '></input>
							<button
								onClick={() => {
									toggleAccordion();
								}}
								className=' text-[#757575] rounded-md font-normal px-[6px] bg-[#E3E3E3] hover:bg-[#d3d3d3]'>
								Consultar
							</button>
						</div>
					</div>
				</div>
				<div className='bg-[#E3E3E3] mt-[24px] h-[1px] my-5 w-[95%] max-w-[1136px] mx-auto'></div>

				{protocolResult === false ? (
					<div className='absolute top-[400px] z-0 text-gray-400 font-bold text-xl sm:text-2xl w-[100%] mx-auto text-center'>
						<p>Nenhuma notificação em curso com este protocolo</p>
						<p>{`:(`}</p>
					</div>
				) : null}
				<div
					ref={contentRef}
					className={`relative overflow-hidden flex-grow transition-all duration-700 z-10 ease-in-out 
    rounded-sm border-[#E3E3E3] w-full mx-auto
    ${isOpen ? 'h-[700px]' : 'h-0 border-[0px]'}`}>
					{/* Conteúdo do card */}
					<div className='bg-[#F5F5F5F5] rounded-md border-[2px] p-[32px] border-[#E3E3E3] h-[651px]  max-w-[1136px] mx-auto'>
						<div className='flex items-end justify-between  gap-y-[38px] flex-wrap'>
							<div className='flex items-center w-[500px] h-[80px] gap-4'>
								<div className='w-10 h-10'>
									<img
										alt='icone de usuario'
										src={UserPNG}
										className='w-full h-full object-cover'
									/>
								</div>
								<div className='flex flex-col text-start w-[80%] '>
									<p className='text-[24px] text-[#555555] leading-7   line-clamp-2'>
										Ronaldo da Silva
									</p>
									<p className='text-[#757575] font-normal text-[clamp(12px, 2vw, 16px)] leading-none mt-1'>
										Nome do paciente
									</p>
								</div>
							</div>

							<div className='flex items-center w-[500px] h-[60px] gap-4'>
								<div className='w-10 h-10'>
									<img
										alt='icone de usuario'
										src={CalendarioPNG}
										className='w-full h-full object-cover'
									/>
								</div>
								<div className='flex flex-col text-start w-[80%] '>
									<p className='text-[24px] text-[#555555] leading-7   line-clamp-2'>
										03/ 12 / 1997
									</p>
									<p className='text-[#757575] font-normal text-[clamp(12px, 2vw, 16px)] leading-none mt-1'>
										Data de nascimento
									</p>
								</div>
							</div>
							<div className='flex items-center w-[500px] h-[60px] gap-4'>
								<div className=''>
									<img
										alt='icone de usuario'
										src={QuebradoPNG}
										className='w-full h-full object-cover'
									/>
								</div>
								<div className='flex flex-col text-start w-[80%] '>
									<p className='text-[24px] text-[#555555] leading-7   line-clamp-2'>
										Falha operacional de equipamentos ou
										dispositivos médicos
									</p>
									<p className='text-[#757575] font-normal text-[clamp(12px, 2vw, 16px)] leading-none mt-1'>
										Incidente
									</p>
								</div>
							</div>
							<div className='flex items-center w-[500px] h-[60px] gap-4'>
								<div className='w-10 h-10'>
									<img
										alt='icone de usuario'
										src={UnidadePNG}
										className='w-full h-full object-cover'
									/>
								</div>
								<div className='flex flex-col text-start w-[80%] '>
									<p className='text-[24px] text-[#555555] leading-7   line-clamp-2'>
										Hospital Municipal Raphael de Paula
										Souza
									</p>
									<p className='text-[#757575] font-normal text-[clamp(12px, 2vw, 16px)] leading-none mt-1'>
										Unidade de incidente
									</p>
								</div>
							</div>
							<div className='flex items-center w-[500px] h-[60px] gap-4'>
								<div className=''>
									<img
										alt='icone de usuario'
										src={PranchaPNG}
										className='w-full h-full object-cover'
									/>
								</div>
								<div className='flex flex-col text-start w-[80%] '>
									<p className='text-[24px] text-[#555555] leading-7   line-clamp-2'>
										5550123
									</p>
									<p className='text-[#757575] font-normal text-[clamp(12px, 2vw, 16px)] leading-none mt-1'>
										Número do protocolo
									</p>
								</div>
							</div>
						</div>
						<div className='h-[108px] content-center my-[32px]'>
							<div className=' w-full h-[3px] bg-[#CBCBCB] flex justify-evenly items-center gap-x-[217px]'>
								<div className=' h-[30px] w-[4px] bg-[#CBCBCB]'></div>
								<div className=' h-[30px] w-[4px] bg-[#CBCBCB]'></div>
								<div className=' h-[30px] w-[4px] bg-[#CBCBCB]'></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<Footer />
			</div>
		</div>
	);
}

export default NotesHub;
