import React, { useState, useEffect } from 'react';
import Logo from '../images/logo.png';
import { usePDF } from 'react-to-pdf';

function NotifyForm() {
	const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });
	const [otherCheck, setOtherCheck] = useState(false);
	const [yesCheck, setYesCheck] = useState(false);
	const [form, setForm] = useState({
		data_notificacao: '',
		data_ocorrencia: '',
		unidade: '',
		setor: '',
		nome_paciente: '',
		sexo_paciente: '',
		nascimento_paciente: '',
		prontuario: '',
		notificador: '',
		tipo_incidente: '',
		quem_presenciou: '',
		descricao_ocorrencia: '',
		acoes_imediatas: '',
		medida_preventiva: '',
	});

	const moment = require('moment');
	require('moment/locale/pt-br');
	moment.locale('pt-br');

	const incidentTypes = [
		'Falha na identificação do paciente',
		'Falha na terapia medicamentosa',
		'Falha na segurança cirúrgica',
		'Queda ou quase queda do paciente',
		'Lesão por pressão adquirida após admissão do paciente',
		'Reações transfusionais ou falhas com hemocomponentes',
		'Incidente envolvendo a dieta',
		'Falha no transporte do paciente',
		'Falha relacionada ao uso de gases/oxigênio/vapor',
		'Falha relacionada a exames ou diagnósticos',
		'Falha operacional de equipamentos ou dispositivos médicos',
		'Falha relacionada a infraestrutura, edifício ou instalações',
		'Falhas na comunicação e/ou documentação',
		'Contenção, suicídio ou dano autoinfligido',
		'Evasão de paciente',
	];

	const handleForm = (value, id) => {
		setForm((prevForm) => ({ ...prevForm, [id]: value }));
	};

	const isFormValid = () => {
		return Object.values(form).every((value) => value.trim() !== '');
	};

	const handleSubmit = () => {
		if (!isFormValid()) {
			console.log(form);
			alert('Por favor, preencha todos os campos obrigatórios.');
		} else {
			console.log(form);
		}
	};

	useEffect(() => {
		setForm((prevForm) => ({
			...prevForm,
			data_notificacao: moment(new Date()).format('DD/MM/YYYY'),
			unidade: decodeURIComponent(
				String(window.location.pathname).split('/')[2]
			),
		}));
	}, []);

	return (
		<div>
			<div
				ref={targetRef}
				id='my-table-id'
				className='mx-auto w-full max-w-[1130px] mt-10 px-4 sm:px-6 lg:px-8'>
				<div className='flex flex-col md:flex-row justify-between w-full h-[150px] my-1'>
					<div className='w-0 md:w-[30%] h-[105px] my-3 flex justify-center md:justify-start'>
						<img
							alt='logo da prefeitura'
							src={Logo}
							className='h-full object-contain'
						/>
					</div>
					<div className='w-full md:w-[65%] h-[105px] my-5 text-white font-bold text-center flex items-center justify-center'>
						<p className='text-2xl py-5 px-5 md:p-0 md:text-2xl lg:text-3xl bg-gray-800 h-full content-center'>
							NOTIFICAÇÃO DE INCIDENTES E NÃO CONFORMIDADES
						</p>
					</div>
				</div>
				<div className='w-full border border-black'>
					<div className='w-full'>
						<div className='flex flex-col md:flex-row'>
							<div className=' flex w-full justify-start md:w-1/2 border-r border-b border-black px-2 py-3'>
								<p className='font-semibold w-4/12 text-start content-center '>
									Data da notificação:
								</p>
								<input
									name='data_notificacao'
									id='data_notificacao'
									defaultValue={form.data_notificacao}
									onChange={({ target: { value, id } }) =>
										handleForm(value, id)
									}
									className='w-3/12  focus:outline-none'
								/>
							</div>
							<div className=' flex w-full justify-start md:w-1/2  border-b border-black px-2 py-3'>
								<p className='font-semibold w-4/12 text-start content-center '>
									Data da ocorrência:
								</p>
								<input
									name='data_ocorrencia'
									id='data_ocorrencia'
									onChange={({ target: { value, id } }) =>
										handleForm(value, id)
									}
									type='date'
									className='w-3/12 focus:outline-none'
								/>
							</div>
						</div>
						<div className='flex flex-col md:flex-row'>
							<div className='flex w-full md:w-1/2 border-r border-b border-black px-2 py-3'>
								<p className='font-semibold content-center align-baseline py-1'>
									Unidade:
								</p>
								<input
									disabled
									name='unidade'
									id='unidade'
									defaultValue={form.unidade}
									placeholder='escreva aqui. . .'
									className='w-full ml-2 focus:outline-none bg-white'
									type='text'
								/>
							</div>
							<div className='flex w-full md:w-7/12 border-b border-black px-2 py-3'>
								<p className='font-semibold content-center w-9/12 py-1'>
									Setor onde ocorreu o incidente:
								</p>
								<input
									name='setor'
									id='setor'
									onChange={({ target: { value, id } }) =>
										handleForm(value, id)
									}
									placeholder='escreva aqui. . .'
									className='ml-2 w-full focus:outline-none align-top '
									type='text'
								/>
							</div>
						</div>
						<div className='flex border-b border-black px-2 py-3'>
							<p className='font-semibold  text-start py-1'>
								Nome completo do paciente:
							</p>
							<input
								name='nome_paciente'
								id='nome_paciente'
								onChange={({ target: { value, id } }) =>
									handleForm(value, id)
								}
								placeholder='escreva aqui. . .'
								className='w-9/12 ml-2 focus:outline-none '
								type='text'
							/>
						</div>
						<div className='w-full flex flex-col md:flex-row'>
							<div className=' flex xs:w-1/4 border-r border-b border-black px-2 py-3'>
								<p className='font-semibold text-start mr-3 content-center'>
									Sexo:
								</p>
								<div className='flex  gap-4 '>
									<label className='flex items-end py-1 '>
										<input
											type='radio'
											name='sexo_paciente'
											id='masculino'
											onChange={({
												target: { id, name },
											}) => handleForm(id, name)}
											className=' h-4 w-4 text-blue-600'
										/>
										<span className='ml-2 text-sm'>
											Masculino
										</span>
									</label>
									<label className='flex items-end py-1'>
										<input
											type='radio'
											name='sexo_paciente'
											id='feminino'
											onChange={({
												target: { id, name },
											}) => handleForm(id, name)}
											className=' h-4 w-4 text-blue-600'
										/>
										<span className='ml-2 text-sm'>
											Feminino
										</span>
									</label>
								</div>
							</div>
							<div className='flex w-full justify-start md:w-5/12  border-b border-black px-2 py-3'>
								<p className='font-semibold w-10/12 text-start content-center '>
									Data de Nascimento:
								</p>
								<input
									name='nascimento_paciente'
									id='nascimento_paciente'
									onChange={({ target: { value, id } }) =>
										handleForm(value, id)
									}
									type='date'
									className='w-5/12 focus:outline-none'
								/>
							</div>
							<div className=' flex w-full md:w-2/3 border-l border-b border-black px-2 py-3'>
								<p className='font-semibold content-center py-1'>
									Prontuário/BE:
								</p>
								<input
									name='prontuario'
									id='prontuario'
									onChange={({ target: { value, id } }) =>
										handleForm(value, id)
									}
									placeholder='escreva aqui. . .'
									className=' ml-2 w-full focus:outline-none'
									type='text'
								/>
							</div>
						</div>
						<div className=' flex w-full md:w-full  border-black px-2 py-3'>
							<p className='font-semibold content-center text-start py-1 sm:w-[300px]'>
								Identificação do notificador (opcional):
							</p>
							<input
								name='notificador'
								id='notificador'
								onChange={({ target: { value, id } }) =>
									handleForm(value, id)
								}
								placeholder='escreva aqui. . .'
								className=' ml-2 w-9/12 focus:outline-none'
								type='text'
							/>
						</div>
					</div>
				</div>
				<div className='w-full my-5 border border-black'>
					<div className='w-full bg-gray-300 py-2 text-center font-bold'>
						INCIDENTES E NÃO CONFORMIDADES
					</div>
					<div className='w-full border-b border-t border-black p-4'>
						<div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
							{incidentTypes.map((incident, index) => (
								<label
									key={index}
									className='flex items-center whitespace-nowrap'>
									<input
										type='radio'
										name='tipo_incidente'
										value={incident}
										id={incident}
										onChange={({
											target: { value, name },
										}) => {
											handleForm(value, name);
											setOtherCheck(false);
										}}
										className='form-radio h-3 w-3 md:h-4 md:w-4 text-blue-600'
									/>
									<span className='ml-2 text-gray-700 text-sm md:text-base'>
										{incident}
									</span>
								</label>
							))}
							<label className='flex items-center whitespace-nowrap'>
								<input
									type='radio'
									name='tipo_incidente'
									onChange={({ target: { checked } }) =>
										setOtherCheck(checked)
									}
									className='form-radio h-3 w-3 md:h-4 md:w-4 text-blue-600'
								/>
								<span className='ml-2 text-gray-700 flex items-center w-10/12 text-sm md:text-base'>
									Outro:
									{otherCheck ? (
										<input
											type='text'
											name='tipo_incidente'
											onChange={({
												target: { value, name },
											}) => handleForm(value, name)}
											className='ml-2 w-full h-8 text-xs md:text-sm border-b border-gray-500  focus:outline-none'
										/>
									) : null}
								</span>
							</label>
						</div>
					</div>
					<div className=' flex w-full py-2'>
						<p className='font-semibold px-2  content-center md:w-5/12 text-start'>
							O fato foi presenciado por alguém?
						</p>
						<div className='w-full flex items-center px-2 gap-4'>
							<label className='flex items-center'>
								<input
									type='radio'
									name='quem_presenciou'
									id='ninguem'
									onChange={({ target: { id, name } }) => {
										handleForm(id, name);
										setYesCheck(false);
									}}
									className='form-radio h-4 w-4 text-blue-600'
								/>
								<span className='ml-2 text-gray-700'>Não</span>
							</label>
							<label className='flex items-center w-full font-semibold'>
								<input
									type='radio'
									name='quem_presenciou'
									id='sim'
									onChange={() => {
										setYesCheck(true);
									}}
									className='form-radio h-4 w-4 text-blue-600'
								/>
								<span className='ml-2 text-gray-700 flex w-full  items-center'>
									<span className=' text-gray-700 md:w-3/12 text-start py-2'>
										Sim - Quem ?
									</span>
									{yesCheck ? (
										<input
											type='text'
											id='quem_presenciou'
											onChange={({
												target: { value, id },
											}) => handleForm(value, id)}
											className=' w-full h-8 text-sm border-b border-gray-500 focus:outline-none '
										/>
									) : null}
								</span>
							</label>
						</div>
					</div>
				</div>
				<div className='w-full my-5 border border-black'>
					<div className='text-start px-2 font-semibold'>
						Descrição da ocorrência.
					</div>
					<textarea
						name='descricao_ocorrencia'
						id='descricao_ocorrencia'
						onChange={({ target: { value, id } }) =>
							handleForm(value, id)
						}
						placeholder='escreva aqui. . .'
						className='w-full h-36 mt-2 px-2 text-sm text-gray-700 border-none focus:outline-none focus:ring-0 resize-none'></textarea>
				</div>
				<div className='w-full my-5 border border-black'>
					<div className='text-start px-2 font-semibold'>
						Ações imediatas tomadas.
					</div>
					<textarea
						name='acoes_imediatas'
						id='acoes_imediatas'
						onChange={({ target: { value, id } }) =>
							handleForm(value, id)
						}
						placeholder='escreva aqui. . .'
						className='w-full h-36 mt-2 px-2 text-sm text-gray-700 border-none focus:outline-none focus:ring-0 resize-none'></textarea>
				</div>
				<div className='w-full my-5 border border-black'>
					<div className='text-start px-2 font-semibold'>
						Existe alguma medida em vigor para prevenir tal
						incidente? Qual?
					</div>
					<textarea
						name='medida_preventiva'
						id='medida_preventiva'
						onChange={({ target: { value, id } }) =>
							handleForm(value, id)
						}
						placeholder='escreva aqui. . .'
						className='w-full h-36 mt-2 px-2 text-sm text-gray-700 border-none focus:outline-none focus:ring-0 resize-none'></textarea>
				</div>
			</div>

			<div className='flex justify-between mx-auto w-full max-w-[1130px] mt-10 px-4 sm:px-6 lg:px-8'>
				<button
					className='bg-gray-800 text-white py-2 px-4 rounded-md hover:bg-gray-700 focus:outline-none'
					onClick={() => toPDF()}>
					Download PDF
				</button>
				<button
					onClick={handleSubmit}
					className='bg-blue-800 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none'>
					Enviar
				</button>
			</div>
		</div>
	);
}

export default NotifyForm;
