import React, { useEffect, useState } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { requestEmail } from '../services/requests';

const GmailSendEmail = ({ sendDataToParent }) => {
	const [messageStatus, setMessageStatus] = useState('toSend');
	const [emailData, setEmailData] = useState({
		nome: '',
		telefone: '',
		email: '',
		descricao: '',
	});

	const sendData = () => {
		const data = false;
		sendDataToParent(data);
	};

	const verificarCamposCompletos = (data) => {
		for (let key in data) {
			if (key === 'telefone') continue;
			if (data[key].trim() === '') {
				return false;
			}
		}
		return true;
	};
	const handleChange = (e) => {
		const { name, value } = e.target;
		const updatedData = {
			...emailData,
			[name]: value,
		};
		setEmailData(updatedData);
	};

	const handleRequest = async () => {
		const result = await requestEmail({
			nome: emailData.nome,
			email: emailData.email,
			telefone: emailData.telefone,
			mensagem: emailData.descricao,
		});
		if (!result.error) {
			setMessageStatus('sent');
		} else {
			alert(result.error);
		}
	};
	useEffect(() => {}, []);

	const formatarTelefone = (telefone) => {
		// Remove tudo o que não for número
		const apenasNumeros = telefone.replace(/\D/g, '');

		// Caso o campo esteja vazio, retorna vazio
		if (apenasNumeros === '') return '';

		// Formata o telefone (XX) XXXXX-XXXX
		if (apenasNumeros.length <= 2) {
			return `(${apenasNumeros}`;
		} else if (apenasNumeros.length <= 7) {
			return `(${apenasNumeros.slice(0, 2)}) ${apenasNumeros.slice(2)}`;
		} else {
			return `(${apenasNumeros.slice(0, 2)}) ${apenasNumeros.slice(
				2,
				7
			)}-${apenasNumeros.slice(7, 11)}`;
		}
	};
	const handleTelefoneChange = (e) => {
		const telefoneFormatado = formatarTelefone(e.target.value);
		setEmailData({
			...emailData,
			telefone: telefoneFormatado,
		});
	};

	const camposCompletos = verificarCamposCompletos(emailData);

	return (
		<div className='absolute bottom-0 right-0  md:right-4 bg-white shadow-slate-700 shadow-lg  rounded-t-xl h-[460px]  '>
			<div className=' w-[300px] md:w-[430px]'>
				<div className='rounded-t-2xl'>
					<h2 className='flex justify-between text-[14px] font-semibold text-zinc-800 bg-[#f2f6fc] rounded-t-xl px-6 py-3'>
						Nova Mensagem
						<button
							onClick={sendData}
							className='hover:bg-gray-300 hover:rounded-sm hover:text-gray-800 text-gray-500 transition-all ease-in-out'>
							<RiCloseLine size={20} />
						</button>
					</h2>
					<div className='px-4 py-1'>
						<div className='font-light text-[14px]'>
							<input
								type='text'
								name='nome'
								placeholder='Nome Completo'
								value={emailData.nome}
								onChange={handleChange}
								className='rounded py-2.5 px-2  w-full'
							/>
							<div className=' bg-gray-200 h-[1px]'></div>
							<input
								type='text'
								name='telefone'
								placeholder='Telefone (opcional)'
								value={emailData.telefone}
								onChange={handleTelefoneChange}
								className=' py-2.5 px-2  rounded w-full'
							/>
							<div className='bg-gray-200 h-[1px]'></div>
							<input
								name='email'
								type='email'
								placeholder='Email'
								value={emailData.email}
								onChange={handleChange}
								className='rounded py-2.5 px-2  w-full'
							/>
							<div className='bg-gray-200 h-[1px] mb-3'></div>
							{/* <input
								name='unidade'
								type='text'
								placeholder='Unidade'
								value={emailData.unidade}
								onChange={handleChange}
								className='rounded py-2.5 px-2  w-full'
							/>
							<div className='bg-gray-200 h-[1px]'></div> */}
							{/* <input
								name='cargo'
								type='text'
								placeholder='Cargo'
								value={emailData.cargo}
								onChange={handleChange}
								className='rounded py-2.5 px-2  w-full'
							/>

							<div className='bg-gray-200 h-[1px]'></div> */}
							<textarea
								name='descricao'
								placeholder='Descrição'
								value={emailData.descricao}
								onChange={handleChange}
								maxLength={500}
								className='border mt-5 resize-none  p-2 rounded mb-4 w-[100%] h-[170px]'
							/>
							{messageStatus === 'toSend' ? (
								<div className='  font-normal flex justify-between'>
									<button
										disabled={!camposCompletos}
										onClick={() => {
											handleRequest();
										}}
										className='enabled:bg-[#044c84] enabled:hover:bg-[#044d84e0]  bg-[#47afff] font-normal hover:bg- text-white p-2 rounded'>
										{camposCompletos
											? 'Enviar Mensagem'
											: 'Preencha os campos'}
									</button>
								</div>
							) : (
								<div className='flex justify-between'>
									<button
										disabled
										className=' bg-[#044c84] font-normal hover:bg- text-white p-2 rounded'>
										Mensagem enviada !
									</button>
									<button
										onClick={() => {
											setMessageStatus('toSend');
										}}
										className='text-[12px] text-gray-700 hover:text-[#47afff] font-normal h-full py-2'>
										Deseja enviar uma nova mensagem ?
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
				{/* )} */}
			</div>
		</div>
	);
};

export default GmailSendEmail;
