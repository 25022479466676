import { React } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from '../components/NavBar';
import BlogShortcut from '../components/BlogShortcut';
import Footer from '../components/Footer';

function Blog() {
	const { hash } = useLocation();

	useEffect(() => {
		if (hash) {
			const elemento = document.getElementById(hash.replace('#', ''));
			if (elemento) {
				elemento.scrollIntoView({ behavior: 'smooth', block: 'end' });
			}
		}
	}, [hash]);
	return (
		<div className='flex flex-col min-h-screen'>
			<NavBar />
			<div className='text-center  flex-grow  mx-auto  w-full mt-[50px]'>
				<div className='  h-[179px] w-full p-[42px] bg-[#F0F4F8] mt-8'>
					<div className=' rounded-md w-full h-full'>
						<p className='text-[#004A80]  text-3xl font-bold w-[87%] sm:w-full  text-center mx-auto mb-[16px] font-inter '>
							Blog SUBHUE
						</p>
						<p className='text-[#757575]   font-normal w-[87%] sm:w-full md:text-[16px] text-[13px]  text-center mx-auto font-inter '>
							Espaço informativo da Subsecretaria de Atenção
							Hospitalar, Urgência e Emergência
						</p>
					</div>
					{/* <div className='py-[48px] px-[32px] '>
						<p className='text-[#004A80]  text-3xl font-semibold w-[87%] sm:w-full  text-center mx-auto font-inter '>
							Blog SUBHUE
						</p>
						<p className='text-[#757575]   font-semibold w-[87%] sm:w-full  text-center mx-auto font-inter '>
							Espaço informativo da Subsecretaria de Atenção
							Hospitalar, Urgência e Emergência
						</p>
					</div> */}
				</div>
				<div
					id='noticias'
					className='h-[113.6]  w-full mt-4 py-3'>
					<div className='max-w-[1136px] w-[90%] sm:w-[95%] mx-auto'>
						<div className='flex justify-center flex-col sm:flex-row '>
							<p className='text-[#004A80] mb-2 text-[28px] font-semibold    font-inter '>
								Últimas Publicações
							</p>
						</div>
						<BlogShortcut />
					</div>
				</div>
			</div>
			<div>
				<Footer />
			</div>
		</div>
	);
}

export default Blog;
