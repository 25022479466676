import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const ExcelExport = ({ data, fileName }) => {
	const exportToExcel = () => {
		const worksheet = XLSX.utils.json_to_sheet(data);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

		worksheet['!pageSetup'] = {
			orientation: 'portrait',
			paperSize: 9,
			scale: 60,
			fitToPage: true,
			margins: {
				left: 0.7,
				right: 0.7,
				top: 0.7,
				bottom: 0.7,
			},
		};
		const excelBuffer = XLSX.write(workbook, {
			bookType: 'xlsx',
			type: 'array',
		});
		const blob = new Blob([excelBuffer], {
			type: 'application/octet-stream',
		});
		saveAs(blob, `${fileName}.xlsx`);
	};

	return (
		<div
			className='text-white w-full h-full content-center'
			onClick={exportToExcel}>
			Exportar como Excel
		</div>
	);
};

export default ExcelExport;
