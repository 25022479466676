import React, { useState, useEffect } from 'react';
/* import { useNavigate } from 'react-router-dom'; */
import EscalaPNG from '../images/escala de profissionais.png';
import PaineisPNG from '../images/paineis.png';
import AtividadesPNG from '../images/atividades.png';
import CalculoPNG from '../images/calculo.png';
import ContatosPNG from '../images/contatos.png';
import EventosPNG from '../images/eventos.png';
import CalendarioPNG from '../images/calendario interno.png';
import Cookies from 'js-cookie';
/* JSON.parse(Cookies.get('user')); */

function ICards() {
	/* const navigate = useNavigate(); */
	const [visibleCards, setVisibleCards] = useState([]);

	const user = JSON.parse(Cookies.get('user'));

	// Função para determinar os cards visíveis com base nas condições
	const determineVisibleCards = () => {
		const allCards = [0, 1, 2, 3, 4, 5, 6, 7, 8];
		let filteredCards = allCards;

		// Condição 1: Usuário é gestor
		if (user?.cargo === 'gestor') {
			filteredCards = allCards.filter(
				(card) => ![1, 5, 7, 8].includes(card)
			); // Remove Agenda Interna SUBHUE
		}
		// Condição 2: Usuário é da unidade subhue e não tem permissão de escala
		else if (
			user?.unidade === 'subhue' &&
			user?.permissao?.includes('escala')
		) {
			filteredCards = allCards.filter(
				(card) => ![0, 5, 6].includes(card) // Remove Escala, Agenda Interna e Permissões
			);
		}
		// Condição 3: Usuário é de outra unidade e tem permissão de escala
		else if (
			user?.unidade !== 'subhue' &&
			user?.permissao?.includes('escala')
		) {
			filteredCards = allCards.filter(
				(card) => ![1, 5, 6, 7, 8].includes(card) // Remove Agenda Interna e Permissões
			);
		}

		setVisibleCards(filteredCards);
	};

	useEffect(() => {
		determineVisibleCards();
	}, []);

	const getSVG = (index) => {
		switch (index) {
			case 0:
				return (
					<img
						alt='Escala de Profissionais'
						src={EscalaPNG}
					/>
				);
			case 1:
				return (
					<img
						alt='Painéis de Monitoramento'
						src={PaineisPNG}
					/>
				);
			case 2:
				return (
					<img
						alt='Painel de Atividades'
						src={AtividadesPNG}
					/>
				);
			case 3:
				return (
					<img
						alt='Memória de Cálculo'
						src={CalculoPNG}
					/>
				);
			case 4:
				return (
					<img
						alt='Contatos'
						src={ContatosPNG}
					/>
				);
			case 5:
				return (
					<img
						alt='Agenda Interna SUBHUE'
						src={CalendarioPNG}
					/>
				);
			case 6:
				return (
					<img
						alt='Permissões de Usuários'
						src={EventosPNG}
					/>
				);
			case 7:
				return (
					<img
						alt='Visualizador de Escalas'
						src={EscalaPNG}
					/>
				);
			case 8:
				return (
					<img
						alt='Criador de Eventos'
						src={EscalaPNG}
					/>
				);
			default:
				return null;
		}
	};

	const getLink = (index) => {
		switch (index) {
			case 0:
				return '/escala';
			case 1:
				return '/hub';
			case 2:
				return '/hub';
			case 3:
				return '/hub';
			case 4:
				return '/hub';
			case 5:
				return '/calendario-interno';
			case 6:
				return '/permissionador';
			case 7:
				return '/admin-visualize';
			case 8:
				return '/calendar-form';
			default:
				return '/';
		}
	};

	const getCardName = (index) => {
		switch (index) {
			case 0:
				return 'Escala de Profissionais';
			case 1:
				return 'Painéis de Monitoramento';
			case 2:
				return 'Painel de Atividades';
			case 3:
				return 'Memória de Cálculo';
			case 4:
				return 'Contatos';
			case 5:
				return 'Agenda Interna SUBHUE';
			case 6:
				return 'Permissões de Usuários';
			case 7:
				return 'Visualizador de Escalas';
			case 8:
				return 'Criador de Eventos';
			default:
				return '';
		}
	};

	return (
		<div className='flex items-center mt-[8%] mb-[5%] md:mt-[2%] w-100 xl:mb-[3%] 2xl:mb-[10%]'>
			<div className='flex flex-col md:flex-row md:flex-wrap gap-10 md:gap-[32px] justify-center items-center w-full'>
				{visibleCards.map((index) => (
					<a
						key={index}
						href={getLink(index)}
						className='flex flex-row scale- md:scale-100 justify-center w-[250px] h-[200px] md:w-[200px] md:h-[240px] hover:no-underline hover:scale-11 cursor-pointer rounded-md transform transition duration-300 hover:scale-110'>
						<div className='hover:no-underline flex flex-col items-center justify-between g transform transition duration-300 hover:scale-105 md:h-[240px] md:w-[105%] w-[80%] bg-[#F5F5F5] rounded-lg shadow-lg'>
							<div className='flex justify-start bg-[#004A80] w-full mb-[7px] rounded-t-md h-[128px]'>
								<div className='flex justify-center w-full md:scale-[55%] scale-[48%]'>
									{getSVG(index)}
								</div>
							</div>
							<p className='font-semibold my-auto text-[#757575]'>
								{getCardName(index)}
							</p>
						</div>
					</a>
				))}
			</div>
		</div>
	);
}

export default ICards;
